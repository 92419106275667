// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_user from '../../cwn/user';
import * as cwn_ubnt_unifi from '../../cwn/ubnt/unifi';
import * as cwn_ubnt_status from '../../cwn/ubnt/status';
import * as cwn_tech_viewmodels from '../../cwn/tech/viewmodels';
import * as cwn_tech from '../../cwn/tech';
import * as cwn_speedtest from '../../cwn/speedtest';
import * as cwn_sms from '../../cwn/sms';
import * as cwn_site_plan from '../../cwn/site/plan';
import * as cwn_site_maintmode from '../../cwn/site/maintmode';
import * as cwn_site_hw from '../../cwn/site/hw';
import * as cwn_site_details from '../../cwn/site/details';
import * as cwn_site_build_viewmodels from '../../cwn/site/build/viewmodels';
import * as cwn_site_build from '../../cwn/site/build';
import * as cwn_site_assessment from '../../cwn/site/assessment';
import * as cwn_site from '../../cwn/site';
import * as cwn_signup from '../../cwn/signup';
import * as cwn_serviceability from '../../cwn/serviceability';
import * as cwn_search from '../../cwn/search';
import * as cwn_rpc from '../../cwn/rpc';
import * as cwn_reports from '../../cwn/reports';
import * as cwn_radios from '../../cwn/radios';
import * as cwn_program from '../../cwn/program';
import * as cwn_photos from '../../cwn/photos';
import * as cwn_peers2 from '../../cwn/peers2';
import * as cwn_partner from '../../cwn/partner';
import * as cwn_ops from '../../cwn/ops';
import * as cwn_network_routing from '../../cwn/network/routing';
import * as cwn_network from '../../cwn/network';
import * as cwn_netplan from '../../cwn/netplan';
import * as cwn_module from '../../cwn/module';
import * as cwn_moca from '../../cwn/moca';
import * as cwn_mikrotik_w60g from '../../cwn/mikrotik/w60g';
import * as cwn_log from '../../cwn/log';
import * as cwn_lidar_los from '../../cwn/lidar/los';
import * as cwn_lidar from '../../cwn/lidar';
import * as cwn_lead from '../../cwn/lead';
import * as cwn_inforad from '../../cwn/inforad';
import * as cwn_ignitenet_metrolinq from '../../cwn/ignitenet/metrolinq';
import * as cwn_health from '../../cwn/health';
import * as cwn_han from '../../cwn/han';
import * as cwn_geofence from '../../cwn/geofence';
import * as cwn_geo from '../../cwn/geo';
import * as cwn_emails from '../../cwn/emails';
import * as cwn_devices_status from '../../cwn/devices/status';
import * as cwn_devices_detect from '../../cwn/devices/detect';
import * as cwn_devices from '../../cwn/devices';
import * as cwn_customers from '../../cwn/customers';
import * as cwn_cpm from '../../cwn/cpm';
import * as cwn_bridges from '../../cwn/bridges';
import * as cwn_boots from '../../cwn/boots';
import * as cwn_billing from '../../cwn/billing';
import * as cwn_audit from '../../cwn/audit';
import * as cwn_asset from '../../cwn/asset';
import * as cwn_antenna from '../../cwn/antenna';
import * as cwn_acuity from '../../cwn/acuity';
import * as cwn_actions from '../../cwn/actions';

// START cwn/admin/service.proto

class SearchRequest {
  query: string;
  limit: number;

  constructor(props: $Shape<SearchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = '';
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {SearchRequest};

class SearchReply {
  sites: Array<cwn_site.Site>;
  devices: Array<cwn_devices.Device>;
  leads: Array<cwn_lead.Lead>;
  customers: Array<cwn_customers.Customer>;
  subscriptions: Array<cwn_customers.Subscription>;

  constructor(props: $Shape<SearchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }

    this.devices = [];
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field devices should be array');
      }
      this.devices = v.map(function(v) {
        return new cwn_devices.Device(v);
      });
    }

    this.leads = [];
    if (props.hasOwnProperty('leads')) {
      const v = props.leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field leads should be array');
      }
      this.leads = v.map(function(v) {
        return new cwn_lead.Lead(v);
      });
    }

    this.customers = [];
    if (props.hasOwnProperty('customers')) {
      const v = props.customers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field customers should be array');
      }
      this.customers = v.map(function(v) {
        return new cwn_customers.Customer(v);
      });
    }

    this.subscriptions = [];
    if (props.hasOwnProperty('subscriptions')) {
      const v = props.subscriptions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscriptions should be array');
      }
      this.subscriptions = v.map(function(v) {
        return new cwn_customers.Subscription(v);
      });
    }
  }
}
export {SearchReply};

class FindAllQBPsWithinBoundsRequest {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  hashes: Array<string>;

  constructor(props: $Shape<FindAllQBPsWithinBoundsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.minLat = 0;
    if (props.hasOwnProperty('minLat')) {
      const v = props.minLat;
      this.minLat = v;
    }

    this.maxLat = 0;
    if (props.hasOwnProperty('maxLat')) {
      const v = props.maxLat;
      this.maxLat = v;
    }

    this.minLon = 0;
    if (props.hasOwnProperty('minLon')) {
      const v = props.minLon;
      this.minLon = v;
    }

    this.maxLon = 0;
    if (props.hasOwnProperty('maxLon')) {
      const v = props.maxLon;
      this.maxLon = v;
    }

    this.hashes = [];
    if (props.hasOwnProperty('hashes')) {
      const v = props.hashes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hashes should be array');
      }
      this.hashes = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FindAllQBPsWithinBoundsRequest};

class FindAllQBPsWithinBoundsReply {
  points: Array<ElevationPoint>;
  hashes: Array<string>;

  constructor(props: $Shape<FindAllQBPsWithinBoundsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.points = [];
    if (props.hasOwnProperty('points')) {
      const v = props.points;
      if (!Array.isArray(v)) {
        throw new Error('repeated field points should be array');
      }
      this.points = v.map(function(v) {
        return new ElevationPoint(v);
      });
    }

    this.hashes = [];
    if (props.hasOwnProperty('hashes')) {
      const v = props.hashes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hashes should be array');
      }
      this.hashes = v.map(function(v) {
        return v;
      });
    }
  }
}
export {FindAllQBPsWithinBoundsReply};

class ElevationPoint {
  lat: number;
  lon: number;
  elevation: number;

  constructor(props: $Shape<ElevationPoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lon = 0;
    if (props.hasOwnProperty('lon')) {
      const v = props.lon;
      this.lon = v;
    }

    this.elevation = 0;
    if (props.hasOwnProperty('elevation')) {
      const v = props.elevation;
      this.elevation = v;
    }
  }
}
export {ElevationPoint};

class GetUserRequest {
  constructor(props: $Shape<GetUserRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {GetUserRequest};

class GetUserReply {
  email: string;
  logout_url: string;

  constructor(props: $Shape<GetUserReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.logout_url = '';
    if (props.hasOwnProperty('logout_url')) {
      const v = props.logout_url;
      this.logout_url = v;
    }
  }
}
export {GetUserReply};

class GetInforadV2Request {
  constructor(props: $Shape<GetInforadV2Request> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {GetInforadV2Request};

class GetInforadV2Reply {
  inforad: ?cwn_inforad.UptimeSummary;

  constructor(props: $Shape<GetInforadV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.inforad = null;
    if (props.hasOwnProperty('inforad')) {
      const v = props.inforad;
      this.inforad = v && new cwn_inforad.UptimeSummary(v);
    }
  }

  getInforad(): cwn_inforad.UptimeSummary {
    if (this.inforad) {
      return this.inforad;
    }
    return new cwn_inforad.UptimeSummary();
  }
}
export {GetInforadV2Reply};

class ListDevicesRequest {
  constructor(props: $Shape<ListDevicesRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListDevicesRequest};

class ListDevicesReply {
  devices: Array<cwn_devices.Device>;

  constructor(props: $Shape<ListDevicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.devices = [];
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field devices should be array');
      }
      this.devices = v.map(function(v) {
        return new cwn_devices.Device(v);
      });
    }
  }
}
export {ListDevicesReply};

class ListDeviceSummariesRequest {
  constructor(props: $Shape<ListDeviceSummariesRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListDeviceSummariesRequest};

class ListDeviceSummariesReply_DeviceSummary {
  device: ?cwn_devices.Device;
  network_status: ?cwn_network.DeviceNetworkStatus;
  customer: ?cwn_customers.Customer;

  constructor(
    props: $Shape<ListDeviceSummariesReply_DeviceSummary> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.device = null;
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v && new cwn_devices.Device(v);
    }

    this.network_status = null;
    if (props.hasOwnProperty('network_status')) {
      const v = props.network_status;
      this.network_status = v && new cwn_network.DeviceNetworkStatus(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getDevice(): cwn_devices.Device {
    if (this.device) {
      return this.device;
    }
    return new cwn_devices.Device();
  }

  getNetworkStatus(): cwn_network.DeviceNetworkStatus {
    if (this.network_status) {
      return this.network_status;
    }
    return new cwn_network.DeviceNetworkStatus();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {ListDeviceSummariesReply_DeviceSummary};
class ListDeviceSummariesReply {
  summaries: Array<ListDeviceSummariesReply_DeviceSummary>;

  constructor(props: $Shape<ListDeviceSummariesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.summaries = [];
    if (props.hasOwnProperty('summaries')) {
      const v = props.summaries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field summaries should be array');
      }
      this.summaries = v.map(function(v) {
        return new ListDeviceSummariesReply_DeviceSummary(v);
      });
    }
  }
}
export {ListDeviceSummariesReply};

class GetDeviceRequest {
  device_id: string;

  constructor(props: $Shape<GetDeviceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetDeviceRequest};

class GetDeviceReply {
  device: ?cwn_devices.Device;
  device_profiles: Array<cwn_devices.DeviceProfile>;
  boots: Array<cwn_boots.Boot>;
  network_status: ?cwn_network.DeviceNetworkStatus;
  device_log_level: ?cwn_devices.DeviceLogLevel;
  alarm_password: string;
  root_password: string;
  availability_history: ?cwn_devices.AvailabilityHistory;
  module_run_status: ?cwn_module.ModuleRunStatus;
  last_heartbeat: ?cwn_devices_status.Heartbeat;
  site: ?cwn_site.Site;
  customer_subscription_results: Array<
    cwn_customers.CustomerSubscriptionResult,
  >;

  constructor(props: $Shape<GetDeviceReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.device = null;
    if (props.hasOwnProperty('device')) {
      const v = props.device;
      this.device = v && new cwn_devices.Device(v);
    }

    this.device_profiles = [];
    if (props.hasOwnProperty('device_profiles')) {
      const v = props.device_profiles;
      if (!Array.isArray(v)) {
        throw new Error('repeated field device_profiles should be array');
      }
      this.device_profiles = v.map(function(v) {
        return new cwn_devices.DeviceProfile(v);
      });
    }

    this.boots = [];
    if (props.hasOwnProperty('boots')) {
      const v = props.boots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field boots should be array');
      }
      this.boots = v.map(function(v) {
        return new cwn_boots.Boot(v);
      });
    }

    this.network_status = null;
    if (props.hasOwnProperty('network_status')) {
      const v = props.network_status;
      this.network_status = v && new cwn_network.DeviceNetworkStatus(v);
    }

    this.device_log_level = null;
    if (props.hasOwnProperty('device_log_level')) {
      const v = props.device_log_level;
      this.device_log_level = v && new cwn_devices.DeviceLogLevel(v);
    }

    this.alarm_password = '';
    if (props.hasOwnProperty('alarm_password')) {
      const v = props.alarm_password;
      this.alarm_password = v;
    }

    this.root_password = '';
    if (props.hasOwnProperty('root_password')) {
      const v = props.root_password;
      this.root_password = v;
    }

    this.availability_history = null;
    if (props.hasOwnProperty('availability_history')) {
      const v = props.availability_history;
      this.availability_history = v && new cwn_devices.AvailabilityHistory(v);
    }

    this.module_run_status = null;
    if (props.hasOwnProperty('module_run_status')) {
      const v = props.module_run_status;
      this.module_run_status = v && new cwn_module.ModuleRunStatus(v);
    }

    this.last_heartbeat = null;
    if (props.hasOwnProperty('last_heartbeat')) {
      const v = props.last_heartbeat;
      this.last_heartbeat = v && new cwn_devices_status.Heartbeat(v);
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.customer_subscription_results = [];
    if (props.hasOwnProperty('customer_subscription_results')) {
      const v = props.customer_subscription_results;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field customer_subscription_results should be array',
        );
      }
      this.customer_subscription_results = v.map(function(v) {
        return new cwn_customers.CustomerSubscriptionResult(v);
      });
    }
  }

  getDevice(): cwn_devices.Device {
    if (this.device) {
      return this.device;
    }
    return new cwn_devices.Device();
  }

  getNetworkStatus(): cwn_network.DeviceNetworkStatus {
    if (this.network_status) {
      return this.network_status;
    }
    return new cwn_network.DeviceNetworkStatus();
  }

  getDeviceLogLevel(): cwn_devices.DeviceLogLevel {
    if (this.device_log_level) {
      return this.device_log_level;
    }
    return new cwn_devices.DeviceLogLevel();
  }

  getAvailabilityHistory(): cwn_devices.AvailabilityHistory {
    if (this.availability_history) {
      return this.availability_history;
    }
    return new cwn_devices.AvailabilityHistory();
  }

  getModuleRunStatus(): cwn_module.ModuleRunStatus {
    if (this.module_run_status) {
      return this.module_run_status;
    }
    return new cwn_module.ModuleRunStatus();
  }

  getLastHeartbeat(): cwn_devices_status.Heartbeat {
    if (this.last_heartbeat) {
      return this.last_heartbeat;
    }
    return new cwn_devices_status.Heartbeat();
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {GetDeviceReply};

class GetSpeedTestsRequest {
  device_id: string;

  constructor(props: $Shape<GetSpeedTestsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetSpeedTestsRequest};

class GetSpeedTestsReply {
  speedtests: Array<cwn_speedtest.SpeedTestResultV3WithRoute>;

  constructor(props: $Shape<GetSpeedTestsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.speedtests = [];
    if (props.hasOwnProperty('speedtests')) {
      const v = props.speedtests;
      if (!Array.isArray(v)) {
        throw new Error('repeated field speedtests should be array');
      }
      this.speedtests = v.map(function(v) {
        return new cwn_speedtest.SpeedTestResultV3WithRoute(v);
      });
    }
  }
}
export {GetSpeedTestsReply};

class PutDeviceLogLevelRequest {
  device_id: string;
  severity: cwn_log.Severity;

  constructor(props: $Shape<PutDeviceLogLevelRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.severity = cwn_log.SeverityValue(0);
    if (props.hasOwnProperty('severity')) {
      const v = props.severity;
      this.severity = v;
    }
  }
}
export {PutDeviceLogLevelRequest};

class PutDeviceLogLevelReply {
  constructor(props: $Shape<PutDeviceLogLevelReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutDeviceLogLevelReply};

class GetStatusContainerRequest {
  device_id: string;

  constructor(props: $Shape<GetStatusContainerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetStatusContainerRequest};

class GetStatusContainerReply {
  status_containers: Array<cwn_peers2.StatusContainer>;

  constructor(props: $Shape<GetStatusContainerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status_containers = [];
    if (props.hasOwnProperty('status_containers')) {
      const v = props.status_containers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field status_containers should be array');
      }
      this.status_containers = v.map(function(v) {
        return new cwn_peers2.StatusContainer(v);
      });
    }
  }
}
export {GetStatusContainerReply};

class GetDeviceNetworkStatusRequest {
  device_id: string;

  constructor(props: $Shape<GetDeviceNetworkStatusRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetDeviceNetworkStatusRequest};

class GetDeviceNetworkStatusReply {
  network_status: ?cwn_network.DeviceNetworkStatus;

  constructor(props: $Shape<GetDeviceNetworkStatusReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.network_status = null;
    if (props.hasOwnProperty('network_status')) {
      const v = props.network_status;
      this.network_status = v && new cwn_network.DeviceNetworkStatus(v);
    }
  }

  getNetworkStatus(): cwn_network.DeviceNetworkStatus {
    if (this.network_status) {
      return this.network_status;
    }
    return new cwn_network.DeviceNetworkStatus();
  }
}
export {GetDeviceNetworkStatusReply};

class ClearDeviceLogLevelRequest {
  device_id: string;

  constructor(props: $Shape<ClearDeviceLogLevelRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {ClearDeviceLogLevelRequest};

class ClearDeviceLogLevelReply {
  constructor(props: $Shape<ClearDeviceLogLevelReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ClearDeviceLogLevelReply};

class ListBootsRequest {
  constructor(props: $Shape<ListBootsRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListBootsRequest};

class ListBootsReply {
  boots: Array<cwn_boots.Boot>;

  constructor(props: $Shape<ListBootsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.boots = [];
    if (props.hasOwnProperty('boots')) {
      const v = props.boots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field boots should be array');
      }
      this.boots = v.map(function(v) {
        return new cwn_boots.Boot(v);
      });
    }
  }
}
export {ListBootsReply};

class GetBootRequest {
  boot_id: string;

  constructor(props: $Shape<GetBootRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }
  }
}
export {GetBootRequest};

class GetBootReply {
  boot: ?cwn_boots.Boot;

  constructor(props: $Shape<GetBootReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.boot = null;
    if (props.hasOwnProperty('boot')) {
      const v = props.boot;
      this.boot = v && new cwn_boots.Boot(v);
    }
  }

  getBoot(): cwn_boots.Boot {
    if (this.boot) {
      return this.boot;
    }
    return new cwn_boots.Boot();
  }
}
export {GetBootReply};

class ListRadioGroupsRequest {
  constructor(props: $Shape<ListRadioGroupsRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListRadioGroupsRequest};

class ListRadioGroupsReply {
  radio_groups: Array<cwn_radios.RadioGroup>;

  constructor(props: $Shape<ListRadioGroupsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_groups = [];
    if (props.hasOwnProperty('radio_groups')) {
      const v = props.radio_groups;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_groups should be array');
      }
      this.radio_groups = v.map(function(v) {
        return new cwn_radios.RadioGroup(v);
      });
    }
  }
}
export {ListRadioGroupsReply};

class ListJoinableRadioGroupsRequest {
  constructor(props: $Shape<ListJoinableRadioGroupsRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListJoinableRadioGroupsRequest};

class ListJoinableRadioGroupsReply_Result {
  radio_group: ?cwn_radios.RadioGroup;
  customer_subscription_result: ?cwn_customers.CustomerSubscriptionResult;
  station_count: number;

  constructor(props: $Shape<ListJoinableRadioGroupsReply_Result> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }

    this.customer_subscription_result = null;
    if (props.hasOwnProperty('customer_subscription_result')) {
      const v = props.customer_subscription_result;
      this.customer_subscription_result =
        v && new cwn_customers.CustomerSubscriptionResult(v);
    }

    this.station_count = 0;
    if (props.hasOwnProperty('station_count')) {
      const v = props.station_count;
      this.station_count = v;
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }

  getCustomerSubscriptionResult(): cwn_customers.CustomerSubscriptionResult {
    if (this.customer_subscription_result) {
      return this.customer_subscription_result;
    }
    return new cwn_customers.CustomerSubscriptionResult();
  }
}
export {ListJoinableRadioGroupsReply_Result};
class ListJoinableRadioGroupsReply {
  results: Array<ListJoinableRadioGroupsReply_Result>;

  constructor(props: $Shape<ListJoinableRadioGroupsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new ListJoinableRadioGroupsReply_Result(v);
      });
    }
  }
}
export {ListJoinableRadioGroupsReply};

class PutRadioGroupRequest {
  radio_group: ?cwn_radios.RadioGroup;

  constructor(props: $Shape<PutRadioGroupRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }
}
export {PutRadioGroupRequest};

class PutRadioGroupReply {
  radio_group: ?cwn_radios.RadioGroup;

  constructor(props: $Shape<PutRadioGroupReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }
}
export {PutRadioGroupReply};

class GetRadioGroupRequest {
  radio_group_id: string;

  constructor(props: $Shape<GetRadioGroupRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }
  }
}
export {GetRadioGroupRequest};

class GetRadioGroupReply_SRLEntry {
  customer_subscription_result: ?cwn_customers.CustomerSubscriptionResult;
  radio: ?cwn_radios.Radio;
  site_radio_link: ?cwn_site.SiteRadioLink;

  constructor(props: $Shape<GetRadioGroupReply_SRLEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer_subscription_result = null;
    if (props.hasOwnProperty('customer_subscription_result')) {
      const v = props.customer_subscription_result;
      this.customer_subscription_result =
        v && new cwn_customers.CustomerSubscriptionResult(v);
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new cwn_radios.Radio(v);
    }

    this.site_radio_link = null;
    if (props.hasOwnProperty('site_radio_link')) {
      const v = props.site_radio_link;
      this.site_radio_link = v && new cwn_site.SiteRadioLink(v);
    }
  }

  getCustomerSubscriptionResult(): cwn_customers.CustomerSubscriptionResult {
    if (this.customer_subscription_result) {
      return this.customer_subscription_result;
    }
    return new cwn_customers.CustomerSubscriptionResult();
  }

  getRadio(): cwn_radios.Radio {
    if (this.radio) {
      return this.radio;
    }
    return new cwn_radios.Radio();
  }

  getSiteRadioLink(): cwn_site.SiteRadioLink {
    if (this.site_radio_link) {
      return this.site_radio_link;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {GetRadioGroupReply_SRLEntry};
class GetRadioGroupReply {
  radio_group: ?cwn_radios.RadioGroup;
  srl_entries: Array<GetRadioGroupReply_SRLEntry>;

  constructor(props: $Shape<GetRadioGroupReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }

    this.srl_entries = [];
    if (props.hasOwnProperty('srl_entries')) {
      const v = props.srl_entries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field srl_entries should be array');
      }
      this.srl_entries = v.map(function(v) {
        return new GetRadioGroupReply_SRLEntry(v);
      });
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }
}
export {GetRadioGroupReply};

class GetCustomerRequest {
  customer_id: string;

  constructor(props: $Shape<GetCustomerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }
  }
}
export {GetCustomerRequest};

class GetCustomerReply {
  customer: ?cwn_customers.Customer;
  subscriptions: Array<cwn_customers.Subscription>;
  comments: Array<cwn_customers.Comment>;
  messages: Array<cwn_emails.Message>;

  constructor(props: $Shape<GetCustomerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.subscriptions = [];
    if (props.hasOwnProperty('subscriptions')) {
      const v = props.subscriptions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscriptions should be array');
      }
      this.subscriptions = v.map(function(v) {
        return new cwn_customers.Subscription(v);
      });
    }

    this.comments = [];
    if (props.hasOwnProperty('comments')) {
      const v = props.comments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field comments should be array');
      }
      this.comments = v.map(function(v) {
        return new cwn_customers.Comment(v);
      });
    }

    this.messages = [];
    if (props.hasOwnProperty('messages')) {
      const v = props.messages;
      if (!Array.isArray(v)) {
        throw new Error('repeated field messages should be array');
      }
      this.messages = v.map(function(v) {
        return new cwn_emails.Message(v);
      });
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {GetCustomerReply};

class GetCustomerBatchRequest {
  customer_ids: Array<string>;

  constructor(props: $Shape<GetCustomerBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer_ids = [];
    if (props.hasOwnProperty('customer_ids')) {
      const v = props.customer_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field customer_ids should be array');
      }
      this.customer_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetCustomerBatchRequest};

class GetCustomerBatchReply {
  customers: Array<cwn_customers.Customer>;

  constructor(props: $Shape<GetCustomerBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customers = [];
    if (props.hasOwnProperty('customers')) {
      const v = props.customers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field customers should be array');
      }
      this.customers = v.map(function(v) {
        return new cwn_customers.Customer(v);
      });
    }
  }
}
export {GetCustomerBatchReply};

class GetSubscriptionRequest {
  subscription_id: string;

  constructor(props: $Shape<GetSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {GetSubscriptionRequest};

class GetSubscriptionReply {
  customer: ?cwn_customers.Customer;
  subscription: ?cwn_customers.Subscription;
  most_recent_signup: ?cwn_signup.Signup;
  comments: Array<cwn_customers.Comment>;
  billing: ?cwn_billing.Billing;
  instruments: Array<cwn_billing.Instrument>;

  constructor(props: $Shape<GetSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }

    this.most_recent_signup = null;
    if (props.hasOwnProperty('most_recent_signup')) {
      const v = props.most_recent_signup;
      this.most_recent_signup = v && new cwn_signup.Signup(v);
    }

    this.comments = [];
    if (props.hasOwnProperty('comments')) {
      const v = props.comments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field comments should be array');
      }
      this.comments = v.map(function(v) {
        return new cwn_customers.Comment(v);
      });
    }

    this.billing = null;
    if (props.hasOwnProperty('billing')) {
      const v = props.billing;
      this.billing = v && new cwn_billing.Billing(v);
    }

    this.instruments = [];
    if (props.hasOwnProperty('instruments')) {
      const v = props.instruments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field instruments should be array');
      }
      this.instruments = v.map(function(v) {
        return new cwn_billing.Instrument(v);
      });
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }

  getMostRecentSignup(): cwn_signup.Signup {
    if (this.most_recent_signup) {
      return this.most_recent_signup;
    }
    return new cwn_signup.Signup();
  }

  getBilling(): cwn_billing.Billing {
    if (this.billing) {
      return this.billing;
    }
    return new cwn_billing.Billing();
  }
}
export {GetSubscriptionReply};

class GetSubscriptionBatchRequest {
  subscription_ids: Array<string>;

  constructor(props: $Shape<GetSubscriptionBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_ids = [];
    if (props.hasOwnProperty('subscription_ids')) {
      const v = props.subscription_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscription_ids should be array');
      }
      this.subscription_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetSubscriptionBatchRequest};

class GetSubscriptionBatchReply {
  subscriptions: Array<cwn_customers.Subscription>;

  constructor(props: $Shape<GetSubscriptionBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscriptions = [];
    if (props.hasOwnProperty('subscriptions')) {
      const v = props.subscriptions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field subscriptions should be array');
      }
      this.subscriptions = v.map(function(v) {
        return new cwn_customers.Subscription(v);
      });
    }
  }
}
export {GetSubscriptionBatchReply};

class PutCustomerRequest {
  customer: ?cwn_customers.Customer;

  constructor(props: $Shape<PutCustomerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {PutCustomerRequest};

class PutCustomerReply {
  customer: ?cwn_customers.Customer;

  constructor(props: $Shape<PutCustomerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {PutCustomerReply};

class PutSubscriptionRequest {
  subscription: ?cwn_customers.Subscription;

  constructor(props: $Shape<PutSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }
}
export {PutSubscriptionRequest};

class PutSubscriptionReply {
  subscription: ?cwn_customers.Subscription;

  constructor(props: $Shape<PutSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }
}
export {PutSubscriptionReply};

class ListCustomersRequest {
  status: cwn_customers.Customer_Status;
  cursor: string;

  constructor(props: $Shape<ListCustomersRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = cwn_customers.Customer_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListCustomersRequest};

class ListCustomersReply {
  customers: Array<cwn_customers.Customer>;
  cursor: string;
  done: boolean;

  constructor(props: $Shape<ListCustomersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customers = [];
    if (props.hasOwnProperty('customers')) {
      const v = props.customers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field customers should be array');
      }
      this.customers = v.map(function(v) {
        return new cwn_customers.Customer(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.done = false;
    if (props.hasOwnProperty('done')) {
      const v = props.done;
      this.done = !!v;
    }
  }
}
export {ListCustomersReply};

class ListPackagesV2Request {
  constructor(props: $Shape<ListPackagesV2Request> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListPackagesV2Request};

class ListPackagesV2Reply {
  packages: Array<cwn_cpm.Package>;

  constructor(props: $Shape<ListPackagesV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.packages = [];
    if (props.hasOwnProperty('packages')) {
      const v = props.packages;
      if (!Array.isArray(v)) {
        throw new Error('repeated field packages should be array');
      }
      this.packages = v.map(function(v) {
        return new cwn_cpm.Package(v);
      });
    }
  }
}
export {ListPackagesV2Reply};

class GetPackageRequest {
  package_name: string;

  constructor(props: $Shape<GetPackageRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.package_name = '';
    if (props.hasOwnProperty('package_name')) {
      const v = props.package_name;
      this.package_name = v;
    }
  }
}
export {GetPackageRequest};

class GetPackageReply {
  package: ?cwn_cpm.Package;
  versions: Array<cwn_cpm.Version>;
  defaults: Array<cwn_cpm.DefaultArchive>;
  archives: Array<cwn_cpm.Archive>;

  constructor(props: $Shape<GetPackageReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.package = null;
    if (props.hasOwnProperty('package')) {
      const v = props.package;
      this.package = v && new cwn_cpm.Package(v);
    }

    this.versions = [];
    if (props.hasOwnProperty('versions')) {
      const v = props.versions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field versions should be array');
      }
      this.versions = v.map(function(v) {
        return new cwn_cpm.Version(v);
      });
    }

    this.defaults = [];
    if (props.hasOwnProperty('defaults')) {
      const v = props.defaults;
      if (!Array.isArray(v)) {
        throw new Error('repeated field defaults should be array');
      }
      this.defaults = v.map(function(v) {
        return new cwn_cpm.DefaultArchive(v);
      });
    }

    this.archives = [];
    if (props.hasOwnProperty('archives')) {
      const v = props.archives;
      if (!Array.isArray(v)) {
        throw new Error('repeated field archives should be array');
      }
      this.archives = v.map(function(v) {
        return new cwn_cpm.Archive(v);
      });
    }
  }

  getPackage(): cwn_cpm.Package {
    if (this.package) {
      return this.package;
    }
    return new cwn_cpm.Package();
  }
}
export {GetPackageReply};

class CreatePackageRequest {
  package: ?cwn_cpm.Package;

  constructor(props: $Shape<CreatePackageRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.package = null;
    if (props.hasOwnProperty('package')) {
      const v = props.package;
      this.package = v && new cwn_cpm.Package(v);
    }
  }

  getPackage(): cwn_cpm.Package {
    if (this.package) {
      return this.package;
    }
    return new cwn_cpm.Package();
  }
}
export {CreatePackageRequest};

class CreatePackageReply {
  constructor(props: $Shape<CreatePackageReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {CreatePackageReply};

class CreateVersionRequest {
  version: ?cwn_cpm.Version;

  constructor(props: $Shape<CreateVersionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.version = null;
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v && new cwn_cpm.Version(v);
    }
  }

  getVersion(): cwn_cpm.Version {
    if (this.version) {
      return this.version;
    }
    return new cwn_cpm.Version();
  }
}
export {CreateVersionRequest};

class CreateVersionReply {
  constructor(props: $Shape<CreateVersionReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {CreateVersionReply};

class PutDefaultArchiveRequest {
  archive: ?cwn_cpm.Archive;

  constructor(props: $Shape<PutDefaultArchiveRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.archive = null;
    if (props.hasOwnProperty('archive')) {
      const v = props.archive;
      this.archive = v && new cwn_cpm.Archive(v);
    }
  }

  getArchive(): cwn_cpm.Archive {
    if (this.archive) {
      return this.archive;
    }
    return new cwn_cpm.Archive();
  }
}
export {PutDefaultArchiveRequest};

class PutDefaultArchiveReply {
  constructor(props: $Shape<PutDefaultArchiveReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutDefaultArchiveReply};

class PostSubscriptionRequest {
  subscription: ?cwn_customers.Subscription;

  constructor(props: $Shape<PostSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }
}
export {PostSubscriptionRequest};

class PostSubscriptionReply {
  subscription: ?cwn_customers.Subscription;

  constructor(props: $Shape<PostSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }
}
export {PostSubscriptionReply};

class SearchSubscriptionsRequest {
  search: string;

  constructor(props: $Shape<SearchSubscriptionsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.search = '';
    if (props.hasOwnProperty('search')) {
      const v = props.search;
      this.search = v;
    }
  }
}
export {SearchSubscriptionsRequest};

class SearchSubscriptionsReply {
  results: Array<cwn_customers.CustomerSubscriptionResult>;

  constructor(props: $Shape<SearchSubscriptionsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new cwn_customers.CustomerSubscriptionResult(v);
      });
    }
  }
}
export {SearchSubscriptionsReply};

class GetHansForSubscriptionRequest {
  sub_id: string;

  constructor(props: $Shape<GetHansForSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.sub_id = '0';
    if (props.hasOwnProperty('sub_id')) {
      const v = props.sub_id;
      this.sub_id = v;
    }
  }
}
export {GetHansForSubscriptionRequest};

class GetHansForSubscriptionsReply {
  han: ?cwn_han.Han;
  wlan_hash2g: string;
  wlan_hash5g: string;

  constructor(props: $Shape<GetHansForSubscriptionsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new cwn_han.Han(v);
    }

    this.wlan_hash2g = '';
    if (props.hasOwnProperty('wlan_hash2g')) {
      const v = props.wlan_hash2g;
      this.wlan_hash2g = v;
    }

    this.wlan_hash5g = '';
    if (props.hasOwnProperty('wlan_hash5g')) {
      const v = props.wlan_hash5g;
      this.wlan_hash5g = v;
    }
  }

  getHan(): cwn_han.Han {
    if (this.han) {
      return this.han;
    }
    return new cwn_han.Han();
  }
}
export {GetHansForSubscriptionsReply};

class CreateHanRequest {
  sub_id: string;
  cust_id: string;

  constructor(props: $Shape<CreateHanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.sub_id = '0';
    if (props.hasOwnProperty('sub_id')) {
      const v = props.sub_id;
      this.sub_id = v;
    }

    this.cust_id = '';
    if (props.hasOwnProperty('cust_id')) {
      const v = props.cust_id;
      this.cust_id = v;
    }
  }
}
export {CreateHanRequest};

class CreateHanReply {
  han: ?cwn_han.Han;

  constructor(props: $Shape<CreateHanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new cwn_han.Han(v);
    }
  }

  getHan(): cwn_han.Han {
    if (this.han) {
      return this.han;
    }
    return new cwn_han.Han();
  }
}
export {CreateHanReply};

class UpdateHanRequest {
  han_id: string;
  ap_macs: Array<string>;
  wlans: Array<cwn_han.Wlan>;
  force: boolean;
  eero_serial_numbers: Array<string>;

  constructor(props: $Shape<UpdateHanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }

    this.ap_macs = [];
    if (props.hasOwnProperty('ap_macs')) {
      const v = props.ap_macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ap_macs should be array');
      }
      this.ap_macs = v.map(function(v) {
        return v;
      });
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.force = false;
    if (props.hasOwnProperty('force')) {
      const v = props.force;
      this.force = !!v;
    }

    this.eero_serial_numbers = [];
    if (props.hasOwnProperty('eero_serial_numbers')) {
      const v = props.eero_serial_numbers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_serial_numbers should be array');
      }
      this.eero_serial_numbers = v.map(function(v) {
        return v;
      });
    }
  }
}
export {UpdateHanRequest};

export const UpdateHanReply_StatusValues = {
  OK: 0,
  CONFLICT: 1,
};
type UpdateHanReply_Status = $Keys<typeof UpdateHanReply_StatusValues>;
export type {UpdateHanReply_Status};

export function UpdateHanReply_StatusValue(n: number): UpdateHanReply_Status {
  switch (n) {
    case 0:
      return 'OK';

    case 1:
      return 'CONFLICT';

    default:
      return 'OK';
  }
}

class UpdateHanReply_Conflict {
  sub_id: string;
  name: string;
  address: string;

  constructor(props: $Shape<UpdateHanReply_Conflict> = {}): void {
    if (!props) {
      props = {};
    }

    this.sub_id = '0';
    if (props.hasOwnProperty('sub_id')) {
      const v = props.sub_id;
      this.sub_id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }
}
export {UpdateHanReply_Conflict};
class UpdateHanReply {
  status: UpdateHanReply_Status;
  conflicts: {[key: string]: ?UpdateHanReply_Conflict};
  han: ?cwn_han.Han;

  constructor(props: $Shape<UpdateHanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = UpdateHanReply_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.conflicts = {};
    if (props.hasOwnProperty('conflicts')) {
      const v = props.conflicts;
      for (const key: any of Object.keys(v)) {
        this.conflicts[key] = new UpdateHanReply_Conflict((v[key]: any));
      }
    }

    this.han = null;
    if (props.hasOwnProperty('han')) {
      const v = props.han;
      this.han = v && new cwn_han.Han(v);
    }
  }

  getHan(): cwn_han.Han {
    if (this.han) {
      return this.han;
    }
    return new cwn_han.Han();
  }
}
export {UpdateHanReply};

class UpdateDeclineApRequest {
  han_id: string;
  declined_ap: boolean;
  declined_ap_mac: string;

  constructor(props: $Shape<UpdateDeclineApRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }

    this.declined_ap = false;
    if (props.hasOwnProperty('declined_ap')) {
      const v = props.declined_ap;
      this.declined_ap = !!v;
    }

    this.declined_ap_mac = '';
    if (props.hasOwnProperty('declined_ap_mac')) {
      const v = props.declined_ap_mac;
      this.declined_ap_mac = v;
    }
  }
}
export {UpdateDeclineApRequest};

class GetLastUnifiStatusesRequest {
  macs: Array<string>;
  unassigned: boolean;

  constructor(props: $Shape<GetLastUnifiStatusesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.macs = [];
    if (props.hasOwnProperty('macs')) {
      const v = props.macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field macs should be array');
      }
      this.macs = v.map(function(v) {
        return v;
      });
    }

    this.unassigned = false;
    if (props.hasOwnProperty('unassigned')) {
      const v = props.unassigned;
      this.unassigned = !!v;
    }
  }
}
export {GetLastUnifiStatusesRequest};

class GetLastUnifiStatusesReply {
  status_by_mac: {[key: string]: ?cwn_ubnt_unifi.Status};
  unassigned_statuses: Array<cwn_ubnt_unifi.Status>;
  han_id_for_mac: {[key: string]: string};

  constructor(props: $Shape<GetLastUnifiStatusesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status_by_mac = {};
    if (props.hasOwnProperty('status_by_mac')) {
      const v = props.status_by_mac;
      for (const key: any of Object.keys(v)) {
        this.status_by_mac[key] = new cwn_ubnt_unifi.Status((v[key]: any));
      }
    }

    this.unassigned_statuses = [];
    if (props.hasOwnProperty('unassigned_statuses')) {
      const v = props.unassigned_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field unassigned_statuses should be array');
      }
      this.unassigned_statuses = v.map(function(v) {
        return new cwn_ubnt_unifi.Status(v);
      });
    }

    this.han_id_for_mac = {};
    if (props.hasOwnProperty('han_id_for_mac')) {
      const v = props.han_id_for_mac;
      for (const key: any of Object.keys(v)) {
        this.han_id_for_mac[key] = v[key];
      }
    }
  }
}
export {GetLastUnifiStatusesReply};

class RebootUnifiRequest {
  mac: string;
  sub_id: string;

  constructor(props: $Shape<RebootUnifiRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.sub_id = '0';
    if (props.hasOwnProperty('sub_id')) {
      const v = props.sub_id;
      this.sub_id = v;
    }
  }
}
export {RebootUnifiRequest};

class RebootUnifiReply {
  constructor(props: $Shape<RebootUnifiReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {RebootUnifiReply};

class GetRadioRequest {
  mac_address: string;

  constructor(props: $Shape<GetRadioRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }
  }
}
export {GetRadioRequest};

class GetRadioReply {
  radio: ?cwn_radios.Radio;

  constructor(props: $Shape<GetRadioReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new cwn_radios.Radio(v);
    }
  }

  getRadio(): cwn_radios.Radio {
    if (this.radio) {
      return this.radio;
    }
    return new cwn_radios.Radio();
  }
}
export {GetRadioReply};

class GetActionRequest {
  action_id: string;

  constructor(props: $Shape<GetActionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.action_id = '0';
    if (props.hasOwnProperty('action_id')) {
      const v = props.action_id;
      this.action_id = v;
    }
  }
}
export {GetActionRequest};

class GetActionReply {
  action: ?cwn_actions.Action;

  constructor(props: $Shape<GetActionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.action = null;
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v && new cwn_actions.Action(v);
    }
  }

  getAction(): cwn_actions.Action {
    if (this.action) {
      return this.action;
    }
    return new cwn_actions.Action();
  }
}
export {GetActionReply};

class PutActionRequest {
  action: ?cwn_actions.Action;

  constructor(props: $Shape<PutActionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.action = null;
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v && new cwn_actions.Action(v);
    }
  }

  getAction(): cwn_actions.Action {
    if (this.action) {
      return this.action;
    }
    return new cwn_actions.Action();
  }
}
export {PutActionRequest};

class PutActionReply {
  action_id: string;

  constructor(props: $Shape<PutActionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.action_id = '0';
    if (props.hasOwnProperty('action_id')) {
      const v = props.action_id;
      this.action_id = v;
    }
  }
}
export {PutActionReply};

class ListRoutingTablesRequest {
  limit: number;

  constructor(props: $Shape<ListRoutingTablesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {ListRoutingTablesRequest};

class ListRoutingTablesReply {
  routing_tables: Array<cwn_network_routing.RoutingTable>;

  constructor(props: $Shape<ListRoutingTablesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_tables = [];
    if (props.hasOwnProperty('routing_tables')) {
      const v = props.routing_tables;
      if (!Array.isArray(v)) {
        throw new Error('repeated field routing_tables should be array');
      }
      this.routing_tables = v.map(function(v) {
        return new cwn_network_routing.RoutingTable(v);
      });
    }
  }
}
export {ListRoutingTablesReply};

class PostRoutingTableRequest {
  algorithm: cwn_network_routing.Algorithm;

  constructor(props: $Shape<PostRoutingTableRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.algorithm = cwn_network_routing.AlgorithmValue(0);
    if (props.hasOwnProperty('algorithm')) {
      const v = props.algorithm;
      this.algorithm = v;
    }
  }
}
export {PostRoutingTableRequest};

class PostRoutingTableReply {
  routing_table: ?cwn_network_routing.RoutingTable;

  constructor(props: $Shape<PostRoutingTableReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_table = null;
    if (props.hasOwnProperty('routing_table')) {
      const v = props.routing_table;
      this.routing_table = v && new cwn_network_routing.RoutingTable(v);
    }
  }

  getRoutingTable(): cwn_network_routing.RoutingTable {
    if (this.routing_table) {
      return this.routing_table;
    }
    return new cwn_network_routing.RoutingTable();
  }
}
export {PostRoutingTableReply};

class GetRoutingTableRequest {
  routing_table_id: string;

  constructor(props: $Shape<GetRoutingTableRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_table_id = '0';
    if (props.hasOwnProperty('routing_table_id')) {
      const v = props.routing_table_id;
      this.routing_table_id = v;
    }
  }
}
export {GetRoutingTableRequest};

class GetRoutingTableReply {
  routing_table: ?cwn_network_routing.RoutingTable;

  constructor(props: $Shape<GetRoutingTableReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_table = null;
    if (props.hasOwnProperty('routing_table')) {
      const v = props.routing_table;
      this.routing_table = v && new cwn_network_routing.RoutingTable(v);
    }
  }

  getRoutingTable(): cwn_network_routing.RoutingTable {
    if (this.routing_table) {
      return this.routing_table;
    }
    return new cwn_network_routing.RoutingTable();
  }
}
export {GetRoutingTableReply};

class GetCurrentRoutingTableRequest {
  constructor(props: $Shape<GetCurrentRoutingTableRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {GetCurrentRoutingTableRequest};

class GetCurrentRoutingTableReply {
  routing_table: ?cwn_network_routing.RoutingTable;
  metadata: ?cwn_network_routing.Metadata;

  constructor(props: $Shape<GetCurrentRoutingTableReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_table = null;
    if (props.hasOwnProperty('routing_table')) {
      const v = props.routing_table;
      this.routing_table = v && new cwn_network_routing.RoutingTable(v);
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new cwn_network_routing.Metadata(v);
    }
  }

  getRoutingTable(): cwn_network_routing.RoutingTable {
    if (this.routing_table) {
      return this.routing_table;
    }
    return new cwn_network_routing.RoutingTable();
  }

  getMetadata(): cwn_network_routing.Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new cwn_network_routing.Metadata();
  }
}
export {GetCurrentRoutingTableReply};

class ApplyRoutingTableRequest {
  routing_table_id: string;

  constructor(props: $Shape<ApplyRoutingTableRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.routing_table_id = '0';
    if (props.hasOwnProperty('routing_table_id')) {
      const v = props.routing_table_id;
      this.routing_table_id = v;
    }
  }
}
export {ApplyRoutingTableRequest};

class ApplyRoutingTableReply {
  constructor(props: $Shape<ApplyRoutingTableReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ApplyRoutingTableReply};

class ListRouteHopsForDestinationDeviceIDRequest {
  device_id: string;

  constructor(
    props: $Shape<ListRouteHopsForDestinationDeviceIDRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {ListRouteHopsForDestinationDeviceIDRequest};

class RouteHop {
  device_id: string;
  upstream_radio_frequency: cwn_asset.RadioFrequency;
  download_speed: number;
  node_type: cwn_site.Site_NodeType;

  constructor(props: $Shape<RouteHop> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.upstream_radio_frequency = cwn_asset.RadioFrequencyValue(0);
    if (props.hasOwnProperty('upstream_radio_frequency')) {
      const v = props.upstream_radio_frequency;
      this.upstream_radio_frequency = v;
    }

    this.download_speed = 0;
    if (props.hasOwnProperty('download_speed')) {
      const v = props.download_speed;
      this.download_speed = v;
    }

    this.node_type = cwn_site.Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }
  }
}
export {RouteHop};

class ListRouteHopsForDestinationDeviceIDReply {
  route_hops: Array<RouteHop>;
  is_stale: boolean;
  applied: ?Date;

  constructor(
    props: $Shape<ListRouteHopsForDestinationDeviceIDReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.route_hops = [];
    if (props.hasOwnProperty('route_hops')) {
      const v = props.route_hops;
      if (!Array.isArray(v)) {
        throw new Error('repeated field route_hops should be array');
      }
      this.route_hops = v.map(function(v) {
        return new RouteHop(v);
      });
    }

    this.is_stale = false;
    if (props.hasOwnProperty('is_stale')) {
      const v = props.is_stale;
      this.is_stale = !!v;
    }

    this.applied = null;
    if (props.hasOwnProperty('applied')) {
      const v = props.applied;
      this.applied = v && new Date(v);
    }
  }
}
export {ListRouteHopsForDestinationDeviceIDReply};

class ListAppliedRoutesForDestinationDeviceIDBetweenRequest {
  device_id: string;
  start: ?Date;
  end: ?Date;

  constructor(
    props: $Shape<ListAppliedRoutesForDestinationDeviceIDBetweenRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.start = null;
    if (props.hasOwnProperty('start')) {
      const v = props.start;
      this.start = v && new Date(v);
    }

    this.end = null;
    if (props.hasOwnProperty('end')) {
      const v = props.end;
      this.end = v && new Date(v);
    }
  }
}
export {ListAppliedRoutesForDestinationDeviceIDBetweenRequest};

class ListAppliedRoutesForDestinationDeviceIDBetweenReply {
  applied_routes: Array<cwn_network_routing.AppliedRoute>;

  constructor(
    props: $Shape<ListAppliedRoutesForDestinationDeviceIDBetweenReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.applied_routes = [];
    if (props.hasOwnProperty('applied_routes')) {
      const v = props.applied_routes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field applied_routes should be array');
      }
      this.applied_routes = v.map(function(v) {
        return new cwn_network_routing.AppliedRoute(v);
      });
    }
  }
}
export {ListAppliedRoutesForDestinationDeviceIDBetweenReply};

class GetNetworkRequest {
  constructor(props: $Shape<GetNetworkRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {GetNetworkRequest};

class GetNetworkReply {
  network: ?cwn_network_routing.Network;

  constructor(props: $Shape<GetNetworkReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.network = null;
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v && new cwn_network_routing.Network(v);
    }
  }

  getNetwork(): cwn_network_routing.Network {
    if (this.network) {
      return this.network;
    }
    return new cwn_network_routing.Network();
  }
}
export {GetNetworkReply};

class UpdateFrequenciesRequest {
  radio_groups: Array<cwn_radios.RadioGroup>;

  constructor(props: $Shape<UpdateFrequenciesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_groups = [];
    if (props.hasOwnProperty('radio_groups')) {
      const v = props.radio_groups;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_groups should be array');
      }
      this.radio_groups = v.map(function(v) {
        return new cwn_radios.RadioGroup(v);
      });
    }
  }
}
export {UpdateFrequenciesRequest};

class UpdateFrequenciesReply {
  constructor(props: $Shape<UpdateFrequenciesReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {UpdateFrequenciesReply};

class ListProspectiveCustomersRequest {
  constructor(props: $Shape<ListProspectiveCustomersRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListProspectiveCustomersRequest};

class ListProspectiveCustomersReply {
  prospective_customers: Array<cwn_customers.ProspectiveCustomer>;

  constructor(props: $Shape<ListProspectiveCustomersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.prospective_customers = [];
    if (props.hasOwnProperty('prospective_customers')) {
      const v = props.prospective_customers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field prospective_customers should be array');
      }
      this.prospective_customers = v.map(function(v) {
        return new cwn_customers.ProspectiveCustomer(v);
      });
    }
  }
}
export {ListProspectiveCustomersReply};

class PostCommentRequest {
  comment: ?cwn_customers.Comment;

  constructor(props: $Shape<PostCommentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.comment = null;
    if (props.hasOwnProperty('comment')) {
      const v = props.comment;
      this.comment = v && new cwn_customers.Comment(v);
    }
  }

  getComment(): cwn_customers.Comment {
    if (this.comment) {
      return this.comment;
    }
    return new cwn_customers.Comment();
  }
}
export {PostCommentRequest};

class PostCommentReply {
  comment: ?cwn_customers.Comment;

  constructor(props: $Shape<PostCommentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.comment = null;
    if (props.hasOwnProperty('comment')) {
      const v = props.comment;
      this.comment = v && new cwn_customers.Comment(v);
    }
  }

  getComment(): cwn_customers.Comment {
    if (this.comment) {
      return this.comment;
    }
    return new cwn_customers.Comment();
  }
}
export {PostCommentReply};

class DeleteEmptyRadioGroupRequest {
  radio_group_id: string;

  constructor(props: $Shape<DeleteEmptyRadioGroupRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }
  }
}
export {DeleteEmptyRadioGroupRequest};

class DeleteEmptyRadioGroupReply {
  constructor(props: $Shape<DeleteEmptyRadioGroupReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteEmptyRadioGroupReply};

class SearchRadioGroupsRequest {
  query: string;
  limit: number;

  constructor(props: $Shape<SearchRadioGroupsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = '';
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {SearchRadioGroupsRequest};

class SearchRadioGroupsReply_ApInfo {
  name: string;
  heading: string;

  constructor(props: $Shape<SearchRadioGroupsReply_ApInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.heading = '';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }
  }
}
export {SearchRadioGroupsReply_ApInfo};
class SearchRadioGroupsReply_Result {
  id: string;
  radio_group: ?cwn_radios.RadioGroup;
  aps: Array<SearchRadioGroupsReply_ApInfo>;

  constructor(props: $Shape<SearchRadioGroupsReply_Result> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }

    this.aps = [];
    if (props.hasOwnProperty('aps')) {
      const v = props.aps;
      if (!Array.isArray(v)) {
        throw new Error('repeated field aps should be array');
      }
      this.aps = v.map(function(v) {
        return new SearchRadioGroupsReply_ApInfo(v);
      });
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }
}
export {SearchRadioGroupsReply_Result};
class SearchRadioGroupsReply {
  radio_groups: Array<cwn_radios.RadioGroup>;
  results: Array<SearchRadioGroupsReply_Result>;

  constructor(props: $Shape<SearchRadioGroupsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_groups = [];
    if (props.hasOwnProperty('radio_groups')) {
      const v = props.radio_groups;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_groups should be array');
      }
      this.radio_groups = v.map(function(v) {
        return new cwn_radios.RadioGroup(v);
      });
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new SearchRadioGroupsReply_Result(v);
      });
    }
  }
}
export {SearchRadioGroupsReply};

class IsRadioGroupChannelLockedReply {
  channel_locked: boolean;

  constructor(props: $Shape<IsRadioGroupChannelLockedReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.channel_locked = false;
    if (props.hasOwnProperty('channel_locked')) {
      const v = props.channel_locked;
      this.channel_locked = !!v;
    }
  }
}
export {IsRadioGroupChannelLockedReply};

class LockRadioGroupChannelRequest {
  radio_group_id: string;
  site_id: string;

  constructor(props: $Shape<LockRadioGroupChannelRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {LockRadioGroupChannelRequest};

export const ListPossibleChannelsRequest_ModeValues = {
  INVALID: 0,
  PTMP: 1,
  PTP: 2,
  UNII_PTMP: 3,
  UNII_PTP: 4,
  ALL_PTP: 5,
  ALL_PTMP: 6,
};
type ListPossibleChannelsRequest_Mode = $Keys<
  typeof ListPossibleChannelsRequest_ModeValues,
>;
export type {ListPossibleChannelsRequest_Mode};

export function ListPossibleChannelsRequest_ModeValue(
  n: number,
): ListPossibleChannelsRequest_Mode {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'PTMP';

    case 2:
      return 'PTP';

    case 3:
      return 'UNII_PTMP';

    case 4:
      return 'UNII_PTP';

    case 5:
      return 'ALL_PTP';

    case 6:
      return 'ALL_PTMP';

    default:
      return 'INVALID';
  }
}

class ListPossibleChannelsRequest {
  mode: ListPossibleChannelsRequest_Mode;

  constructor(props: $Shape<ListPossibleChannelsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = ListPossibleChannelsRequest_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }
  }
}
export {ListPossibleChannelsRequest};

class ListPossibleChannelsReply {
  channels: Array<cwn_radios.Channel>;

  constructor(props: $Shape<ListPossibleChannelsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.channels = [];
    if (props.hasOwnProperty('channels')) {
      const v = props.channels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field channels should be array');
      }
      this.channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }
  }
}
export {ListPossibleChannelsReply};

class ListAvailableChannelsForSitesRequest {
  site_ids: Array<string>;

  constructor(props: $Shape<ListAvailableChannelsForSitesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_ids = [];
    if (props.hasOwnProperty('site_ids')) {
      const v = props.site_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_ids should be array');
      }
      this.site_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListAvailableChannelsForSitesRequest};

class ListAvailableChannelsForSitesReply {
  possible_ptp_channels: Array<cwn_radios.Channel>;
  possible_ptmp_channels: Array<cwn_radios.Channel>;
  available_ptp_channels: Array<cwn_radios.Channel>;
  available_ptmp_channels: Array<cwn_radios.Channel>;

  constructor(props: $Shape<ListAvailableChannelsForSitesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.possible_ptp_channels = [];
    if (props.hasOwnProperty('possible_ptp_channels')) {
      const v = props.possible_ptp_channels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field possible_ptp_channels should be array');
      }
      this.possible_ptp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.possible_ptmp_channels = [];
    if (props.hasOwnProperty('possible_ptmp_channels')) {
      const v = props.possible_ptmp_channels;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field possible_ptmp_channels should be array',
        );
      }
      this.possible_ptmp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.available_ptp_channels = [];
    if (props.hasOwnProperty('available_ptp_channels')) {
      const v = props.available_ptp_channels;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field available_ptp_channels should be array',
        );
      }
      this.available_ptp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.available_ptmp_channels = [];
    if (props.hasOwnProperty('available_ptmp_channels')) {
      const v = props.available_ptmp_channels;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field available_ptmp_channels should be array',
        );
      }
      this.available_ptmp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }
  }
}
export {ListAvailableChannelsForSitesReply};

class ListPossibleRadioConfigurationsRequest {
  constructor(
    props: $Shape<ListPossibleRadioConfigurationsRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListPossibleRadioConfigurationsRequest};

class ListPossibleRadioConfigurationsReply_Powers {
  powers: Array<number>;

  constructor(
    props: $Shape<ListPossibleRadioConfigurationsReply_Powers> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.powers = [];
    if (props.hasOwnProperty('powers')) {
      const v = props.powers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field powers should be array');
      }
      this.powers = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListPossibleRadioConfigurationsReply_Powers};
class ListPossibleRadioConfigurationsReply_Beamwidths {
  beam_widths: Array<string>;

  constructor(
    props: $Shape<ListPossibleRadioConfigurationsReply_Beamwidths> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.beam_widths = [];
    if (props.hasOwnProperty('beam_widths')) {
      const v = props.beam_widths;
      if (!Array.isArray(v)) {
        throw new Error('repeated field beam_widths should be array');
      }
      this.beam_widths = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListPossibleRadioConfigurationsReply_Beamwidths};
class ListPossibleRadioConfigurationsReply {
  activated_ptp_channels: Array<cwn_radios.Channel>;
  activated_ptmp_channels: Array<cwn_radios.Channel>;
  radio_models: Array<cwn_radios.Radio>;
  antenna_models: Array<cwn_antenna.Antenna>;
  tx_power_by_model: {
    [key: string]: ?ListPossibleRadioConfigurationsReply_Powers,
  };
  metrolinq_channels: Array<cwn_radios.Channel>;
  beamwidths_by_model: {
    [key: string]: ?ListPossibleRadioConfigurationsReply_Beamwidths,
  };

  constructor(props: $Shape<ListPossibleRadioConfigurationsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.activated_ptp_channels = [];
    if (props.hasOwnProperty('activated_ptp_channels')) {
      const v = props.activated_ptp_channels;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field activated_ptp_channels should be array',
        );
      }
      this.activated_ptp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.activated_ptmp_channels = [];
    if (props.hasOwnProperty('activated_ptmp_channels')) {
      const v = props.activated_ptmp_channels;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field activated_ptmp_channels should be array',
        );
      }
      this.activated_ptmp_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.radio_models = [];
    if (props.hasOwnProperty('radio_models')) {
      const v = props.radio_models;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_models should be array');
      }
      this.radio_models = v.map(function(v) {
        return new cwn_radios.Radio(v);
      });
    }

    this.antenna_models = [];
    if (props.hasOwnProperty('antenna_models')) {
      const v = props.antenna_models;
      if (!Array.isArray(v)) {
        throw new Error('repeated field antenna_models should be array');
      }
      this.antenna_models = v.map(function(v) {
        return new cwn_antenna.Antenna(v);
      });
    }

    this.tx_power_by_model = {};
    if (props.hasOwnProperty('tx_power_by_model')) {
      const v = props.tx_power_by_model;
      for (const key: any of Object.keys(v)) {
        this.tx_power_by_model[
          key
        ] = new ListPossibleRadioConfigurationsReply_Powers((v[key]: any));
      }
    }

    this.metrolinq_channels = [];
    if (props.hasOwnProperty('metrolinq_channels')) {
      const v = props.metrolinq_channels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field metrolinq_channels should be array');
      }
      this.metrolinq_channels = v.map(function(v) {
        return new cwn_radios.Channel(v);
      });
    }

    this.beamwidths_by_model = {};
    if (props.hasOwnProperty('beamwidths_by_model')) {
      const v = props.beamwidths_by_model;
      for (const key: any of Object.keys(v)) {
        this.beamwidths_by_model[
          key
        ] = new ListPossibleRadioConfigurationsReply_Beamwidths((v[key]: any));
      }
    }
  }
}
export {ListPossibleRadioConfigurationsReply};

class GenerateSignupLinkV2Request {
  lead_id: string;

  constructor(props: $Shape<GenerateSignupLinkV2Request> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {GenerateSignupLinkV2Request};

class GenerateSignupLinkV2Reply {
  link: string;

  constructor(props: $Shape<GenerateSignupLinkV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.link = '';
    if (props.hasOwnProperty('link')) {
      const v = props.link;
      this.link = v;
    }
  }
}
export {GenerateSignupLinkV2Reply};

class RadioStatus {
  parsed: ?cwn_radios.Status;
  airmax: ?cwn_ubnt_status.AirmaxStatus;
  metro: ?cwn_ignitenet_metrolinq.Status;
  airfiber: ?cwn_ubnt_status.AirfiberStatus;
  w60g: ?cwn_mikrotik_w60g.Status;

  constructor(props: $Shape<RadioStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.parsed = null;
    if (props.hasOwnProperty('parsed')) {
      const v = props.parsed;
      this.parsed = v && new cwn_radios.Status(v);
    }

    this.airmax = null;
    if (props.hasOwnProperty('airmax')) {
      const v = props.airmax;
      this.airmax = v && new cwn_ubnt_status.AirmaxStatus(v);
    }

    this.metro = null;
    if (props.hasOwnProperty('metro')) {
      const v = props.metro;
      this.metro = v && new cwn_ignitenet_metrolinq.Status(v);
    }

    this.airfiber = null;
    if (props.hasOwnProperty('airfiber')) {
      const v = props.airfiber;
      this.airfiber = v && new cwn_ubnt_status.AirfiberStatus(v);
    }

    this.w60g = null;
    if (props.hasOwnProperty('w60g')) {
      const v = props.w60g;
      this.w60g = v && new cwn_mikrotik_w60g.Status(v);
    }
  }

  getParsed(): cwn_radios.Status {
    if (this.parsed) {
      return this.parsed;
    }
    return new cwn_radios.Status();
  }

  getAirmax(): cwn_ubnt_status.AirmaxStatus {
    if (this.airmax) {
      return this.airmax;
    }
    return new cwn_ubnt_status.AirmaxStatus();
  }

  getMetro(): cwn_ignitenet_metrolinq.Status {
    if (this.metro) {
      return this.metro;
    }
    return new cwn_ignitenet_metrolinq.Status();
  }

  getAirfiber(): cwn_ubnt_status.AirfiberStatus {
    if (this.airfiber) {
      return this.airfiber;
    }
    return new cwn_ubnt_status.AirfiberStatus();
  }

  getW60g(): cwn_mikrotik_w60g.Status {
    if (this.w60g) {
      return this.w60g;
    }
    return new cwn_mikrotik_w60g.Status();
  }
}
export {RadioStatus};

class ListRadioStatusesRequest {
  mac: string;
  last_seen: ?Date;
  limit: number;
  cursor: string;
  sparse: boolean;

  constructor(props: $Shape<ListRadioStatusesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.last_seen = null;
    if (props.hasOwnProperty('last_seen')) {
      const v = props.last_seen;
      this.last_seen = v && new Date(v);
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.sparse = false;
    if (props.hasOwnProperty('sparse')) {
      const v = props.sparse;
      this.sparse = !!v;
    }
  }
}
export {ListRadioStatusesRequest};

class ListRadioStatusesReply {
  radio_statuses: Array<RadioStatus>;
  cursor: string;

  constructor(props: $Shape<ListRadioStatusesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_statuses = [];
    if (props.hasOwnProperty('radio_statuses')) {
      const v = props.radio_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_statuses should be array');
      }
      this.radio_statuses = v.map(function(v) {
        return new RadioStatus(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListRadioStatusesReply};

class ListRadioStatusesSinceRequest {
  mac: string;
  since: ?Date;

  constructor(props: $Shape<ListRadioStatusesSinceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.since = null;
    if (props.hasOwnProperty('since')) {
      const v = props.since;
      this.since = v && new Date(v);
    }
  }
}
export {ListRadioStatusesSinceRequest};

class ListRadioStatusesSinceReply {
  radio_statuses: Array<RadioStatus>;

  constructor(props: $Shape<ListRadioStatusesSinceReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_statuses = [];
    if (props.hasOwnProperty('radio_statuses')) {
      const v = props.radio_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_statuses should be array');
      }
      this.radio_statuses = v.map(function(v) {
        return new RadioStatus(v);
      });
    }
  }
}
export {ListRadioStatusesSinceReply};

class ListInvoicesRequest {
  constructor(props: $Shape<ListInvoicesRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListInvoicesRequest};

class ListInvoicesReply {
  invoices: Array<cwn_billing.Invoice>;

  constructor(props: $Shape<ListInvoicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoices = [];
    if (props.hasOwnProperty('invoices')) {
      const v = props.invoices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field invoices should be array');
      }
      this.invoices = v.map(function(v) {
        return new cwn_billing.Invoice(v);
      });
    }
  }
}
export {ListInvoicesReply};

class SearchInvoicesRequest {
  query: ?cwn_search.Query;

  constructor(props: $Shape<SearchInvoicesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = null;
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v && new cwn_search.Query(v);
    }
  }

  getQuery(): cwn_search.Query {
    if (this.query) {
      return this.query;
    }
    return new cwn_search.Query();
  }
}
export {SearchInvoicesRequest};

class SearchInvoicesReply {
  invoices: Array<cwn_billing.Invoice>;
  cursor: string;

  constructor(props: $Shape<SearchInvoicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoices = [];
    if (props.hasOwnProperty('invoices')) {
      const v = props.invoices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field invoices should be array');
      }
      this.invoices = v.map(function(v) {
        return new cwn_billing.Invoice(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {SearchInvoicesReply};

class GetInvoiceRequest {
  invoice_id: string;

  constructor(props: $Shape<GetInvoiceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoice_id = '0';
    if (props.hasOwnProperty('invoice_id')) {
      const v = props.invoice_id;
      this.invoice_id = v;
    }
  }
}
export {GetInvoiceRequest};

class GetInvoiceReply {
  invoice: ?cwn_billing.Invoice;
  receipt_html: string;

  constructor(props: $Shape<GetInvoiceReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoice = null;
    if (props.hasOwnProperty('invoice')) {
      const v = props.invoice;
      this.invoice = v && new cwn_billing.Invoice(v);
    }

    this.receipt_html = '';
    if (props.hasOwnProperty('receipt_html')) {
      const v = props.receipt_html;
      this.receipt_html = v;
    }
  }

  getInvoice(): cwn_billing.Invoice {
    if (this.invoice) {
      return this.invoice;
    }
    return new cwn_billing.Invoice();
  }
}
export {GetInvoiceReply};

class GetInvoicesForSubscriptionRequest {
  subscription_id: string;

  constructor(props: $Shape<GetInvoicesForSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {GetInvoicesForSubscriptionRequest};

class GetInvoicesForSubscriptionReply {
  invoices: Array<cwn_billing.Invoice>;

  constructor(props: $Shape<GetInvoicesForSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoices = [];
    if (props.hasOwnProperty('invoices')) {
      const v = props.invoices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field invoices should be array');
      }
      this.invoices = v.map(function(v) {
        return new cwn_billing.Invoice(v);
      });
    }
  }
}
export {GetInvoicesForSubscriptionReply};

class GetActiveLedgerRequest {
  subscription_id: string;

  constructor(props: $Shape<GetActiveLedgerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {GetActiveLedgerRequest};

class GetActiveLedgerResponse {
  ledger: ?cwn_billing.Ledger;
  balance: ?cwn_billing.Money;
  upcoming_coupons: Array<cwn_billing.Coupon>;

  constructor(props: $Shape<GetActiveLedgerResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.ledger = null;
    if (props.hasOwnProperty('ledger')) {
      const v = props.ledger;
      this.ledger = v && new cwn_billing.Ledger(v);
    }

    this.balance = null;
    if (props.hasOwnProperty('balance')) {
      const v = props.balance;
      this.balance = v && new cwn_billing.Money(v);
    }

    this.upcoming_coupons = [];
    if (props.hasOwnProperty('upcoming_coupons')) {
      const v = props.upcoming_coupons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field upcoming_coupons should be array');
      }
      this.upcoming_coupons = v.map(function(v) {
        return new cwn_billing.Coupon(v);
      });
    }
  }

  getLedger(): cwn_billing.Ledger {
    if (this.ledger) {
      return this.ledger;
    }
    return new cwn_billing.Ledger();
  }

  getBalance(): cwn_billing.Money {
    if (this.balance) {
      return this.balance;
    }
    return new cwn_billing.Money();
  }
}
export {GetActiveLedgerResponse};

class ResendReceiptEmailRequest {
  invoice_id: string;

  constructor(props: $Shape<ResendReceiptEmailRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoice_id = '0';
    if (props.hasOwnProperty('invoice_id')) {
      const v = props.invoice_id;
      this.invoice_id = v;
    }
  }
}
export {ResendReceiptEmailRequest};

class ResendReceiptEmailReply {
  constructor(props: $Shape<ResendReceiptEmailReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ResendReceiptEmailReply};

class AddCouponRequest {
  billing_id: string;
  coupon: ?cwn_billing.Coupon;

  constructor(props: $Shape<AddCouponRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.billing_id = '0';
    if (props.hasOwnProperty('billing_id')) {
      const v = props.billing_id;
      this.billing_id = v;
    }

    this.coupon = null;
    if (props.hasOwnProperty('coupon')) {
      const v = props.coupon;
      this.coupon = v && new cwn_billing.Coupon(v);
    }
  }

  getCoupon(): cwn_billing.Coupon {
    if (this.coupon) {
      return this.coupon;
    }
    return new cwn_billing.Coupon();
  }
}
export {AddCouponRequest};

class AddCouponReply {
  coupon: ?cwn_billing.Coupon;

  constructor(props: $Shape<AddCouponReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.coupon = null;
    if (props.hasOwnProperty('coupon')) {
      const v = props.coupon;
      this.coupon = v && new cwn_billing.Coupon(v);
    }
  }

  getCoupon(): cwn_billing.Coupon {
    if (this.coupon) {
      return this.coupon;
    }
    return new cwn_billing.Coupon();
  }
}
export {AddCouponReply};

class AddLedgerEntryRequest {
  subscription_id: string;
  entry: ?cwn_billing.LedgerEntry;

  constructor(props: $Shape<AddLedgerEntryRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.entry = null;
    if (props.hasOwnProperty('entry')) {
      const v = props.entry;
      this.entry = v && new cwn_billing.LedgerEntry(v);
    }
  }

  getEntry(): cwn_billing.LedgerEntry {
    if (this.entry) {
      return this.entry;
    }
    return new cwn_billing.LedgerEntry();
  }
}
export {AddLedgerEntryRequest};

class DeleteCouponRequest {
  billing_id: string;
  coupon_id: string;

  constructor(props: $Shape<DeleteCouponRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.billing_id = '0';
    if (props.hasOwnProperty('billing_id')) {
      const v = props.billing_id;
      this.billing_id = v;
    }

    this.coupon_id = '0';
    if (props.hasOwnProperty('coupon_id')) {
      const v = props.coupon_id;
      this.coupon_id = v;
    }
  }
}
export {DeleteCouponRequest};

class DeleteCouponReply {
  constructor(props: $Shape<DeleteCouponReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteCouponReply};

class PostAreaRequest {
  area: ?cwn_customers.Area;

  constructor(props: $Shape<PostAreaRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.area = null;
    if (props.hasOwnProperty('area')) {
      const v = props.area;
      this.area = v && new cwn_customers.Area(v);
    }
  }

  getArea(): cwn_customers.Area {
    if (this.area) {
      return this.area;
    }
    return new cwn_customers.Area();
  }
}
export {PostAreaRequest};

class PostAreaReply {
  area: ?cwn_customers.Area;

  constructor(props: $Shape<PostAreaReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.area = null;
    if (props.hasOwnProperty('area')) {
      const v = props.area;
      this.area = v && new cwn_customers.Area(v);
    }
  }

  getArea(): cwn_customers.Area {
    if (this.area) {
      return this.area;
    }
    return new cwn_customers.Area();
  }
}
export {PostAreaReply};

class PutAreaRequest {
  area: ?cwn_customers.Area;

  constructor(props: $Shape<PutAreaRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.area = null;
    if (props.hasOwnProperty('area')) {
      const v = props.area;
      this.area = v && new cwn_customers.Area(v);
    }
  }

  getArea(): cwn_customers.Area {
    if (this.area) {
      return this.area;
    }
    return new cwn_customers.Area();
  }
}
export {PutAreaRequest};

class PutAreaReply {
  constructor(props: $Shape<PutAreaReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutAreaReply};

class ListAreasRequest {
  constructor(props: $Shape<ListAreasRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListAreasRequest};

class ListAreasReply {
  areas: Array<cwn_customers.Area>;

  constructor(props: $Shape<ListAreasReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.areas = [];
    if (props.hasOwnProperty('areas')) {
      const v = props.areas;
      if (!Array.isArray(v)) {
        throw new Error('repeated field areas should be array');
      }
      this.areas = v.map(function(v) {
        return new cwn_customers.Area(v);
      });
    }
  }
}
export {ListAreasReply};

class GetAreaRequest {
  id: string;

  constructor(props: $Shape<GetAreaRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {GetAreaRequest};

class GetAreaReply {
  area: ?cwn_customers.Area;

  constructor(props: $Shape<GetAreaReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.area = null;
    if (props.hasOwnProperty('area')) {
      const v = props.area;
      this.area = v && new cwn_customers.Area(v);
    }
  }

  getArea(): cwn_customers.Area {
    if (this.area) {
      return this.area;
    }
    return new cwn_customers.Area();
  }
}
export {GetAreaReply};

class PutOfferRequest {
  offer: ?cwn_signup.Offer;

  constructor(props: $Shape<PutOfferRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.offer = null;
    if (props.hasOwnProperty('offer')) {
      const v = props.offer;
      this.offer = v && new cwn_signup.Offer(v);
    }
  }

  getOffer(): cwn_signup.Offer {
    if (this.offer) {
      return this.offer;
    }
    return new cwn_signup.Offer();
  }
}
export {PutOfferRequest};

class PutOfferReply {
  constructor(props: $Shape<PutOfferReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutOfferReply};

class ListOffersRequest {
  constructor(props: $Shape<ListOffersRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListOffersRequest};

class ListOffersReply {
  offers: Array<cwn_signup.Offer>;

  constructor(props: $Shape<ListOffersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.offers = [];
    if (props.hasOwnProperty('offers')) {
      const v = props.offers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field offers should be array');
      }
      this.offers = v.map(function(v) {
        return new cwn_signup.Offer(v);
      });
    }
  }
}
export {ListOffersReply};

class DeleteOfferRequest {
  signup_source: string;

  constructor(props: $Shape<DeleteOfferRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.signup_source = '';
    if (props.hasOwnProperty('signup_source')) {
      const v = props.signup_source;
      this.signup_source = v;
    }
  }
}
export {DeleteOfferRequest};

class DeleteOfferReply {
  constructor(props: $Shape<DeleteOfferReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteOfferReply};

class OverrideOfferRequest {
  lead_id: string;
  offer_override: string;

  constructor(props: $Shape<OverrideOfferRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.offer_override = '';
    if (props.hasOwnProperty('offer_override')) {
      const v = props.offer_override;
      this.offer_override = v;
    }
  }
}
export {OverrideOfferRequest};

class OverrideOfferReply {
  constructor(props: $Shape<OverrideOfferReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {OverrideOfferReply};

class PostInstrumentRequest {
  instrument: ?cwn_billing.Instrument;
  subscription_id: string;
  customer_id: string;

  constructor(props: $Shape<PostInstrumentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new cwn_billing.Instrument(v);
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }
  }

  getInstrument(): cwn_billing.Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new cwn_billing.Instrument();
  }
}
export {PostInstrumentRequest};

class ChargeLedgerRequest {
  ledger: ?cwn_billing.Ledger;

  constructor(props: $Shape<ChargeLedgerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.ledger = null;
    if (props.hasOwnProperty('ledger')) {
      const v = props.ledger;
      this.ledger = v && new cwn_billing.Ledger(v);
    }
  }

  getLedger(): cwn_billing.Ledger {
    if (this.ledger) {
      return this.ledger;
    }
    return new cwn_billing.Ledger();
  }
}
export {ChargeLedgerRequest};

export const ScheduleSpeedTestRequest_StreamTypeValues = {
  UNKNOWN: 0,
  SINGLE: 1,
  PARALLEL: 2,
};
type ScheduleSpeedTestRequest_StreamType = $Keys<
  typeof ScheduleSpeedTestRequest_StreamTypeValues,
>;
export type {ScheduleSpeedTestRequest_StreamType};

export function ScheduleSpeedTestRequest_StreamTypeValue(
  n: number,
): ScheduleSpeedTestRequest_StreamType {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'SINGLE';

    case 2:
      return 'PARALLEL';

    default:
      return 'UNKNOWN';
  }
}

class ScheduleSpeedTestRequest {
  device_id: string;
  streams: ScheduleSpeedTestRequest_StreamType;

  constructor(props: $Shape<ScheduleSpeedTestRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.streams = ScheduleSpeedTestRequest_StreamTypeValue(0);
    if (props.hasOwnProperty('streams')) {
      const v = props.streams;
      this.streams = v;
    }
  }
}
export {ScheduleSpeedTestRequest};

class ScheduleSpeedTestReply {
  constructor(props: $Shape<ScheduleSpeedTestReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ScheduleSpeedTestReply};

class GetRecentSpeedAverageForDeviceRequest {
  device_id: string;

  constructor(props: $Shape<GetRecentSpeedAverageForDeviceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetRecentSpeedAverageForDeviceRequest};

class GetRecentSpeedAverageForDeviceReply {
  upload: number;
  download: number;

  constructor(props: $Shape<GetRecentSpeedAverageForDeviceReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.upload = 0;
    if (props.hasOwnProperty('upload')) {
      const v = props.upload;
      this.upload = v;
    }

    this.download = 0;
    if (props.hasOwnProperty('download')) {
      const v = props.download;
      this.download = v;
    }
  }
}
export {GetRecentSpeedAverageForDeviceReply};

class CreateSiteRequest {
  constructor(props: $Shape<CreateSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {CreateSiteRequest};

class CreateSiteReply {
  site: ?cwn_site.Site;

  constructor(props: $Shape<CreateSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {CreateSiteReply};

class GetSiteRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteRequest};

class GetSiteReply {
  site: ?cwn_site.Site;

  constructor(props: $Shape<GetSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {GetSiteReply};

class GetSitesRequest {
  site_ids: Array<string>;

  constructor(props: $Shape<GetSitesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_ids = [];
    if (props.hasOwnProperty('site_ids')) {
      const v = props.site_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_ids should be array');
      }
      this.site_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetSitesRequest};

class GetSitesReply {
  sites: Array<cwn_site.Site>;

  constructor(props: $Shape<GetSitesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }
  }
}
export {GetSitesReply};

class GetSiteBatchRequest {
  site_ids: Array<string>;

  constructor(props: $Shape<GetSiteBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_ids = [];
    if (props.hasOwnProperty('site_ids')) {
      const v = props.site_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_ids should be array');
      }
      this.site_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetSiteBatchRequest};

class GetSiteBatchReply {
  sites: Array<cwn_site.Site>;

  constructor(props: $Shape<GetSiteBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }
  }
}
export {GetSiteBatchReply};

class GetSiteFromDeviceIDBatchRequest {
  device_ids: Array<string>;

  constructor(props: $Shape<GetSiteFromDeviceIDBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_ids = [];
    if (props.hasOwnProperty('device_ids')) {
      const v = props.device_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field device_ids should be array');
      }
      this.device_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetSiteFromDeviceIDBatchRequest};

class GetSiteFromDeviceIDBatchReply {
  sites: Array<cwn_site.Site>;

  constructor(props: $Shape<GetSiteFromDeviceIDBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }
  }
}
export {GetSiteFromDeviceIDBatchReply};

class GetSiteTargetSummaryRequest {
  site_id: string;
  for_freq_plan_id: string;

  constructor(props: $Shape<GetSiteTargetSummaryRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.for_freq_plan_id = '0';
    if (props.hasOwnProperty('for_freq_plan_id')) {
      const v = props.for_freq_plan_id;
      this.for_freq_plan_id = v;
    }
  }
}
export {GetSiteTargetSummaryRequest};

class GetSiteTargetSummaryReply_SrlSummary {
  site: ?cwn_site.Site;
  srl: ?cwn_site.SiteRadioLink;
  customer_subscription_results: Array<
    cwn_customers.CustomerSubscriptionResult,
  >;
  radio: ?cwn_radios.Radio;

  constructor(props: $Shape<GetSiteTargetSummaryReply_SrlSummary> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new cwn_site.SiteRadioLink(v);
    }

    this.customer_subscription_results = [];
    if (props.hasOwnProperty('customer_subscription_results')) {
      const v = props.customer_subscription_results;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field customer_subscription_results should be array',
        );
      }
      this.customer_subscription_results = v.map(function(v) {
        return new cwn_customers.CustomerSubscriptionResult(v);
      });
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new cwn_radios.Radio(v);
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }

  getSrl(): cwn_site.SiteRadioLink {
    if (this.srl) {
      return this.srl;
    }
    return new cwn_site.SiteRadioLink();
  }

  getRadio(): cwn_radios.Radio {
    if (this.radio) {
      return this.radio;
    }
    return new cwn_radios.Radio();
  }
}
export {GetSiteTargetSummaryReply_SrlSummary};
class GetSiteTargetSummaryReply_TargetSummary {
  target_srls: Array<GetSiteTargetSummaryReply_SrlSummary>;
  radio_group: ?cwn_radios.RadioGroup;
  channel_locked: boolean;
  srl: ?cwn_site.SiteRadioLink;
  discovered: boolean;
  radio: ?cwn_radios.Radio;

  constructor(
    props: $Shape<GetSiteTargetSummaryReply_TargetSummary> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.target_srls = [];
    if (props.hasOwnProperty('target_srls')) {
      const v = props.target_srls;
      if (!Array.isArray(v)) {
        throw new Error('repeated field target_srls should be array');
      }
      this.target_srls = v.map(function(v) {
        return new GetSiteTargetSummaryReply_SrlSummary(v);
      });
    }

    this.radio_group = null;
    if (props.hasOwnProperty('radio_group')) {
      const v = props.radio_group;
      this.radio_group = v && new cwn_radios.RadioGroup(v);
    }

    this.channel_locked = false;
    if (props.hasOwnProperty('channel_locked')) {
      const v = props.channel_locked;
      this.channel_locked = !!v;
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new cwn_site.SiteRadioLink(v);
    }

    this.discovered = false;
    if (props.hasOwnProperty('discovered')) {
      const v = props.discovered;
      this.discovered = !!v;
    }

    this.radio = null;
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v && new cwn_radios.Radio(v);
    }
  }

  getRadioGroup(): cwn_radios.RadioGroup {
    if (this.radio_group) {
      return this.radio_group;
    }
    return new cwn_radios.RadioGroup();
  }

  getSrl(): cwn_site.SiteRadioLink {
    if (this.srl) {
      return this.srl;
    }
    return new cwn_site.SiteRadioLink();
  }

  getRadio(): cwn_radios.Radio {
    if (this.radio) {
      return this.radio;
    }
    return new cwn_radios.Radio();
  }
}
export {GetSiteTargetSummaryReply_TargetSummary};
class GetSiteTargetSummaryReply {
  local_site: ?cwn_site.Site;
  local_srls: Array<GetSiteTargetSummaryReply_TargetSummary>;
  radios_missing_config: Array<cwn_radios.Radio>;

  constructor(props: $Shape<GetSiteTargetSummaryReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.local_site = null;
    if (props.hasOwnProperty('local_site')) {
      const v = props.local_site;
      this.local_site = v && new cwn_site.Site(v);
    }

    this.local_srls = [];
    if (props.hasOwnProperty('local_srls')) {
      const v = props.local_srls;
      if (!Array.isArray(v)) {
        throw new Error('repeated field local_srls should be array');
      }
      this.local_srls = v.map(function(v) {
        return new GetSiteTargetSummaryReply_TargetSummary(v);
      });
    }

    this.radios_missing_config = [];
    if (props.hasOwnProperty('radios_missing_config')) {
      const v = props.radios_missing_config;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radios_missing_config should be array');
      }
      this.radios_missing_config = v.map(function(v) {
        return new cwn_radios.Radio(v);
      });
    }
  }

  getLocalSite(): cwn_site.Site {
    if (this.local_site) {
      return this.local_site;
    }
    return new cwn_site.Site();
  }
}
export {GetSiteTargetSummaryReply};

class GetSiteBridgeConfigsRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteBridgeConfigsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteBridgeConfigsRequest};

class GetSiteBridgeConfigsReply {
  configs: Array<cwn_site.SiteBridgeConfig>;

  constructor(props: $Shape<GetSiteBridgeConfigsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.configs = [];
    if (props.hasOwnProperty('configs')) {
      const v = props.configs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field configs should be array');
      }
      this.configs = v.map(function(v) {
        return new cwn_site.SiteBridgeConfig(v);
      });
    }
  }
}
export {GetSiteBridgeConfigsReply};

class PutSiteBridgeConfigRequest {
  config: ?cwn_site.SiteBridgeConfig;

  constructor(props: $Shape<PutSiteBridgeConfigRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.config = null;
    if (props.hasOwnProperty('config')) {
      const v = props.config;
      this.config = v && new cwn_site.SiteBridgeConfig(v);
    }
  }

  getConfig(): cwn_site.SiteBridgeConfig {
    if (this.config) {
      return this.config;
    }
    return new cwn_site.SiteBridgeConfig();
  }
}
export {PutSiteBridgeConfigRequest};

class PutSiteBridgeConfigReply {
  id: string;

  constructor(props: $Shape<PutSiteBridgeConfigReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {PutSiteBridgeConfigReply};

class DeleteSiteBridgeConfigRequest {
  config: ?cwn_site.SiteBridgeConfig;

  constructor(props: $Shape<DeleteSiteBridgeConfigRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.config = null;
    if (props.hasOwnProperty('config')) {
      const v = props.config;
      this.config = v && new cwn_site.SiteBridgeConfig(v);
    }
  }

  getConfig(): cwn_site.SiteBridgeConfig {
    if (this.config) {
      return this.config;
    }
    return new cwn_site.SiteBridgeConfig();
  }
}
export {DeleteSiteBridgeConfigRequest};

class DeleteSiteBridgeConfigReply {
  constructor(props: $Shape<DeleteSiteBridgeConfigReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteSiteBridgeConfigReply};

class GetSiteRadioLinkBatchRequest {
  srl_ids: Array<string>;

  constructor(props: $Shape<GetSiteRadioLinkBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_ids = [];
    if (props.hasOwnProperty('srl_ids')) {
      const v = props.srl_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field srl_ids should be array');
      }
      this.srl_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetSiteRadioLinkBatchRequest};

class GetSiteRadioLinkBatchReply {
  site_radio_links: Array<cwn_site.SiteRadioLink>;

  constructor(props: $Shape<GetSiteRadioLinkBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new cwn_site.SiteRadioLink(v);
      });
    }
  }
}
export {GetSiteRadioLinkBatchReply};

class ListSiteRadioLinksForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListSiteRadioLinksForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListSiteRadioLinksForSiteRequest};

class ListSiteRadioLinksReply {
  site_radio_links: Array<cwn_site.SiteRadioLink>;

  constructor(props: $Shape<ListSiteRadioLinksReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new cwn_site.SiteRadioLink(v);
      });
    }
  }
}
export {ListSiteRadioLinksReply};

class ListLiveSiteRadioLinksForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListLiveSiteRadioLinksForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListLiveSiteRadioLinksForSiteRequest};

class ListLiveSiteRadioLinksForSiteReply {
  site_radio_links: Array<cwn_site.SiteRadioLink>;

  constructor(props: $Shape<ListLiveSiteRadioLinksForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new cwn_site.SiteRadioLink(v);
      });
    }
  }
}
export {ListLiveSiteRadioLinksForSiteReply};

class ListActiveShotsForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListActiveShotsForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListActiveShotsForSiteRequest};

class ListActiveShotsForSiteReply_ActiveShot {
  site_mount_id: string;
  target_mount_id: string;
  target_site_id: string;

  constructor(
    props: $Shape<ListActiveShotsForSiteReply_ActiveShot> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.site_mount_id = '0';
    if (props.hasOwnProperty('site_mount_id')) {
      const v = props.site_mount_id;
      this.site_mount_id = v;
    }

    this.target_mount_id = '0';
    if (props.hasOwnProperty('target_mount_id')) {
      const v = props.target_mount_id;
      this.target_mount_id = v;
    }

    this.target_site_id = '';
    if (props.hasOwnProperty('target_site_id')) {
      const v = props.target_site_id;
      this.target_site_id = v;
    }
  }
}
export {ListActiveShotsForSiteReply_ActiveShot};
class ListActiveShotsForSiteReply {
  active_shots: Array<ListActiveShotsForSiteReply_ActiveShot>;
  mounts: {[key: string]: ?cwn_geo.Mount};
  sites: {[key: string]: ?cwn_site.Site};

  constructor(props: $Shape<ListActiveShotsForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.active_shots = [];
    if (props.hasOwnProperty('active_shots')) {
      const v = props.active_shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field active_shots should be array');
      }
      this.active_shots = v.map(function(v) {
        return new ListActiveShotsForSiteReply_ActiveShot(v);
      });
    }

    this.mounts = {};
    if (props.hasOwnProperty('mounts')) {
      const v = props.mounts;
      for (const key: any of Object.keys(v)) {
        this.mounts[key] = new cwn_geo.Mount((v[key]: any));
      }
    }

    this.sites = {};
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      for (const key: any of Object.keys(v)) {
        this.sites[key] = new cwn_site.Site((v[key]: any));
      }
    }
  }
}
export {ListActiveShotsForSiteReply};

class ListStaMacAddressesForAreaRequest {
  area_id: string;

  constructor(props: $Shape<ListStaMacAddressesForAreaRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }
  }
}
export {ListStaMacAddressesForAreaRequest};

class ListStaMacAddressesForAreaReply {
  mac_addresses: Array<string>;

  constructor(props: $Shape<ListStaMacAddressesForAreaReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_addresses = [];
    if (props.hasOwnProperty('mac_addresses')) {
      const v = props.mac_addresses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mac_addresses should be array');
      }
      this.mac_addresses = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListStaMacAddressesForAreaReply};

class GetSiteEdgeWeightOverridesRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteEdgeWeightOverridesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteEdgeWeightOverridesRequest};

class GetSiteEdgeWeightOverridesResponse {
  edge_weight_overrides: Array<cwn_network_routing.EdgeWeightOverride>;
  site_weight_overrides: Array<cwn_network_routing.SiteWeightOverride>;

  constructor(props: $Shape<GetSiteEdgeWeightOverridesResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge_weight_overrides = [];
    if (props.hasOwnProperty('edge_weight_overrides')) {
      const v = props.edge_weight_overrides;
      if (!Array.isArray(v)) {
        throw new Error('repeated field edge_weight_overrides should be array');
      }
      this.edge_weight_overrides = v.map(function(v) {
        return new cwn_network_routing.EdgeWeightOverride(v);
      });
    }

    this.site_weight_overrides = [];
    if (props.hasOwnProperty('site_weight_overrides')) {
      const v = props.site_weight_overrides;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_weight_overrides should be array');
      }
      this.site_weight_overrides = v.map(function(v) {
        return new cwn_network_routing.SiteWeightOverride(v);
      });
    }
  }
}
export {GetSiteEdgeWeightOverridesResponse};

class GetLatestSiteRadioStatusRequest {
  site_id: string;

  constructor(props: $Shape<GetLatestSiteRadioStatusRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetLatestSiteRadioStatusRequest};

class GetLatestSiteRadioStatusResponse {
  radio_statuses_by_srl_id: {[key: string]: ?cwn_radios.Status};

  constructor(props: $Shape<GetLatestSiteRadioStatusResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.radio_statuses_by_srl_id = {};
    if (props.hasOwnProperty('radio_statuses_by_srl_id')) {
      const v = props.radio_statuses_by_srl_id;
      for (const key: any of Object.keys(v)) {
        this.radio_statuses_by_srl_id[key] = new cwn_radios.Status(
          (v[key]: any),
        );
      }
    }
  }
}
export {GetLatestSiteRadioStatusResponse};

class ListSiteRadioLinksForRadioGroupBatchRequest {
  radio_group_ids: Array<string>;

  constructor(
    props: $Shape<ListSiteRadioLinksForRadioGroupBatchRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.radio_group_ids = [];
    if (props.hasOwnProperty('radio_group_ids')) {
      const v = props.radio_group_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_group_ids should be array');
      }
      this.radio_group_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListSiteRadioLinksForRadioGroupBatchRequest};

class ListSiteRadioLinksForRadioGroupBatchReply_Csrs {
  customer_subscription_results: Array<
    cwn_customers.CustomerSubscriptionResult,
  >;

  constructor(
    props: $Shape<ListSiteRadioLinksForRadioGroupBatchReply_Csrs> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.customer_subscription_results = [];
    if (props.hasOwnProperty('customer_subscription_results')) {
      const v = props.customer_subscription_results;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field customer_subscription_results should be array',
        );
      }
      this.customer_subscription_results = v.map(function(v) {
        return new cwn_customers.CustomerSubscriptionResult(v);
      });
    }
  }
}
export {ListSiteRadioLinksForRadioGroupBatchReply_Csrs};
class ListSiteRadioLinksForRadioGroupBatchReply_Result {
  radio_group_id: string;
  site_radio_links: Array<cwn_site.SiteRadioLink>;

  constructor(
    props: $Shape<ListSiteRadioLinksForRadioGroupBatchReply_Result> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new cwn_site.SiteRadioLink(v);
      });
    }
  }
}
export {ListSiteRadioLinksForRadioGroupBatchReply_Result};
class ListSiteRadioLinksForRadioGroupBatchReply {
  results: Array<ListSiteRadioLinksForRadioGroupBatchReply_Result>;
  csrs_by_srl_id: {
    [key: string]: ?ListSiteRadioLinksForRadioGroupBatchReply_Csrs,
  };

  constructor(
    props: $Shape<ListSiteRadioLinksForRadioGroupBatchReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new ListSiteRadioLinksForRadioGroupBatchReply_Result(v);
      });
    }

    this.csrs_by_srl_id = {};
    if (props.hasOwnProperty('csrs_by_srl_id')) {
      const v = props.csrs_by_srl_id;
      for (const key: any of Object.keys(v)) {
        this.csrs_by_srl_id[
          key
        ] = new ListSiteRadioLinksForRadioGroupBatchReply_Csrs((v[key]: any));
      }
    }
  }
}
export {ListSiteRadioLinksForRadioGroupBatchReply};

class AssignSiteDeviceRequest {
  site_id: string;
  device_id: string;

  constructor(props: $Shape<AssignSiteDeviceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {AssignSiteDeviceRequest};

class AssignSiteDeviceReply {
  constructor(props: $Shape<AssignSiteDeviceReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {AssignSiteDeviceReply};

class AddSiteSubscriptionRequest {
  site_id: string;
  subscription_id: string;

  constructor(props: $Shape<AddSiteSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {AddSiteSubscriptionRequest};

class AddSiteSubscriptionReply {
  constructor(props: $Shape<AddSiteSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {AddSiteSubscriptionReply};

class RemoveSiteSubscriptionRequest {
  site_id: string;
  subscription_id: string;

  constructor(props: $Shape<RemoveSiteSubscriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {RemoveSiteSubscriptionRequest};

class RemoveSiteSubscriptionReply {
  constructor(props: $Shape<RemoveSiteSubscriptionReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {RemoveSiteSubscriptionReply};

class ListSitesRequest {
  lat: number;
  lng: number;
  radius: number;
  cursor: string;
  limit: number;

  constructor(props: $Shape<ListSitesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.radius = 0;
    if (props.hasOwnProperty('radius')) {
      const v = props.radius;
      this.radius = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {ListSitesRequest};

class ListSitesReply {
  sites: Array<cwn_site.Site>;
  cursor: string;

  constructor(props: $Shape<ListSitesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListSitesReply};

class ListSitesWithCustomersRequest {
  long: number;
  lat: number;
  radius: number;
  cursor: string;
  limit: number;

  constructor(props: $Shape<ListSitesWithCustomersRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.radius = 0;
    if (props.hasOwnProperty('radius')) {
      const v = props.radius;
      this.radius = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {ListSitesWithCustomersRequest};

class ListSitesWithCustomersReply_LC {
  lead: ?cwn_lead.Lead;
  customer: ?cwn_customers.Customer;

  constructor(props: $Shape<ListSitesWithCustomersReply_LC> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead = null;
    if (props.hasOwnProperty('lead')) {
      const v = props.lead;
      this.lead = v && new cwn_lead.Lead(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getLead(): cwn_lead.Lead {
    if (this.lead) {
      return this.lead;
    }
    return new cwn_lead.Lead();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {ListSitesWithCustomersReply_LC};
class ListSitesWithCustomersReply_Result {
  site: ?cwn_site.Site;
  customer_subscription_results: Array<
    cwn_customers.CustomerSubscriptionResult,
  >;
  lcs: Array<ListSitesWithCustomersReply_LC>;

  constructor(props: $Shape<ListSitesWithCustomersReply_Result> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.customer_subscription_results = [];
    if (props.hasOwnProperty('customer_subscription_results')) {
      const v = props.customer_subscription_results;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field customer_subscription_results should be array',
        );
      }
      this.customer_subscription_results = v.map(function(v) {
        return new cwn_customers.CustomerSubscriptionResult(v);
      });
    }

    this.lcs = [];
    if (props.hasOwnProperty('lcs')) {
      const v = props.lcs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field lcs should be array');
      }
      this.lcs = v.map(function(v) {
        return new ListSitesWithCustomersReply_LC(v);
      });
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {ListSitesWithCustomersReply_Result};
class ListSitesWithCustomersReply {
  results: Array<ListSitesWithCustomersReply_Result>;
  cursor: string;

  constructor(props: $Shape<ListSitesWithCustomersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new ListSitesWithCustomersReply_Result(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListSitesWithCustomersReply};

class ListLeadCustomerSubscriptionsForSiteRequest {
  site_id: string;

  constructor(
    props: $Shape<ListLeadCustomerSubscriptionsForSiteRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListLeadCustomerSubscriptionsForSiteRequest};

class ListLeadCustomerSubscriptionsForSiteReply {
  lcss: Array<cwn_lead.LeadCustomerSubscription>;

  constructor(
    props: $Shape<ListLeadCustomerSubscriptionsForSiteReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.lcss = [];
    if (props.hasOwnProperty('lcss')) {
      const v = props.lcss;
      if (!Array.isArray(v)) {
        throw new Error('repeated field lcss should be array');
      }
      this.lcss = v.map(function(v) {
        return new cwn_lead.LeadCustomerSubscription(v);
      });
    }
  }
}
export {ListLeadCustomerSubscriptionsForSiteReply};

class ListSiteRadiosRequest {
  site_id: string;

  constructor(props: $Shape<ListSiteRadiosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListSiteRadiosRequest};

class ListSiteRadiosReply_RadioSummary {
  mac_address: string;
  radio_ip: ?cwn_network.IP;
  interface_name: string;
  interface_ip: ?cwn_network.IP;
  radio_group_id: string;
  radio_group_ssid: string;
  mode: cwn_radios.Mode;
  channel: ?cwn_radios.Channel;
  heading: string;
  discovered: boolean;
  configured: boolean;
  site_radio_link_id: string;
  neighbors: Array<cwn_radios.Neighbor>;
  warnings: Array<string>;
  asset_model: cwn_asset.Model;

  constructor(props: $Shape<ListSiteRadiosReply_RadioSummary> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.radio_ip = null;
    if (props.hasOwnProperty('radio_ip')) {
      const v = props.radio_ip;
      this.radio_ip = v && new cwn_network.IP(v);
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.interface_ip = null;
    if (props.hasOwnProperty('interface_ip')) {
      const v = props.interface_ip;
      this.interface_ip = v && new cwn_network.IP(v);
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.radio_group_ssid = '';
    if (props.hasOwnProperty('radio_group_ssid')) {
      const v = props.radio_group_ssid;
      this.radio_group_ssid = v;
    }

    this.mode = cwn_radios.ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.channel = null;
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v && new cwn_radios.Channel(v);
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }

    this.discovered = false;
    if (props.hasOwnProperty('discovered')) {
      const v = props.discovered;
      this.discovered = !!v;
    }

    this.configured = false;
    if (props.hasOwnProperty('configured')) {
      const v = props.configured;
      this.configured = !!v;
    }

    this.site_radio_link_id = '0';
    if (props.hasOwnProperty('site_radio_link_id')) {
      const v = props.site_radio_link_id;
      this.site_radio_link_id = v;
    }

    this.neighbors = [];
    if (props.hasOwnProperty('neighbors')) {
      const v = props.neighbors;
      if (!Array.isArray(v)) {
        throw new Error('repeated field neighbors should be array');
      }
      this.neighbors = v.map(function(v) {
        return new cwn_radios.Neighbor(v);
      });
    }

    this.warnings = [];
    if (props.hasOwnProperty('warnings')) {
      const v = props.warnings;
      if (!Array.isArray(v)) {
        throw new Error('repeated field warnings should be array');
      }
      this.warnings = v.map(function(v) {
        return v;
      });
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }
  }

  getRadioIp(): cwn_network.IP {
    if (this.radio_ip) {
      return this.radio_ip;
    }
    return new cwn_network.IP();
  }

  getInterfaceIp(): cwn_network.IP {
    if (this.interface_ip) {
      return this.interface_ip;
    }
    return new cwn_network.IP();
  }

  getChannel(): cwn_radios.Channel {
    if (this.channel) {
      return this.channel;
    }
    return new cwn_radios.Channel();
  }
}
export {ListSiteRadiosReply_RadioSummary};
class ListSiteRadiosReply {
  site_radio_links: Array<cwn_site.SiteRadioLink>;
  radio_summaries: Array<ListSiteRadiosReply_RadioSummary>;

  constructor(props: $Shape<ListSiteRadiosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_links = [];
    if (props.hasOwnProperty('site_radio_links')) {
      const v = props.site_radio_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_radio_links should be array');
      }
      this.site_radio_links = v.map(function(v) {
        return new cwn_site.SiteRadioLink(v);
      });
    }

    this.radio_summaries = [];
    if (props.hasOwnProperty('radio_summaries')) {
      const v = props.radio_summaries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_summaries should be array');
      }
      this.radio_summaries = v.map(function(v) {
        return new ListSiteRadiosReply_RadioSummary(v);
      });
    }
  }
}
export {ListSiteRadiosReply};

class ListSiteBridgesRequest {
  site_id: string;

  constructor(props: $Shape<ListSiteBridgesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListSiteBridgesRequest};

class ListSiteBridgesReply {
  bridges: Array<cwn_bridges.Status>;

  constructor(props: $Shape<ListSiteBridgesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.bridges = [];
    if (props.hasOwnProperty('bridges')) {
      const v = props.bridges;
      if (!Array.isArray(v)) {
        throw new Error('repeated field bridges should be array');
      }
      this.bridges = v.map(function(v) {
        return new cwn_bridges.Status(v);
      });
    }
  }
}
export {ListSiteBridgesReply};

class ListSiteMocasRequest {
  site_id: string;

  constructor(props: $Shape<ListSiteMocasRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListSiteMocasRequest};

class ListSiteMocasReply_MocaSummary {
  modems: Array<cwn_moca.Modem>;
  subscription: ?cwn_customers.Subscription;
  vlan: ?cwn_network.Vlan;
  bridge: ?cwn_bridges.Status;
  device_interface_name: string;
  customer: ?cwn_customers.Customer;

  constructor(props: $Shape<ListSiteMocasReply_MocaSummary> = {}): void {
    if (!props) {
      props = {};
    }

    this.modems = [];
    if (props.hasOwnProperty('modems')) {
      const v = props.modems;
      if (!Array.isArray(v)) {
        throw new Error('repeated field modems should be array');
      }
      this.modems = v.map(function(v) {
        return new cwn_moca.Modem(v);
      });
    }

    this.subscription = null;
    if (props.hasOwnProperty('subscription')) {
      const v = props.subscription;
      this.subscription = v && new cwn_customers.Subscription(v);
    }

    this.vlan = null;
    if (props.hasOwnProperty('vlan')) {
      const v = props.vlan;
      this.vlan = v && new cwn_network.Vlan(v);
    }

    this.bridge = null;
    if (props.hasOwnProperty('bridge')) {
      const v = props.bridge;
      this.bridge = v && new cwn_bridges.Status(v);
    }

    this.device_interface_name = '';
    if (props.hasOwnProperty('device_interface_name')) {
      const v = props.device_interface_name;
      this.device_interface_name = v;
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getSubscription(): cwn_customers.Subscription {
    if (this.subscription) {
      return this.subscription;
    }
    return new cwn_customers.Subscription();
  }

  getVlan(): cwn_network.Vlan {
    if (this.vlan) {
      return this.vlan;
    }
    return new cwn_network.Vlan();
  }

  getBridge(): cwn_bridges.Status {
    if (this.bridge) {
      return this.bridge;
    }
    return new cwn_bridges.Status();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {ListSiteMocasReply_MocaSummary};
class ListSiteMocasReply {
  moca_summaries: Array<ListSiteMocasReply_MocaSummary>;

  constructor(props: $Shape<ListSiteMocasReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.moca_summaries = [];
    if (props.hasOwnProperty('moca_summaries')) {
      const v = props.moca_summaries;
      if (!Array.isArray(v)) {
        throw new Error('repeated field moca_summaries should be array');
      }
      this.moca_summaries = v.map(function(v) {
        return new ListSiteMocasReply_MocaSummary(v);
      });
    }
  }
}
export {ListSiteMocasReply};

class PutSiteRadioLinkRequest {
  site_radio_link: ?cwn_site.SiteRadioLink;

  constructor(props: $Shape<PutSiteRadioLinkRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_link = null;
    if (props.hasOwnProperty('site_radio_link')) {
      const v = props.site_radio_link;
      this.site_radio_link = v && new cwn_site.SiteRadioLink(v);
    }
  }

  getSiteRadioLink(): cwn_site.SiteRadioLink {
    if (this.site_radio_link) {
      return this.site_radio_link;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {PutSiteRadioLinkRequest};

class PutSiteRadioLinkReply {
  site_radio_link: ?cwn_site.SiteRadioLink;

  constructor(props: $Shape<PutSiteRadioLinkReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_link = null;
    if (props.hasOwnProperty('site_radio_link')) {
      const v = props.site_radio_link;
      this.site_radio_link = v && new cwn_site.SiteRadioLink(v);
    }
  }

  getSiteRadioLink(): cwn_site.SiteRadioLink {
    if (this.site_radio_link) {
      return this.site_radio_link;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {PutSiteRadioLinkReply};

class DeleteSiteRadioLinkRequest {
  site_radio_link_id: string;

  constructor(props: $Shape<DeleteSiteRadioLinkRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_link_id = '0';
    if (props.hasOwnProperty('site_radio_link_id')) {
      const v = props.site_radio_link_id;
      this.site_radio_link_id = v;
    }
  }
}
export {DeleteSiteRadioLinkRequest};

class DeleteSiteRadioLinkReply {
  constructor(props: $Shape<DeleteSiteRadioLinkReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteSiteRadioLinkReply};

class GetSiteRadioLinkStatusForMacRequest {
  mac_address: string;

  constructor(props: $Shape<GetSiteRadioLinkStatusForMacRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }
  }
}
export {GetSiteRadioLinkStatusForMacRequest};

class GetSiteRadioLinkStatusForMacReply {
  status: cwn_health.SiteRadioLinkStatus;

  constructor(props: $Shape<GetSiteRadioLinkStatusForMacReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = cwn_health.SiteRadioLinkStatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {GetSiteRadioLinkStatusForMacReply};

class SetSiteDetailsRequest {
  site_id: string;
  address: ?cwn_customers.Address;
  building_type: cwn_site.Site_BuildingType;
  number_of_units: cwn_site.Site_NumberOfUnits;
  area_id: string;
  node_type: cwn_site.Site_NodeType;

  constructor(props: $Shape<SetSiteDetailsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.building_type = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.number_of_units = cwn_site.Site_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('number_of_units')) {
      const v = props.number_of_units;
      this.number_of_units = v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.node_type = cwn_site.Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {SetSiteDetailsRequest};

class SetSiteDetailsReply {
  constructor(props: $Shape<SetSiteDetailsReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SetSiteDetailsReply};

class SearchSitesRequest {
  query: string;

  constructor(props: $Shape<SearchSitesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = '';
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v;
    }
  }
}
export {SearchSitesRequest};

class SearchSitesReply {
  sites: Array<cwn_site.Site>;

  constructor(props: $Shape<SearchSitesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.sites = [];
    if (props.hasOwnProperty('sites')) {
      const v = props.sites;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sites should be array');
      }
      this.sites = v.map(function(v) {
        return new cwn_site.Site(v);
      });
    }
  }
}
export {SearchSitesReply};

class SearchDevicesRequest {
  query: string;

  constructor(props: $Shape<SearchDevicesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = '';
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v;
    }
  }
}
export {SearchDevicesRequest};

class SearchDevicesReply {
  devices: Array<cwn_devices.Device>;

  constructor(props: $Shape<SearchDevicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.devices = [];
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field devices should be array');
      }
      this.devices = v.map(function(v) {
        return new cwn_devices.Device(v);
      });
    }
  }
}
export {SearchDevicesReply};

class CreateMountRequest {
  site_id: string;
  lat: number;
  long: number;
  altitude: number;
  name: string;
  description: string;
  type: cwn_geo.Mount_Type;
  height: number;
  installation_state: cwn_geo.Mount_InstallationState;

  constructor(props: $Shape<CreateMountRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.altitude = 0;
    if (props.hasOwnProperty('altitude')) {
      const v = props.altitude;
      this.altitude = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.type = cwn_geo.Mount_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.height = 0;
    if (props.hasOwnProperty('height')) {
      const v = props.height;
      this.height = v;
    }

    this.installation_state = cwn_geo.Mount_InstallationStateValue(0);
    if (props.hasOwnProperty('installation_state')) {
      const v = props.installation_state;
      this.installation_state = v;
    }
  }
}
export {CreateMountRequest};

class CreateMountReply {
  mount: ?cwn_geo.Mount;

  constructor(props: $Shape<CreateMountReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_geo.Mount(v);
    }
  }

  getMount(): cwn_geo.Mount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_geo.Mount();
  }
}
export {CreateMountReply};

class ListMountsForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListMountsForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListMountsForSiteRequest};

class ListMountsForSiteReply {
  mount: Array<cwn_geo.Mount>;

  constructor(props: $Shape<ListMountsForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = [];
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mount should be array');
      }
      this.mount = v.map(function(v) {
        return new cwn_geo.Mount(v);
      });
    }
  }
}
export {ListMountsForSiteReply};

class UpdateMountRequest {
  mount: ?cwn_geo.Mount;
  name: string;
  lat: number;
  long: number;
  altitude: number;
  description: string;
  type: cwn_geo.Mount_Type;
  height: number;
  installation_state: cwn_geo.Mount_InstallationState;

  constructor(props: $Shape<UpdateMountRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_geo.Mount(v);
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.long = 0;
    if (props.hasOwnProperty('long')) {
      const v = props.long;
      this.long = v;
    }

    this.altitude = 0;
    if (props.hasOwnProperty('altitude')) {
      const v = props.altitude;
      this.altitude = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.type = cwn_geo.Mount_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.height = 0;
    if (props.hasOwnProperty('height')) {
      const v = props.height;
      this.height = v;
    }

    this.installation_state = cwn_geo.Mount_InstallationStateValue(0);
    if (props.hasOwnProperty('installation_state')) {
      const v = props.installation_state;
      this.installation_state = v;
    }
  }

  getMount(): cwn_geo.Mount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_geo.Mount();
  }
}
export {UpdateMountRequest};

class UpdateMountLocationRequest {
  mount_id: string;
  lat: number;
  lng: number;

  constructor(props: $Shape<UpdateMountLocationRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }
  }
}
export {UpdateMountLocationRequest};

class UpdateMountReply {
  mount: ?cwn_geo.Mount;

  constructor(props: $Shape<UpdateMountReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_geo.Mount(v);
    }
  }

  getMount(): cwn_geo.Mount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_geo.Mount();
  }
}
export {UpdateMountReply};

class DeleteMountRequest {
  site_id: string;
  mount_id: string;

  constructor(props: $Shape<DeleteMountRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }
  }
}
export {DeleteMountRequest};

class DeleteMountReply {
  constructor(props: $Shape<DeleteMountReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteMountReply};

class GetMountsRequest {
  mount_ids: Array<string>;

  constructor(props: $Shape<GetMountsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_ids = [];
    if (props.hasOwnProperty('mount_ids')) {
      const v = props.mount_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mount_ids should be array');
      }
      this.mount_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetMountsRequest};

class GetMountsReply {
  mounts: Array<cwn_geo.Mount>;

  constructor(props: $Shape<GetMountsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.mounts = [];
    if (props.hasOwnProperty('mounts')) {
      const v = props.mounts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mounts should be array');
      }
      this.mounts = v.map(function(v) {
        return new cwn_geo.Mount(v);
      });
    }
  }
}
export {GetMountsReply};

class ListMapLinksRequest {
  constructor(props: $Shape<ListMapLinksRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListMapLinksRequest};

class ListMapLinksReply_MapLink {
  site_radio_link_a: ?cwn_site.SiteRadioLink;
  site_radio_link_b: ?cwn_site.SiteRadioLink;

  constructor(props: $Shape<ListMapLinksReply_MapLink> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_radio_link_a = null;
    if (props.hasOwnProperty('site_radio_link_a')) {
      const v = props.site_radio_link_a;
      this.site_radio_link_a = v && new cwn_site.SiteRadioLink(v);
    }

    this.site_radio_link_b = null;
    if (props.hasOwnProperty('site_radio_link_b')) {
      const v = props.site_radio_link_b;
      this.site_radio_link_b = v && new cwn_site.SiteRadioLink(v);
    }
  }

  getSiteRadioLinkA(): cwn_site.SiteRadioLink {
    if (this.site_radio_link_a) {
      return this.site_radio_link_a;
    }
    return new cwn_site.SiteRadioLink();
  }

  getSiteRadioLinkB(): cwn_site.SiteRadioLink {
    if (this.site_radio_link_b) {
      return this.site_radio_link_b;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {ListMapLinksReply_MapLink};
class ListMapLinksReply_RouteLinks {
  links: Array<ListMapLinksReply_MapLink>;

  constructor(props: $Shape<ListMapLinksReply_RouteLinks> = {}): void {
    if (!props) {
      props = {};
    }

    this.links = [];
    if (props.hasOwnProperty('links')) {
      const v = props.links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field links should be array');
      }
      this.links = v.map(function(v) {
        return new ListMapLinksReply_MapLink(v);
      });
    }
  }
}
export {ListMapLinksReply_RouteLinks};
class ListMapLinksReply {
  map_links: Array<ListMapLinksReply_MapLink>;
  route_links_by_site_id: {[key: string]: ?ListMapLinksReply_RouteLinks};

  constructor(props: $Shape<ListMapLinksReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.map_links = [];
    if (props.hasOwnProperty('map_links')) {
      const v = props.map_links;
      if (!Array.isArray(v)) {
        throw new Error('repeated field map_links should be array');
      }
      this.map_links = v.map(function(v) {
        return new ListMapLinksReply_MapLink(v);
      });
    }

    this.route_links_by_site_id = {};
    if (props.hasOwnProperty('route_links_by_site_id')) {
      const v = props.route_links_by_site_id;
      for (const key: any of Object.keys(v)) {
        this.route_links_by_site_id[key] = new ListMapLinksReply_RouteLinks(
          (v[key]: any),
        );
      }
    }
  }
}
export {ListMapLinksReply};

class ListDelinquentAccountsRequest {
  constructor(props: $Shape<ListDelinquentAccountsRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {ListDelinquentAccountsRequest};

class ListDelinquentAccountsReply_Delinquent {
  subscription_id: string;
  billing_id: string;
  customer_name: string;
  phone_number: string;
  email: string;

  constructor(
    props: $Shape<ListDelinquentAccountsReply_Delinquent> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.billing_id = '0';
    if (props.hasOwnProperty('billing_id')) {
      const v = props.billing_id;
      this.billing_id = v;
    }

    this.customer_name = '';
    if (props.hasOwnProperty('customer_name')) {
      const v = props.customer_name;
      this.customer_name = v;
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {ListDelinquentAccountsReply_Delinquent};
class ListDelinquentAccountsReply {
  delinquents: Array<ListDelinquentAccountsReply_Delinquent>;

  constructor(props: $Shape<ListDelinquentAccountsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.delinquents = [];
    if (props.hasOwnProperty('delinquents')) {
      const v = props.delinquents;
      if (!Array.isArray(v)) {
        throw new Error('repeated field delinquents should be array');
      }
      this.delinquents = v.map(function(v) {
        return new ListDelinquentAccountsReply_Delinquent(v);
      });
    }
  }
}
export {ListDelinquentAccountsReply};

class PutEdgeWeightOverrideRequest {
  reason: string;
  seconds: string;
  site_a_id: string;
  site_b_id: string;
  weight: number;
  initiator: string;

  constructor(props: $Shape<PutEdgeWeightOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.reason = '';
    if (props.hasOwnProperty('reason')) {
      const v = props.reason;
      this.reason = v;
    }

    this.seconds = '0';
    if (props.hasOwnProperty('seconds')) {
      const v = props.seconds;
      this.seconds = v;
    }

    this.site_a_id = '';
    if (props.hasOwnProperty('site_a_id')) {
      const v = props.site_a_id;
      this.site_a_id = v;
    }

    this.site_b_id = '';
    if (props.hasOwnProperty('site_b_id')) {
      const v = props.site_b_id;
      this.site_b_id = v;
    }

    this.weight = 0;
    if (props.hasOwnProperty('weight')) {
      const v = props.weight;
      this.weight = v;
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }
  }
}
export {PutEdgeWeightOverrideRequest};

class PutEdgeWeightOverrideReply {
  constructor(props: $Shape<PutEdgeWeightOverrideReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutEdgeWeightOverrideReply};

class DeleteEdgeWeightOverrideRequest {
  edge_weight_override: ?cwn_network_routing.EdgeWeightOverride;
  reason: string;

  constructor(props: $Shape<DeleteEdgeWeightOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge_weight_override = null;
    if (props.hasOwnProperty('edge_weight_override')) {
      const v = props.edge_weight_override;
      this.edge_weight_override =
        v && new cwn_network_routing.EdgeWeightOverride(v);
    }

    this.reason = '';
    if (props.hasOwnProperty('reason')) {
      const v = props.reason;
      this.reason = v;
    }
  }

  getEdgeWeightOverride(): cwn_network_routing.EdgeWeightOverride {
    if (this.edge_weight_override) {
      return this.edge_weight_override;
    }
    return new cwn_network_routing.EdgeWeightOverride();
  }
}
export {DeleteEdgeWeightOverrideRequest};

class DeleteEdgeWeightOverrideReply {
  constructor(props: $Shape<DeleteEdgeWeightOverrideReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DeleteEdgeWeightOverrideReply};

class ListEdgeWeightOverridesRequest {
  cursor: string;
  limit: number;

  constructor(props: $Shape<ListEdgeWeightOverridesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {ListEdgeWeightOverridesRequest};

class ListEdgeWeightOverridesReply {
  edge_weight_overrides: Array<cwn_network_routing.EdgeWeightOverride>;
  cursor: string;

  constructor(props: $Shape<ListEdgeWeightOverridesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge_weight_overrides = [];
    if (props.hasOwnProperty('edge_weight_overrides')) {
      const v = props.edge_weight_overrides;
      if (!Array.isArray(v)) {
        throw new Error('repeated field edge_weight_overrides should be array');
      }
      this.edge_weight_overrides = v.map(function(v) {
        return new cwn_network_routing.EdgeWeightOverride(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListEdgeWeightOverridesReply};

class GetSiteRadioLinkDowngradedRequest {
  mac_address: string;

  constructor(props: $Shape<GetSiteRadioLinkDowngradedRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }
  }
}
export {GetSiteRadioLinkDowngradedRequest};

class GetSiteRadioLinkDowngradedReply {
  downgraded: boolean;

  constructor(props: $Shape<GetSiteRadioLinkDowngradedReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.downgraded = false;
    if (props.hasOwnProperty('downgraded')) {
      const v = props.downgraded;
      this.downgraded = !!v;
    }
  }
}
export {GetSiteRadioLinkDowngradedReply};

class EnableMaintenanceModeRequest {
  site_a_id: string;
  maintenance_type: cwn_site_maintmode.MaintenanceType;
  durationS: string;
  site_b_ids: Array<string>;

  constructor(props: $Shape<EnableMaintenanceModeRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_a_id = '';
    if (props.hasOwnProperty('site_a_id')) {
      const v = props.site_a_id;
      this.site_a_id = v;
    }

    this.maintenance_type = cwn_site_maintmode.MaintenanceTypeValue(0);
    if (props.hasOwnProperty('maintenance_type')) {
      const v = props.maintenance_type;
      this.maintenance_type = v;
    }

    this.durationS = '0';
    if (props.hasOwnProperty('durationS')) {
      const v = props.durationS;
      this.durationS = v;
    }

    this.site_b_ids = [];
    if (props.hasOwnProperty('site_b_ids')) {
      const v = props.site_b_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_b_ids should be array');
      }
      this.site_b_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {EnableMaintenanceModeRequest};

class EnableMaintenanceModeReply {
  constructor(props: $Shape<EnableMaintenanceModeReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {EnableMaintenanceModeReply};

class DisableMaintenanceModeRequest {
  site_a_id: string;

  constructor(props: $Shape<DisableMaintenanceModeRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_a_id = '';
    if (props.hasOwnProperty('site_a_id')) {
      const v = props.site_a_id;
      this.site_a_id = v;
    }
  }
}
export {DisableMaintenanceModeRequest};

class DisableMaintenanceModeReply {
  constructor(props: $Shape<DisableMaintenanceModeReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {DisableMaintenanceModeReply};

class GetMaintenanceModeRequest {
  site_id: string;

  constructor(props: $Shape<GetMaintenanceModeRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetMaintenanceModeRequest};

class GetMaintenanceModeReply {
  maintenance_mode: ?cwn_site_maintmode.MaintenanceMode;

  constructor(props: $Shape<GetMaintenanceModeReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.maintenance_mode = null;
    if (props.hasOwnProperty('maintenance_mode')) {
      const v = props.maintenance_mode;
      this.maintenance_mode = v && new cwn_site_maintmode.MaintenanceMode(v);
    }
  }

  getMaintenanceMode(): cwn_site_maintmode.MaintenanceMode {
    if (this.maintenance_mode) {
      return this.maintenance_mode;
    }
    return new cwn_site_maintmode.MaintenanceMode();
  }
}
export {GetMaintenanceModeReply};

class GetSignedURLRequest {
  photo: ?cwn_photos.Photo;

  constructor(props: $Shape<GetSignedURLRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.photo = null;
    if (props.hasOwnProperty('photo')) {
      const v = props.photo;
      this.photo = v && new cwn_photos.Photo(v);
    }
  }

  getPhoto(): cwn_photos.Photo {
    if (this.photo) {
      return this.photo;
    }
    return new cwn_photos.Photo();
  }
}
export {GetSignedURLRequest};

class GetSignedURLReply {
  signed_url: string;
  public_url: string;
  photo_id: string;

  constructor(props: $Shape<GetSignedURLReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.signed_url = '';
    if (props.hasOwnProperty('signed_url')) {
      const v = props.signed_url;
      this.signed_url = v;
    }

    this.public_url = '';
    if (props.hasOwnProperty('public_url')) {
      const v = props.public_url;
      this.public_url = v;
    }

    this.photo_id = '';
    if (props.hasOwnProperty('photo_id')) {
      const v = props.photo_id;
      this.photo_id = v;
    }
  }
}
export {GetSignedURLReply};

class UpdatePhotosRequest {
  photos: Array<cwn_photos.Photo>;

  constructor(props: $Shape<UpdatePhotosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.photos = [];
    if (props.hasOwnProperty('photos')) {
      const v = props.photos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photos should be array');
      }
      this.photos = v.map(function(v) {
        return new cwn_photos.Photo(v);
      });
    }
  }
}
export {UpdatePhotosRequest};

class UpdatePhotosReply {
  photos: Array<cwn_photos.Photo>;

  constructor(props: $Shape<UpdatePhotosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.photos = [];
    if (props.hasOwnProperty('photos')) {
      const v = props.photos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photos should be array');
      }
      this.photos = v.map(function(v) {
        return new cwn_photos.Photo(v);
      });
    }
  }
}
export {UpdatePhotosReply};

class DeletePhotoRequest {
  photo_id: string;

  constructor(props: $Shape<DeletePhotoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.photo_id = '';
    if (props.hasOwnProperty('photo_id')) {
      const v = props.photo_id;
      this.photo_id = v;
    }
  }
}
export {DeletePhotoRequest};

class GetSitePhotosRequest {
  site_id: string;

  constructor(props: $Shape<GetSitePhotosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSitePhotosRequest};

class GetSitePhotosReply {
  photos: Array<cwn_photos.Photo>;

  constructor(props: $Shape<GetSitePhotosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.photos = [];
    if (props.hasOwnProperty('photos')) {
      const v = props.photos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field photos should be array');
      }
      this.photos = v.map(function(v) {
        return new cwn_photos.Photo(v);
      });
    }
  }
}
export {GetSitePhotosReply};

class GetSRLForSitePairRequest {
  site_a_id: string;
  site_b_id: string;

  constructor(props: $Shape<GetSRLForSitePairRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_a_id = '';
    if (props.hasOwnProperty('site_a_id')) {
      const v = props.site_a_id;
      this.site_a_id = v;
    }

    this.site_b_id = '';
    if (props.hasOwnProperty('site_b_id')) {
      const v = props.site_b_id;
      this.site_b_id = v;
    }
  }
}
export {GetSRLForSitePairRequest};

class GetSRLForSitePairReply {
  srl: ?cwn_site.SiteRadioLink;

  constructor(props: $Shape<GetSRLForSitePairReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl = null;
    if (props.hasOwnProperty('srl')) {
      const v = props.srl;
      this.srl = v && new cwn_site.SiteRadioLink(v);
    }
  }

  getSrl(): cwn_site.SiteRadioLink {
    if (this.srl) {
      return this.srl;
    }
    return new cwn_site.SiteRadioLink();
  }
}
export {GetSRLForSitePairReply};

class ListNextAuditRecordBatchRequest {
  site_id: string;
  cursor: string;
  batch_size: string;
  filtered_action: cwn_log.Action;

  constructor(props: $Shape<ListNextAuditRecordBatchRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.batch_size = '0';
    if (props.hasOwnProperty('batch_size')) {
      const v = props.batch_size;
      this.batch_size = v;
    }

    this.filtered_action = cwn_log.ActionValue(0);
    if (props.hasOwnProperty('filtered_action')) {
      const v = props.filtered_action;
      this.filtered_action = v;
    }
  }
}
export {ListNextAuditRecordBatchRequest};

class ListNextAuditRecordBatchReply {
  records: Array<cwn_audit.RecordDisplay>;
  cursor: string;

  constructor(props: $Shape<ListNextAuditRecordBatchReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.records = [];
    if (props.hasOwnProperty('records')) {
      const v = props.records;
      if (!Array.isArray(v)) {
        throw new Error('repeated field records should be array');
      }
      this.records = v.map(function(v) {
        return new cwn_audit.RecordDisplay(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListNextAuditRecordBatchReply};

class AddAuditNoteRequest {
  site_id: string;
  note: string;

  constructor(props: $Shape<AddAuditNoteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.note = '';
    if (props.hasOwnProperty('note')) {
      const v = props.note;
      this.note = v;
    }
  }
}
export {AddAuditNoteRequest};

class AddAuditNoteReply {
  constructor(props: $Shape<AddAuditNoteReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {AddAuditNoteReply};

class GetSiteEquipmentRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteEquipmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteEquipmentRequest};

class GetSiteEquipmentReply {
  equipment: ?cwn_site_hw.Equipment;

  constructor(props: $Shape<GetSiteEquipmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.equipment = null;
    if (props.hasOwnProperty('equipment')) {
      const v = props.equipment;
      this.equipment = v && new cwn_site_hw.Equipment(v);
    }
  }

  getEquipment(): cwn_site_hw.Equipment {
    if (this.equipment) {
      return this.equipment;
    }
    return new cwn_site_hw.Equipment();
  }
}
export {GetSiteEquipmentReply};

class PutSiteEquipmentRequest {
  equipment: ?cwn_site_hw.Equipment;

  constructor(props: $Shape<PutSiteEquipmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.equipment = null;
    if (props.hasOwnProperty('equipment')) {
      const v = props.equipment;
      this.equipment = v && new cwn_site_hw.Equipment(v);
    }
  }

  getEquipment(): cwn_site_hw.Equipment {
    if (this.equipment) {
      return this.equipment;
    }
    return new cwn_site_hw.Equipment();
  }
}
export {PutSiteEquipmentRequest};

class PutSiteEquipmentReply {
  constructor(props: $Shape<PutSiteEquipmentReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {PutSiteEquipmentReply};

class GetSiteCustomerNameRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteCustomerNameRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteCustomerNameRequest};

class GetSiteCustomerNameReply {
  name: string;

  constructor(props: $Shape<GetSiteCustomerNameReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }
  }
}
export {GetSiteCustomerNameReply};

class GetSiteHealthRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteHealthRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteHealthRequest};

class GetSiteHealthReply {
  site_health: ?cwn_health.SiteHealth;
  site_health_histories: Array<cwn_health.SiteHealthHistory>;

  constructor(props: $Shape<GetSiteHealthReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_health = null;
    if (props.hasOwnProperty('site_health')) {
      const v = props.site_health;
      this.site_health = v && new cwn_health.SiteHealth(v);
    }

    this.site_health_histories = [];
    if (props.hasOwnProperty('site_health_histories')) {
      const v = props.site_health_histories;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_health_histories should be array');
      }
      this.site_health_histories = v.map(function(v) {
        return new cwn_health.SiteHealthHistory(v);
      });
    }
  }

  getSiteHealth(): cwn_health.SiteHealth {
    if (this.site_health) {
      return this.site_health;
    }
    return new cwn_health.SiteHealth();
  }
}
export {GetSiteHealthReply};

class GetCurrentSiteHealthRequest {
  site_id: string;

  constructor(props: $Shape<GetCurrentSiteHealthRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetCurrentSiteHealthRequest};

class GetCurrentSiteHealthReply {
  site_health: ?cwn_health.SiteHealth;

  constructor(props: $Shape<GetCurrentSiteHealthReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_health = null;
    if (props.hasOwnProperty('site_health')) {
      const v = props.site_health;
      this.site_health = v && new cwn_health.SiteHealth(v);
    }
  }

  getSiteHealth(): cwn_health.SiteHealth {
    if (this.site_health) {
      return this.site_health;
    }
    return new cwn_health.SiteHealth();
  }
}
export {GetCurrentSiteHealthReply};

class ListResolvedDiagnosesRequest {
  site_id: string;
  cursor: string;
  limit: number;

  constructor(props: $Shape<ListResolvedDiagnosesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {ListResolvedDiagnosesRequest};

class ListResolvedDiagnosesReply {
  diagnoses: Array<cwn_health.Diagnosis>;
  cursor: string;

  constructor(props: $Shape<ListResolvedDiagnosesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.diagnoses = [];
    if (props.hasOwnProperty('diagnoses')) {
      const v = props.diagnoses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field diagnoses should be array');
      }
      this.diagnoses = v.map(function(v) {
        return new cwn_health.Diagnosis(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListResolvedDiagnosesReply};

class ListOngoingDiagnosesRequest {
  site_id: string;

  constructor(props: $Shape<ListOngoingDiagnosesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListOngoingDiagnosesRequest};

class ListOngoingDiagnosesReply {
  diagnoses: Array<cwn_health.Diagnosis>;

  constructor(props: $Shape<ListOngoingDiagnosesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.diagnoses = [];
    if (props.hasOwnProperty('diagnoses')) {
      const v = props.diagnoses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field diagnoses should be array');
      }
      this.diagnoses = v.map(function(v) {
        return new cwn_health.Diagnosis(v);
      });
    }
  }
}
export {ListOngoingDiagnosesReply};

class GetPrescriptionsRequest {
  site_id: string;

  constructor(props: $Shape<GetPrescriptionsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetPrescriptionsRequest};

class GetPrescriptionsReply {
  prescriptions: Array<cwn_health.Prescription>;

  constructor(props: $Shape<GetPrescriptionsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.prescriptions = [];
    if (props.hasOwnProperty('prescriptions')) {
      const v = props.prescriptions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field prescriptions should be array');
      }
      this.prescriptions = v.map(function(v) {
        return new cwn_health.Prescription(v);
      });
    }
  }
}
export {GetPrescriptionsReply};

class ListSiteHealthRequest {
  sla: boolean;

  constructor(props: $Shape<ListSiteHealthRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.sla = false;
    if (props.hasOwnProperty('sla')) {
      const v = props.sla;
      this.sla = !!v;
    }
  }
}
export {ListSiteHealthRequest};

class ListSiteHealthReply {
  site_healths: Array<cwn_health.SiteHealth>;

  constructor(props: $Shape<ListSiteHealthReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_healths = [];
    if (props.hasOwnProperty('site_healths')) {
      const v = props.site_healths;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_healths should be array');
      }
      this.site_healths = v.map(function(v) {
        return new cwn_health.SiteHealth(v);
      });
    }
  }
}
export {ListSiteHealthReply};

class SetSiteStatusRequest {
  site_id: string;
  status_v2: ?cwn_site.StatusV2;

  constructor(props: $Shape<SetSiteStatusRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.status_v2 = null;
    if (props.hasOwnProperty('status_v2')) {
      const v = props.status_v2;
      this.status_v2 = v && new cwn_site.StatusV2(v);
    }
  }

  getStatusV2(): cwn_site.StatusV2 {
    if (this.status_v2) {
      return this.status_v2;
    }
    return new cwn_site.StatusV2();
  }
}
export {SetSiteStatusRequest};

class SetSiteStatusReply {
  constructor(props: $Shape<SetSiteStatusReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SetSiteStatusReply};

class GetSiteBuildingDescriptionRequest {
  site_id: string;

  constructor(props: $Shape<GetSiteBuildingDescriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetSiteBuildingDescriptionRequest};

class GetSiteBuildingDescriptionReply {
  description: ?cwn_site_details.BuildingDescription;

  constructor(props: $Shape<GetSiteBuildingDescriptionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.description = null;
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v && new cwn_site_details.BuildingDescription(v);
    }
  }

  getDescription(): cwn_site_details.BuildingDescription {
    if (this.description) {
      return this.description;
    }
    return new cwn_site_details.BuildingDescription();
  }
}
export {GetSiteBuildingDescriptionReply};

class SetSiteBuildingDescriptionRequest {
  description: ?cwn_site_details.BuildingDescription;

  constructor(props: $Shape<SetSiteBuildingDescriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.description = null;
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v && new cwn_site_details.BuildingDescription(v);
    }
  }

  getDescription(): cwn_site_details.BuildingDescription {
    if (this.description) {
      return this.description;
    }
    return new cwn_site_details.BuildingDescription();
  }
}
export {SetSiteBuildingDescriptionRequest};

class SetSiteBuildingDescriptionReply {
  constructor(props: $Shape<SetSiteBuildingDescriptionReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SetSiteBuildingDescriptionReply};

class SetSitePartnerInfoRequest {
  site_id: string;
  partner_info: ?cwn_site.Site_PartnerInfo;

  constructor(props: $Shape<SetSitePartnerInfoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.partner_info = null;
    if (props.hasOwnProperty('partner_info')) {
      const v = props.partner_info;
      this.partner_info = v && new cwn_site.Site_PartnerInfo(v);
    }
  }

  getPartnerInfo(): cwn_site.Site_PartnerInfo {
    if (this.partner_info) {
      return this.partner_info;
    }
    return new cwn_site.Site_PartnerInfo();
  }
}
export {SetSitePartnerInfoRequest};

class SetSitePartnerInfoReply {
  constructor(props: $Shape<SetSitePartnerInfoReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SetSitePartnerInfoReply};

class ListLeadsRequest {
  cursor: string;

  constructor(props: $Shape<ListLeadsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListLeadsRequest};

class ListLeadsReply {
  leads: Array<cwn_lead.LeadCustomerSite>;
  cursor: string;
  done: boolean;

  constructor(props: $Shape<ListLeadsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.leads = [];
    if (props.hasOwnProperty('leads')) {
      const v = props.leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field leads should be array');
      }
      this.leads = v.map(function(v) {
        return new cwn_lead.LeadCustomerSite(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.done = false;
    if (props.hasOwnProperty('done')) {
      const v = props.done;
      this.done = !!v;
    }
  }
}
export {ListLeadsReply};

class GetLeadRequest {
  id: string;

  constructor(props: $Shape<GetLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {GetLeadRequest};

class GetLeadReply {
  lcs: ?cwn_lead.LeadCustomerSite;

  constructor(props: $Shape<GetLeadReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.lcs = null;
    if (props.hasOwnProperty('lcs')) {
      const v = props.lcs;
      this.lcs = v && new cwn_lead.LeadCustomerSite(v);
    }
  }

  getLcs(): cwn_lead.LeadCustomerSite {
    if (this.lcs) {
      return this.lcs;
    }
    return new cwn_lead.LeadCustomerSite();
  }
}
export {GetLeadReply};

class ListLeadsForCustomerRequest {
  customer_id: string;

  constructor(props: $Shape<ListLeadsForCustomerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }
  }
}
export {ListLeadsForCustomerRequest};

class ListLeadsForCustomerReply {
  leads: Array<cwn_lead.Lead>;

  constructor(props: $Shape<ListLeadsForCustomerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.leads = [];
    if (props.hasOwnProperty('leads')) {
      const v = props.leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field leads should be array');
      }
      this.leads = v.map(function(v) {
        return new cwn_lead.Lead(v);
      });
    }
  }
}
export {ListLeadsForCustomerReply};

class ListLeadsForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListLeadsForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListLeadsForSiteRequest};

class ListLeadsForSiteReply {
  leads: Array<cwn_lead.LeadCustomerSite>;

  constructor(props: $Shape<ListLeadsForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.leads = [];
    if (props.hasOwnProperty('leads')) {
      const v = props.leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field leads should be array');
      }
      this.leads = v.map(function(v) {
        return new cwn_lead.LeadCustomerSite(v);
      });
    }
  }
}
export {ListLeadsForSiteReply};

class SearchLeadsRequest {
  query: ?cwn_search.Query;

  constructor(props: $Shape<SearchLeadsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = null;
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v && new cwn_search.Query(v);
    }
  }

  getQuery(): cwn_search.Query {
    if (this.query) {
      return this.query;
    }
    return new cwn_search.Query();
  }
}
export {SearchLeadsRequest};

class SearchLeadsReply {
  results: Array<cwn_lead.LeadCustomerSite>;
  cursor: string;

  constructor(props: $Shape<SearchLeadsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new cwn_lead.LeadCustomerSite(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {SearchLeadsReply};

class SearchPartnerLeadsRequest {
  query: ?cwn_search.Query;

  constructor(props: $Shape<SearchPartnerLeadsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = null;
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v && new cwn_search.Query(v);
    }
  }

  getQuery(): cwn_search.Query {
    if (this.query) {
      return this.query;
    }
    return new cwn_search.Query();
  }
}
export {SearchPartnerLeadsRequest};

class SearchPartnerLeadsReply {
  results: Array<cwn_partner.PartnerLead>;
  cursor: string;

  constructor(props: $Shape<SearchPartnerLeadsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.results = [];
    if (props.hasOwnProperty('results')) {
      const v = props.results;
      if (!Array.isArray(v)) {
        throw new Error('repeated field results should be array');
      }
      this.results = v.map(function(v) {
        return new cwn_partner.PartnerLead(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {SearchPartnerLeadsReply};

class ListPartnerLeadsRequest {
  site_id: string;

  constructor(props: $Shape<ListPartnerLeadsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListPartnerLeadsRequest};

class ListPartnerLeadsReply {
  partner_leads: Array<cwn_partner.PartnerLead>;

  constructor(props: $Shape<ListPartnerLeadsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.partner_leads = [];
    if (props.hasOwnProperty('partner_leads')) {
      const v = props.partner_leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field partner_leads should be array');
      }
      this.partner_leads = v.map(function(v) {
        return new cwn_partner.PartnerLead(v);
      });
    }
  }
}
export {ListPartnerLeadsReply};

class UpdatePartnerLeadsRequest {
  partner_leads: Array<cwn_partner.PartnerLead>;

  constructor(props: $Shape<UpdatePartnerLeadsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.partner_leads = [];
    if (props.hasOwnProperty('partner_leads')) {
      const v = props.partner_leads;
      if (!Array.isArray(v)) {
        throw new Error('repeated field partner_leads should be array');
      }
      this.partner_leads = v.map(function(v) {
        return new cwn_partner.PartnerLead(v);
      });
    }
  }
}
export {UpdatePartnerLeadsRequest};

class UpdatePartnerLeadsReply {
  constructor(props: $Shape<UpdatePartnerLeadsReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {UpdatePartnerLeadsReply};

class UpdateLeadStateRequest {
  lead_id: string;
  state: cwn_lead.Lead_State;

  constructor(props: $Shape<UpdateLeadStateRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.state = cwn_lead.Lead_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }
  }
}
export {UpdateLeadStateRequest};

class UpdateLeadStateReply {
  constructor(props: $Shape<UpdateLeadStateReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {UpdateLeadStateReply};

class UpdateLeadAddressRequest {
  lead_id: string;
  address: ?cwn_customers.Address;

  constructor(props: $Shape<UpdateLeadAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {UpdateLeadAddressRequest};

class UpdateLeadAddressReply {
  constructor(props: $Shape<UpdateLeadAddressReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {UpdateLeadAddressReply};

class UpdateLeadPromoCodeRequest {
  lead_id: string;
  promo_code: string;

  constructor(props: $Shape<UpdateLeadPromoCodeRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.promo_code = '';
    if (props.hasOwnProperty('promo_code')) {
      const v = props.promo_code;
      this.promo_code = v;
    }
  }
}
export {UpdateLeadPromoCodeRequest};

class ActivateLeadRequest {
  lead_id: string;

  constructor(props: $Shape<ActivateLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {ActivateLeadRequest};

class SetLeadSiteRequest {
  lead_id: string;
  site_id: string;

  constructor(props: $Shape<SetLeadSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {SetLeadSiteRequest};

class SetLeadSiteReply {
  constructor(props: $Shape<SetLeadSiteReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SetLeadSiteReply};

class RemoveLeadSiteRequest {
  lead_id: string;
  site_id: string;

  constructor(props: $Shape<RemoveLeadSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {RemoveLeadSiteRequest};

class RemoveLeadSiteReply {
  constructor(props: $Shape<RemoveLeadSiteReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {RemoveLeadSiteReply};

class UninstallSiteReply {
  site: ?cwn_site.Site;

  constructor(props: $Shape<UninstallSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }
}
export {UninstallSiteReply};

class GetNetworkPlanRequest {
  site_id: string;

  constructor(props: $Shape<GetNetworkPlanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetNetworkPlanRequest};

class GetNetworkPlanReply {
  plan: string;

  constructor(props: $Shape<GetNetworkPlanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.plan = '';
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }
}
export {GetNetworkPlanReply};

class GenNetworkPlanTicketRequest {
  site_id: string;

  constructor(props: $Shape<GenNetworkPlanTicketRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GenNetworkPlanTicketRequest};

class GenNetworkPlanTicketReply {
  tickets: string;

  constructor(props: $Shape<GenNetworkPlanTicketReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.tickets = '';
    if (props.hasOwnProperty('tickets')) {
      const v = props.tickets;
      this.tickets = v;
    }
  }
}
export {GenNetworkPlanTicketReply};

class DispatchRadioAddRequest {
  initiator_id: string;
  radio_group_id: string;

  constructor(props: $Shape<DispatchRadioAddRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.initiator_id = '0';
    if (props.hasOwnProperty('initiator_id')) {
      const v = props.initiator_id;
      this.initiator_id = v;
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }
  }
}
export {DispatchRadioAddRequest};

class DispatchRadioAddReply {
  ticket_ids: Array<string>;

  constructor(props: $Shape<DispatchRadioAddReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.ticket_ids = [];
    if (props.hasOwnProperty('ticket_ids')) {
      const v = props.ticket_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ticket_ids should be array');
      }
      this.ticket_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {DispatchRadioAddReply};

class GetSMSRequest {
  limit: number;

  constructor(props: $Shape<GetSMSRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }
  }
}
export {GetSMSRequest};

class GetSMSReply {
  records: Array<cwn_sms.Record>;
  queue: Array<cwn_sms.Task>;

  constructor(props: $Shape<GetSMSReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.records = [];
    if (props.hasOwnProperty('records')) {
      const v = props.records;
      if (!Array.isArray(v)) {
        throw new Error('repeated field records should be array');
      }
      this.records = v.map(function(v) {
        return new cwn_sms.Record(v);
      });
    }

    this.queue = [];
    if (props.hasOwnProperty('queue')) {
      const v = props.queue;
      if (!Array.isArray(v)) {
        throw new Error('repeated field queue should be array');
      }
      this.queue = v.map(function(v) {
        return new cwn_sms.Task(v);
      });
    }
  }
}
export {GetSMSReply};

class SendReferralEmailRequest {
  subscription_id: string;

  constructor(props: $Shape<SendReferralEmailRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {SendReferralEmailRequest};

class SendReferralEmailForLeadRequest {
  lead_id: string;

  constructor(props: $Shape<SendReferralEmailForLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {SendReferralEmailForLeadRequest};

class SendReferralEmailReply {
  constructor(props: $Shape<SendReferralEmailReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {SendReferralEmailReply};

class EmptyMessage {
  constructor(props: $Shape<EmptyMessage> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {EmptyMessage};

class GetAppointmentRequest {
  lead_id: string;

  constructor(props: $Shape<GetAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {GetAppointmentRequest};

class GetAppointmentReply {
  app: ?Date;
  confirmed: boolean;

  constructor(props: $Shape<GetAppointmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.app = null;
    if (props.hasOwnProperty('app')) {
      const v = props.app;
      this.app = v && new Date(v);
    }

    this.confirmed = false;
    if (props.hasOwnProperty('confirmed')) {
      const v = props.confirmed;
      this.confirmed = !!v;
    }
  }
}
export {GetAppointmentReply};

class BookAppointmentRequest {
  lead_id: string;
  app: ?Date;

  constructor(props: $Shape<BookAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.app = null;
    if (props.hasOwnProperty('app')) {
      const v = props.app;
      this.app = v && new Date(v);
    }
  }
}
export {BookAppointmentRequest};

class CancelAppointmentRequest {
  lead_id: string;
  cancellation_reasons: Array<cwn_tech.FlexibleWorkOrder_ClosedReason>;
  cancellation_reason_other: string;
  closing_notes: string;

  constructor(props: $Shape<CancelAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.cancellation_reasons = [];
    if (props.hasOwnProperty('cancellation_reasons')) {
      const v = props.cancellation_reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field cancellation_reasons should be array');
      }
      this.cancellation_reasons = v.map(function(v) {
        return v;
      });
    }

    this.cancellation_reason_other = '';
    if (props.hasOwnProperty('cancellation_reason_other')) {
      const v = props.cancellation_reason_other;
      this.cancellation_reason_other = v;
    }

    this.closing_notes = '';
    if (props.hasOwnProperty('closing_notes')) {
      const v = props.closing_notes;
      this.closing_notes = v;
    }
  }
}
export {CancelAppointmentRequest};

class ListAppointmentsRequest {
  lead_id: string;

  constructor(props: $Shape<ListAppointmentsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {ListAppointmentsRequest};

class ListAppointmentsReply {
  appointments: Array<Date>;

  constructor(props: $Shape<ListAppointmentsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointments = [];
    if (props.hasOwnProperty('appointments')) {
      const v = props.appointments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field appointments should be array');
      }
      this.appointments = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {ListAppointmentsReply};

class ListAppointmentsForDayRequest {
  lead_id: string;
  day: ?Date;

  constructor(props: $Shape<ListAppointmentsForDayRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.day = null;
    if (props.hasOwnProperty('day')) {
      const v = props.day;
      this.day = v && new Date(v);
    }
  }
}
export {ListAppointmentsForDayRequest};

class ListAppointmentsForDayReply {
  appointments: Array<Date>;

  constructor(props: $Shape<ListAppointmentsForDayReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointments = [];
    if (props.hasOwnProperty('appointments')) {
      const v = props.appointments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field appointments should be array');
      }
      this.appointments = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {ListAppointmentsForDayReply};

class ListWorkOrderApptsForDayRequest {
  work_order_id: string;
  appointment_type: number;
  day: ?Date;

  constructor(props: $Shape<ListWorkOrderApptsForDayRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.appointment_type = 0;
    if (props.hasOwnProperty('appointment_type')) {
      const v = props.appointment_type;
      this.appointment_type = v;
    }

    this.day = null;
    if (props.hasOwnProperty('day')) {
      const v = props.day;
      this.day = v && new Date(v);
    }
  }
}
export {ListWorkOrderApptsForDayRequest};

class ListWorkOrderApptsForDayReply {
  appointments: Array<Date>;

  constructor(props: $Shape<ListWorkOrderApptsForDayReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointments = [];
    if (props.hasOwnProperty('appointments')) {
      const v = props.appointments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field appointments should be array');
      }
      this.appointments = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {ListWorkOrderApptsForDayReply};

class SetWorkOrderAppointmentRequest {
  work_order_id: string;
  appointment_type: number;
  slot: ?Date;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;

  constructor(props: $Shape<SetWorkOrderAppointmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.appointment_type = 0;
    if (props.hasOwnProperty('appointment_type')) {
      const v = props.appointment_type;
      this.appointment_type = v;
    }

    this.slot = null;
    if (props.hasOwnProperty('slot')) {
      const v = props.slot;
      this.slot = v && new Date(v);
    }

    this.first_name = '';
    if (props.hasOwnProperty('first_name')) {
      const v = props.first_name;
      this.first_name = v;
    }

    this.last_name = '';
    if (props.hasOwnProperty('last_name')) {
      const v = props.last_name;
      this.last_name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }
  }
}
export {SetWorkOrderAppointmentRequest};

class SetWorkOrderAppointmentReply {
  work_order: ?cwn_tech.FlexibleWorkOrder;

  constructor(props: $Shape<SetWorkOrderAppointmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }
}
export {SetWorkOrderAppointmentReply};

class ListWorkOrderAppointmentTypesReply {
  appointment_types: Array<cwn_acuity.AppointmentType>;

  constructor(props: $Shape<ListWorkOrderAppointmentTypesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointment_types = [];
    if (props.hasOwnProperty('appointment_types')) {
      const v = props.appointment_types;
      if (!Array.isArray(v)) {
        throw new Error('repeated field appointment_types should be array');
      }
      this.appointment_types = v.map(function(v) {
        return new cwn_acuity.AppointmentType(v);
      });
    }
  }
}
export {ListWorkOrderAppointmentTypesReply};

class ListPromoCodesReply {
  promo_codes: Array<cwn_billing.PromoCode>;

  constructor(props: $Shape<ListPromoCodesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.promo_codes = [];
    if (props.hasOwnProperty('promo_codes')) {
      const v = props.promo_codes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field promo_codes should be array');
      }
      this.promo_codes = v.map(function(v) {
        return new cwn_billing.PromoCode(v);
      });
    }
  }
}
export {ListPromoCodesReply};

class UpdatePromoCodeRequest {
  promo_code: ?cwn_billing.PromoCode;

  constructor(props: $Shape<UpdatePromoCodeRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.promo_code = null;
    if (props.hasOwnProperty('promo_code')) {
      const v = props.promo_code;
      this.promo_code = v && new cwn_billing.PromoCode(v);
    }
  }

  getPromoCode(): cwn_billing.PromoCode {
    if (this.promo_code) {
      return this.promo_code;
    }
    return new cwn_billing.PromoCode();
  }
}
export {UpdatePromoCodeRequest};

class QualifyOpenAddressRequest {
  id: string;

  constructor(props: $Shape<QualifyOpenAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {QualifyOpenAddressRequest};

class ListFlexibleWorkOrdersRequest {
  site_id: string;
  cursor: string;
  limit: string;
  only_mine: boolean;

  constructor(props: $Shape<ListFlexibleWorkOrdersRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }

    this.limit = '0';
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }

    this.only_mine = false;
    if (props.hasOwnProperty('only_mine')) {
      const v = props.only_mine;
      this.only_mine = !!v;
    }
  }
}
export {ListFlexibleWorkOrdersRequest};

class ListFlexibleWorkOrdersReply {
  work_orders: Array<cwn_tech.FlexibleWorkOrder>;
  cursor: string;

  constructor(props: $Shape<ListFlexibleWorkOrdersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_orders = [];
    if (props.hasOwnProperty('work_orders')) {
      const v = props.work_orders;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_orders should be array');
      }
      this.work_orders = v.map(function(v) {
        return new cwn_tech.FlexibleWorkOrder(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListFlexibleWorkOrdersReply};

class GetWorkOrdersRequest {
  work_order_id: Array<string>;

  constructor(props: $Shape<GetWorkOrdersRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = [];
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_order_id should be array');
      }
      this.work_order_id = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetWorkOrdersRequest};

class GetWorkOrdersReply {
  work_order: Array<cwn_tech.FlexibleWorkOrder>;

  constructor(props: $Shape<GetWorkOrdersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = [];
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_order should be array');
      }
      this.work_order = v.map(function(v) {
        return new cwn_tech.FlexibleWorkOrder(v);
      });
    }
  }
}
export {GetWorkOrdersReply};

class GetWorkOrderReply {
  work_order: ?cwn_tech.FlexibleWorkOrder;

  constructor(props: $Shape<GetWorkOrderReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }
}
export {GetWorkOrderReply};

class NewWorkOrderRequest {
  work_order: ?cwn_tech.FlexibleWorkOrder;

  constructor(props: $Shape<NewWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }
}
export {NewWorkOrderRequest};

class NewWorkOrderReply {
  work_order: ?cwn_tech.FlexibleWorkOrder;

  constructor(props: $Shape<NewWorkOrderReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }
}
export {NewWorkOrderReply};

class SearchWorkOrdersRequest {
  query: ?cwn_search.Query;
  count_visits_start: ?Date;

  constructor(props: $Shape<SearchWorkOrdersRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.query = null;
    if (props.hasOwnProperty('query')) {
      const v = props.query;
      this.query = v && new cwn_search.Query(v);
    }

    this.count_visits_start = null;
    if (props.hasOwnProperty('count_visits_start')) {
      const v = props.count_visits_start;
      this.count_visits_start = v && new Date(v);
    }
  }

  getQuery(): cwn_search.Query {
    if (this.query) {
      return this.query;
    }
    return new cwn_search.Query();
  }
}
export {SearchWorkOrdersRequest};

class SearchWorkOrdersReply_ViewModel {
  work_order: ?cwn_tech.FlexibleWorkOrder;
  site: ?cwn_site.Site;
  build_plan: ?cwn_tech.InstallBuildPlan;
  customer_name: string;
  customer_phone_number: string;
  customer_email: string;
  work_order_shots: Array<cwn_tech_viewmodels.WorkOrderShotViewModel>;
  recent_visit_count: string;
  time_window: boolean;

  constructor(props: $Shape<SearchWorkOrdersReply_ViewModel> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.build_plan = null;
    if (props.hasOwnProperty('build_plan')) {
      const v = props.build_plan;
      this.build_plan = v && new cwn_tech.InstallBuildPlan(v);
    }

    this.customer_name = '';
    if (props.hasOwnProperty('customer_name')) {
      const v = props.customer_name;
      this.customer_name = v;
    }

    this.customer_phone_number = '';
    if (props.hasOwnProperty('customer_phone_number')) {
      const v = props.customer_phone_number;
      this.customer_phone_number = v;
    }

    this.customer_email = '';
    if (props.hasOwnProperty('customer_email')) {
      const v = props.customer_email;
      this.customer_email = v;
    }

    this.work_order_shots = [];
    if (props.hasOwnProperty('work_order_shots')) {
      const v = props.work_order_shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_order_shots should be array');
      }
      this.work_order_shots = v.map(function(v) {
        return new cwn_tech_viewmodels.WorkOrderShotViewModel(v);
      });
    }

    this.recent_visit_count = '0';
    if (props.hasOwnProperty('recent_visit_count')) {
      const v = props.recent_visit_count;
      this.recent_visit_count = v;
    }

    this.time_window = false;
    if (props.hasOwnProperty('time_window')) {
      const v = props.time_window;
      this.time_window = !!v;
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }

  getBuildPlan(): cwn_tech.InstallBuildPlan {
    if (this.build_plan) {
      return this.build_plan;
    }
    return new cwn_tech.InstallBuildPlan();
  }
}
export {SearchWorkOrdersReply_ViewModel};
class SearchWorkOrdersReply {
  view_models: Array<SearchWorkOrdersReply_ViewModel>;
  cursor: string;

  constructor(props: $Shape<SearchWorkOrdersReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.view_models = [];
    if (props.hasOwnProperty('view_models')) {
      const v = props.view_models;
      if (!Array.isArray(v)) {
        throw new Error('repeated field view_models should be array');
      }
      this.view_models = v.map(function(v) {
        return new SearchWorkOrdersReply_ViewModel(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {SearchWorkOrdersReply};

class EditTagContentsRequest {
  id: string;
  description: string;
  created_for: cwn_tech.Tag_Team;

  constructor(props: $Shape<EditTagContentsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.created_for = cwn_tech.Tag_TeamValue(0);
    if (props.hasOwnProperty('created_for')) {
      const v = props.created_for;
      this.created_for = v;
    }
  }
}
export {EditTagContentsRequest};

class ListTagsRequest {
  include_disabled: boolean;

  constructor(props: $Shape<ListTagsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.include_disabled = false;
    if (props.hasOwnProperty('include_disabled')) {
      const v = props.include_disabled;
      this.include_disabled = !!v;
    }
  }
}
export {ListTagsRequest};

class ListTagsResponse {
  tags: Array<cwn_tech.Tag>;

  constructor(props: $Shape<ListTagsResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.tags = [];
    if (props.hasOwnProperty('tags')) {
      const v = props.tags;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tags should be array');
      }
      this.tags = v.map(function(v) {
        return new cwn_tech.Tag(v);
      });
    }
  }
}
export {ListTagsResponse};

class GetTagConfigResponse {
  new_work_order_tag: string;

  constructor(props: $Shape<GetTagConfigResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.new_work_order_tag = '';
    if (props.hasOwnProperty('new_work_order_tag')) {
      const v = props.new_work_order_tag;
      this.new_work_order_tag = v;
    }
  }
}
export {GetTagConfigResponse};

class CloseWorkOrderRequest {
  work_order_id: string;
  reasons: Array<cwn_tech.FlexibleWorkOrder_ClosedReason>;
  other_reason: string;
  closing_notes: string;
  shots_without_los: Array<cwn_tech_viewmodels.WorkOrderShotViewModel>;

  constructor(props: $Shape<CloseWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.reasons = [];
    if (props.hasOwnProperty('reasons')) {
      const v = props.reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field reasons should be array');
      }
      this.reasons = v.map(function(v) {
        return v;
      });
    }

    this.other_reason = '';
    if (props.hasOwnProperty('other_reason')) {
      const v = props.other_reason;
      this.other_reason = v;
    }

    this.closing_notes = '';
    if (props.hasOwnProperty('closing_notes')) {
      const v = props.closing_notes;
      this.closing_notes = v;
    }

    this.shots_without_los = [];
    if (props.hasOwnProperty('shots_without_los')) {
      const v = props.shots_without_los;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shots_without_los should be array');
      }
      this.shots_without_los = v.map(function(v) {
        return new cwn_tech_viewmodels.WorkOrderShotViewModel(v);
      });
    }
  }
}
export {CloseWorkOrderRequest};

class SetWorkOrderDescriptionRequest {
  work_order_id: string;
  description: string;

  constructor(props: $Shape<SetWorkOrderDescriptionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }
}
export {SetWorkOrderDescriptionRequest};

class SetWorkOrderTagsRequest {
  work_order_id: string;
  tags: Array<string>;

  constructor(props: $Shape<SetWorkOrderTagsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.tags = [];
    if (props.hasOwnProperty('tags')) {
      const v = props.tags;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tags should be array');
      }
      this.tags = v.map(function(v) {
        return v;
      });
    }
  }
}
export {SetWorkOrderTagsRequest};

class SetWorkOrderAssignedToRequest {
  work_order_id: string;
  assigned_to: ?cwn_user.User;

  constructor(props: $Shape<SetWorkOrderAssignedToRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.assigned_to = null;
    if (props.hasOwnProperty('assigned_to')) {
      const v = props.assigned_to;
      this.assigned_to = v && new cwn_user.User(v);
    }
  }

  getAssignedTo(): cwn_user.User {
    if (this.assigned_to) {
      return this.assigned_to;
    }
    return new cwn_user.User();
  }
}
export {SetWorkOrderAssignedToRequest};

class EditWorkOrderRequest {
  work_order_id: string;
  title: string;
  description: string;
  tags: Array<string>;

  constructor(props: $Shape<EditWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.title = '';
    if (props.hasOwnProperty('title')) {
      const v = props.title;
      this.title = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.tags = [];
    if (props.hasOwnProperty('tags')) {
      const v = props.tags;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tags should be array');
      }
      this.tags = v.map(function(v) {
        return v;
      });
    }
  }
}
export {EditWorkOrderRequest};

class GenerateWorkOrderApptTokenRequest {
  work_order_id: string;
  appointment_type: string;

  constructor(props: $Shape<GenerateWorkOrderApptTokenRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.appointment_type = '0';
    if (props.hasOwnProperty('appointment_type')) {
      const v = props.appointment_type;
      this.appointment_type = v;
    }
  }
}
export {GenerateWorkOrderApptTokenRequest};

class GenerateWorkOrderApptTokenReply {
  token: string;

  constructor(props: $Shape<GenerateWorkOrderApptTokenReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }
  }
}
export {GenerateWorkOrderApptTokenReply};

class ScheduleWorkRequest {
  site_id: string;
  work: ?cwn_site_build.RequiredWork;
  work_order_id: string;
  new_work_order: boolean;

  constructor(props: $Shape<ScheduleWorkRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.work = null;
    if (props.hasOwnProperty('work')) {
      const v = props.work;
      this.work = v && new cwn_site_build.RequiredWork(v);
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.new_work_order = false;
    if (props.hasOwnProperty('new_work_order')) {
      const v = props.new_work_order;
      this.new_work_order = !!v;
    }
  }

  getWork(): cwn_site_build.RequiredWork {
    if (this.work) {
      return this.work;
    }
    return new cwn_site_build.RequiredWork();
  }
}
export {ScheduleWorkRequest};

class ScheduleWorkReply {
  work_order: ?cwn_tech.FlexibleWorkOrder;

  constructor(props: $Shape<ScheduleWorkReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }
}
export {ScheduleWorkReply};

class GetSiteVisitCountsRequest {
  site_ids: Array<string>;
  count_visits_start: ?Date;

  constructor(props: $Shape<GetSiteVisitCountsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_ids = [];
    if (props.hasOwnProperty('site_ids')) {
      const v = props.site_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field site_ids should be array');
      }
      this.site_ids = v.map(function(v) {
        return v;
      });
    }

    this.count_visits_start = null;
    if (props.hasOwnProperty('count_visits_start')) {
      const v = props.count_visits_start;
      this.count_visits_start = v && new Date(v);
    }
  }
}
export {GetSiteVisitCountsRequest};

class GetSiteVisitCountsReply {
  site_to_visits: {[key: string]: string};

  constructor(props: $Shape<GetSiteVisitCountsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_to_visits = {};
    if (props.hasOwnProperty('site_to_visits')) {
      const v = props.site_to_visits;
      for (const key: any of Object.keys(v)) {
        this.site_to_visits[key] = v[key];
      }
    }
  }
}
export {GetSiteVisitCountsReply};

class ListBuildPlansRequest {
  limit: number;
  cursor: string;

  constructor(props: $Shape<ListBuildPlansRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.limit = 0;
    if (props.hasOwnProperty('limit')) {
      const v = props.limit;
      this.limit = v;
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListBuildPlansRequest};

class AddShotSelectionRequest {
  install_build_plan_id: string;
  candidate_network_plan_id: string;
  mount_id: string;
  site_id: string;
  is_ptmp: boolean;
  device_platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<AddShotSelectionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.install_build_plan_id = '0';
    if (props.hasOwnProperty('install_build_plan_id')) {
      const v = props.install_build_plan_id;
      this.install_build_plan_id = v;
    }

    this.candidate_network_plan_id = '0';
    if (props.hasOwnProperty('candidate_network_plan_id')) {
      const v = props.candidate_network_plan_id;
      this.candidate_network_plan_id = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.is_ptmp = false;
    if (props.hasOwnProperty('is_ptmp')) {
      const v = props.is_ptmp;
      this.is_ptmp = !!v;
    }

    this.device_platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('device_platform')) {
      const v = props.device_platform;
      this.device_platform = v;
    }
  }
}
export {AddShotSelectionRequest};

class RemoveShotSelectionRequest {
  install_build_plan_id: string;
  mount_id: string;
  reasons: Array<cwn_tech.FlexibleWorkOrder_ClosedReason>;
  other_reason: string;
  closing_notes: string;

  constructor(props: $Shape<RemoveShotSelectionRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.install_build_plan_id = '0';
    if (props.hasOwnProperty('install_build_plan_id')) {
      const v = props.install_build_plan_id;
      this.install_build_plan_id = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.reasons = [];
    if (props.hasOwnProperty('reasons')) {
      const v = props.reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field reasons should be array');
      }
      this.reasons = v.map(function(v) {
        return v;
      });
    }

    this.other_reason = '';
    if (props.hasOwnProperty('other_reason')) {
      const v = props.other_reason;
      this.other_reason = v;
    }

    this.closing_notes = '';
    if (props.hasOwnProperty('closing_notes')) {
      const v = props.closing_notes;
      this.closing_notes = v;
    }
  }
}
export {RemoveShotSelectionRequest};

class ListBuildPlansReply {
  build_plans: Array<cwn_tech.InstallBuildPlan>;
  cursor: string;

  constructor(props: $Shape<ListBuildPlansReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.build_plans = [];
    if (props.hasOwnProperty('build_plans')) {
      const v = props.build_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field build_plans should be array');
      }
      this.build_plans = v.map(function(v) {
        return new cwn_tech.InstallBuildPlan(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListBuildPlansReply};

class AvailableShot {
  site: ?cwn_site.Site;
  building_description: ?cwn_site_details.BuildingDescription;
  mount: ?cwn_geo.Mount;
  distance_meters: number;
  own_building_obstructions: string;
  not_own_building_obstructions: string;
  average_customer_speed: number;
  plans: Array<cwn_customers.Subscription_Plan>;
  used_ports: string;
  address: ?cwn_customers.Address;
  invalid_reason: string;
  is_ptmp: boolean;
  can_provide_high_speed: boolean;
  device_platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<AvailableShot> = {}): void {
    if (!props) {
      props = {};
    }

    this.site = null;
    if (props.hasOwnProperty('site')) {
      const v = props.site;
      this.site = v && new cwn_site.Site(v);
    }

    this.building_description = null;
    if (props.hasOwnProperty('building_description')) {
      const v = props.building_description;
      this.building_description =
        v && new cwn_site_details.BuildingDescription(v);
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_geo.Mount(v);
    }

    this.distance_meters = 0;
    if (props.hasOwnProperty('distance_meters')) {
      const v = props.distance_meters;
      this.distance_meters = v;
    }

    this.own_building_obstructions = '0';
    if (props.hasOwnProperty('own_building_obstructions')) {
      const v = props.own_building_obstructions;
      this.own_building_obstructions = v;
    }

    this.not_own_building_obstructions = '0';
    if (props.hasOwnProperty('not_own_building_obstructions')) {
      const v = props.not_own_building_obstructions;
      this.not_own_building_obstructions = v;
    }

    this.average_customer_speed = 0;
    if (props.hasOwnProperty('average_customer_speed')) {
      const v = props.average_customer_speed;
      this.average_customer_speed = v;
    }

    this.plans = [];
    if (props.hasOwnProperty('plans')) {
      const v = props.plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plans should be array');
      }
      this.plans = v.map(function(v) {
        return v;
      });
    }

    this.used_ports = '0';
    if (props.hasOwnProperty('used_ports')) {
      const v = props.used_ports;
      this.used_ports = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.invalid_reason = '';
    if (props.hasOwnProperty('invalid_reason')) {
      const v = props.invalid_reason;
      this.invalid_reason = v;
    }

    this.is_ptmp = false;
    if (props.hasOwnProperty('is_ptmp')) {
      const v = props.is_ptmp;
      this.is_ptmp = !!v;
    }

    this.can_provide_high_speed = false;
    if (props.hasOwnProperty('can_provide_high_speed')) {
      const v = props.can_provide_high_speed;
      this.can_provide_high_speed = !!v;
    }

    this.device_platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('device_platform')) {
      const v = props.device_platform;
      this.device_platform = v;
    }
  }

  getSite(): cwn_site.Site {
    if (this.site) {
      return this.site;
    }
    return new cwn_site.Site();
  }

  getBuildingDescription(): cwn_site_details.BuildingDescription {
    if (this.building_description) {
      return this.building_description;
    }
    return new cwn_site_details.BuildingDescription();
  }

  getMount(): cwn_geo.Mount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_geo.Mount();
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {AvailableShot};

class GetBuildPlanPageDataReply {
  build_plan: ?cwn_tech.InstallBuildPlan;
  serviceability_geo: ?cwn_serviceability.Geo;
  candidate_network_plan_id: string;
  available_shots: Array<AvailableShot>;
  install_site: ?cwn_site.Site;
  building_description: ?cwn_site_details.BuildingDescription;
  work_order: ?cwn_tech.FlexibleWorkOrder;
  lead: ?cwn_lead.Lead;
  customer: ?cwn_customers.Customer;

  constructor(props: $Shape<GetBuildPlanPageDataReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.build_plan = null;
    if (props.hasOwnProperty('build_plan')) {
      const v = props.build_plan;
      this.build_plan = v && new cwn_tech.InstallBuildPlan(v);
    }

    this.serviceability_geo = null;
    if (props.hasOwnProperty('serviceability_geo')) {
      const v = props.serviceability_geo;
      this.serviceability_geo = v && new cwn_serviceability.Geo(v);
    }

    this.candidate_network_plan_id = '0';
    if (props.hasOwnProperty('candidate_network_plan_id')) {
      const v = props.candidate_network_plan_id;
      this.candidate_network_plan_id = v;
    }

    this.available_shots = [];
    if (props.hasOwnProperty('available_shots')) {
      const v = props.available_shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field available_shots should be array');
      }
      this.available_shots = v.map(function(v) {
        return new AvailableShot(v);
      });
    }

    this.install_site = null;
    if (props.hasOwnProperty('install_site')) {
      const v = props.install_site;
      this.install_site = v && new cwn_site.Site(v);
    }

    this.building_description = null;
    if (props.hasOwnProperty('building_description')) {
      const v = props.building_description;
      this.building_description =
        v && new cwn_site_details.BuildingDescription(v);
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.FlexibleWorkOrder(v);
    }

    this.lead = null;
    if (props.hasOwnProperty('lead')) {
      const v = props.lead;
      this.lead = v && new cwn_lead.Lead(v);
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }
  }

  getBuildPlan(): cwn_tech.InstallBuildPlan {
    if (this.build_plan) {
      return this.build_plan;
    }
    return new cwn_tech.InstallBuildPlan();
  }

  getServiceabilityGeo(): cwn_serviceability.Geo {
    if (this.serviceability_geo) {
      return this.serviceability_geo;
    }
    return new cwn_serviceability.Geo();
  }

  getInstallSite(): cwn_site.Site {
    if (this.install_site) {
      return this.install_site;
    }
    return new cwn_site.Site();
  }

  getBuildingDescription(): cwn_site_details.BuildingDescription {
    if (this.building_description) {
      return this.building_description;
    }
    return new cwn_site_details.BuildingDescription();
  }

  getWorkOrder(): cwn_tech.FlexibleWorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.FlexibleWorkOrder();
  }

  getLead(): cwn_lead.Lead {
    if (this.lead) {
      return this.lead;
    }
    return new cwn_lead.Lead();
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }
}
export {GetBuildPlanPageDataReply};

class UpgradeBuildPlanReply {
  build_plan: ?cwn_tech.InstallBuildPlan;

  constructor(props: $Shape<UpgradeBuildPlanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.build_plan = null;
    if (props.hasOwnProperty('build_plan')) {
      const v = props.build_plan;
      this.build_plan = v && new cwn_tech.InstallBuildPlan(v);
    }
  }

  getBuildPlan(): cwn_tech.InstallBuildPlan {
    if (this.build_plan) {
      return this.build_plan;
    }
    return new cwn_tech.InstallBuildPlan();
  }
}
export {UpgradeBuildPlanReply};

class GetAvailableShotsForInstallBuildPlanReply {
  candidate_network_plan_id: string;
  available_shots: Array<AvailableShot>;

  constructor(
    props: $Shape<GetAvailableShotsForInstallBuildPlanReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.candidate_network_plan_id = '0';
    if (props.hasOwnProperty('candidate_network_plan_id')) {
      const v = props.candidate_network_plan_id;
      this.candidate_network_plan_id = v;
    }

    this.available_shots = [];
    if (props.hasOwnProperty('available_shots')) {
      const v = props.available_shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field available_shots should be array');
      }
      this.available_shots = v.map(function(v) {
        return new AvailableShot(v);
      });
    }
  }
}
export {GetAvailableShotsForInstallBuildPlanReply};

class ListAssignableTechsReply {
  techs: Array<string>;

  constructor(props: $Shape<ListAssignableTechsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.techs = [];
    if (props.hasOwnProperty('techs')) {
      const v = props.techs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field techs should be array');
      }
      this.techs = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListAssignableTechsReply};

class WorkOrderListItem {
  name: string;
  address: ?cwn_customers.Address;
  raw_address: string;
  work_order_id: string;
  order_type: string;

  constructor(props: $Shape<WorkOrderListItem> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.order_type = '';
    if (props.hasOwnProperty('order_type')) {
      const v = props.order_type;
      this.order_type = v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {WorkOrderListItem};

class ListWorkOrdersForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListWorkOrdersForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListWorkOrdersForSiteRequest};

class ListWorkOrdersForSiteReply {
  work_orders: Array<cwn_tech.WorkOrder>;

  constructor(props: $Shape<ListWorkOrdersForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_orders = [];
    if (props.hasOwnProperty('work_orders')) {
      const v = props.work_orders;
      if (!Array.isArray(v)) {
        throw new Error('repeated field work_orders should be array');
      }
      this.work_orders = v.map(function(v) {
        return new cwn_tech.WorkOrder(v);
      });
    }
  }
}
export {ListWorkOrdersForSiteReply};

class WorkOrderInfo {
  lead_id: string;
  address: ?cwn_customers.Address;
  raw_address: string;
  name: string;
  phone_number: string;
  work_order: ?cwn_tech.WorkOrder;
  building_type: cwn_site.Site_BuildingType;
  plan: ?Plan;

  constructor(props: $Shape<WorkOrderInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.WorkOrder(v);
    }

    this.building_type = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.plan = null;
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v && new Plan(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getWorkOrder(): cwn_tech.WorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.WorkOrder();
  }

  getPlan(): Plan {
    if (this.plan) {
      return this.plan;
    }
    return new Plan();
  }
}
export {WorkOrderInfo};

class Plan {
  name: string;
  amount: ?cwn_billing.Money;
  speed: string;
  plan: cwn_customers.Subscription_Plan;

  constructor(props: $Shape<Plan> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.amount = null;
    if (props.hasOwnProperty('amount')) {
      const v = props.amount;
      this.amount = v && new cwn_billing.Money(v);
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }

  getAmount(): cwn_billing.Money {
    if (this.amount) {
      return this.amount;
    }
    return new cwn_billing.Money();
  }
}
export {Plan};

class GetWorkOrderInfoRequest {
  work_order_id: string;

  constructor(props: $Shape<GetWorkOrderInfoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }
  }
}
export {GetWorkOrderInfoRequest};

class GetWorkOrderInfoReply {
  work_order_info: ?WorkOrderInfo;

  constructor(props: $Shape<GetWorkOrderInfoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_info = null;
    if (props.hasOwnProperty('work_order_info')) {
      const v = props.work_order_info;
      this.work_order_info = v && new WorkOrderInfo(v);
    }
  }

  getWorkOrderInfo(): WorkOrderInfo {
    if (this.work_order_info) {
      return this.work_order_info;
    }
    return new WorkOrderInfo();
  }
}
export {GetWorkOrderInfoReply};

class MacTargetInfo {
  srl_id: string;
  mac_address: string;
  photo: boolean;
  address: ?cwn_customers.Address;
  radio_model: cwn_asset.Model;
  photo_uploaded: boolean;

  constructor(props: $Shape<MacTargetInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.photo = false;
    if (props.hasOwnProperty('photo')) {
      const v = props.photo;
      this.photo = !!v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.radio_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('radio_model')) {
      const v = props.radio_model;
      this.radio_model = v;
    }

    this.photo_uploaded = false;
    if (props.hasOwnProperty('photo_uploaded')) {
      const v = props.photo_uploaded;
      this.photo_uploaded = !!v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {MacTargetInfo};

class LosTargetInfo {
  srl_id: string;
  assessment: cwn_netplan.LosAssessment_Assessment;
  reasons: Array<cwn_netplan.LosAssessment_ObstructedReason>;
  address: ?cwn_customers.Address;
  photo: boolean;

  constructor(props: $Shape<LosTargetInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.assessment = cwn_netplan.LosAssessment_AssessmentValue(0);
    if (props.hasOwnProperty('assessment')) {
      const v = props.assessment;
      this.assessment = v;
    }

    this.reasons = [];
    if (props.hasOwnProperty('reasons')) {
      const v = props.reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field reasons should be array');
      }
      this.reasons = v.map(function(v) {
        return v;
      });
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.photo = false;
    if (props.hasOwnProperty('photo')) {
      const v = props.photo;
      this.photo = !!v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {LosTargetInfo};

class StartWorkOrderRequest {
  work_order_id: string;

  constructor(props: $Shape<StartWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }
  }
}
export {StartWorkOrderRequest};

class DeleteWorkOrderRequest {
  work_order: ?cwn_tech.WorkOrder;

  constructor(props: $Shape<DeleteWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.WorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.WorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.WorkOrder();
  }
}
export {DeleteWorkOrderRequest};

class CreateWorkOrderRequest {
  work_order_type: string;
  site_id: string;
  lead_id: string;
  radio_group_id: string;
  work_order_name: cwn_tech.OtherWorkOrder_WorkOrderName;
  work_order_description: string;
  zendesk_ticket: string;
  more_info: string;

  constructor(props: $Shape<CreateWorkOrderRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_type = '';
    if (props.hasOwnProperty('work_order_type')) {
      const v = props.work_order_type;
      this.work_order_type = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.radio_group_id = '0';
    if (props.hasOwnProperty('radio_group_id')) {
      const v = props.radio_group_id;
      this.radio_group_id = v;
    }

    this.work_order_name = cwn_tech.OtherWorkOrder_WorkOrderNameValue(0);
    if (props.hasOwnProperty('work_order_name')) {
      const v = props.work_order_name;
      this.work_order_name = v;
    }

    this.work_order_description = '';
    if (props.hasOwnProperty('work_order_description')) {
      const v = props.work_order_description;
      this.work_order_description = v;
    }

    this.zendesk_ticket = '';
    if (props.hasOwnProperty('zendesk_ticket')) {
      const v = props.zendesk_ticket;
      this.zendesk_ticket = v;
    }

    this.more_info = '';
    if (props.hasOwnProperty('more_info')) {
      const v = props.more_info;
      this.more_info = v;
    }
  }
}
export {CreateWorkOrderRequest};

class CreateWorkOrderReply {
  work_order: ?cwn_tech.WorkOrder;

  constructor(props: $Shape<CreateWorkOrderReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order = null;
    if (props.hasOwnProperty('work_order')) {
      const v = props.work_order;
      this.work_order = v && new cwn_tech.WorkOrder(v);
    }
  }

  getWorkOrder(): cwn_tech.WorkOrder {
    if (this.work_order) {
      return this.work_order;
    }
    return new cwn_tech.WorkOrder();
  }
}
export {CreateWorkOrderReply};

class GetInstrumentRequest {
  instrument_id: string;

  constructor(props: $Shape<GetInstrumentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.instrument_id = '0';
    if (props.hasOwnProperty('instrument_id')) {
      const v = props.instrument_id;
      this.instrument_id = v;
    }
  }
}
export {GetInstrumentRequest};

class GetInstrumentReply {
  instrument: ?cwn_billing.Instrument;

  constructor(props: $Shape<GetInstrumentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new cwn_billing.Instrument(v);
    }
  }

  getInstrument(): cwn_billing.Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new cwn_billing.Instrument();
  }
}
export {GetInstrumentReply};

class GetDependentDownstreamDevicesRequest {
  device_id: string;

  constructor(props: $Shape<GetDependentDownstreamDevicesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }
}
export {GetDependentDownstreamDevicesRequest};

class GetDependentDownstreamDevicesReply {
  device_ids: Array<string>;

  constructor(props: $Shape<GetDependentDownstreamDevicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_ids = [];
    if (props.hasOwnProperty('device_ids')) {
      const v = props.device_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field device_ids should be array');
      }
      this.device_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetDependentDownstreamDevicesReply};

class PointTargetInfo {
  srl_id: string;
  tx_power: number;
  tx_power_avail: boolean;
  expected_range: ?cwn_antenna.Range;
  address: ?cwn_customers.Address;
  photo: boolean;
  photo_uploaded: boolean;
  no_peer: boolean;
  expected_mcs_range: ?cwn_antenna.Range;
  mac_address: string;
  radio_model: cwn_asset.Model;
  current_mcs: string;
  aligned: boolean;
  mcs_avail: boolean;
  current_rssi: number;

  constructor(props: $Shape<PointTargetInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.tx_power = 0;
    if (props.hasOwnProperty('tx_power')) {
      const v = props.tx_power;
      this.tx_power = v;
    }

    this.tx_power_avail = false;
    if (props.hasOwnProperty('tx_power_avail')) {
      const v = props.tx_power_avail;
      this.tx_power_avail = !!v;
    }

    this.expected_range = null;
    if (props.hasOwnProperty('expected_range')) {
      const v = props.expected_range;
      this.expected_range = v && new cwn_antenna.Range(v);
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.photo = false;
    if (props.hasOwnProperty('photo')) {
      const v = props.photo;
      this.photo = !!v;
    }

    this.photo_uploaded = false;
    if (props.hasOwnProperty('photo_uploaded')) {
      const v = props.photo_uploaded;
      this.photo_uploaded = !!v;
    }

    this.no_peer = false;
    if (props.hasOwnProperty('no_peer')) {
      const v = props.no_peer;
      this.no_peer = !!v;
    }

    this.expected_mcs_range = null;
    if (props.hasOwnProperty('expected_mcs_range')) {
      const v = props.expected_mcs_range;
      this.expected_mcs_range = v && new cwn_antenna.Range(v);
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.radio_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('radio_model')) {
      const v = props.radio_model;
      this.radio_model = v;
    }

    this.current_mcs = '0';
    if (props.hasOwnProperty('current_mcs')) {
      const v = props.current_mcs;
      this.current_mcs = v;
    }

    this.aligned = false;
    if (props.hasOwnProperty('aligned')) {
      const v = props.aligned;
      this.aligned = !!v;
    }

    this.mcs_avail = false;
    if (props.hasOwnProperty('mcs_avail')) {
      const v = props.mcs_avail;
      this.mcs_avail = !!v;
    }

    this.current_rssi = 0;
    if (props.hasOwnProperty('current_rssi')) {
      const v = props.current_rssi;
      this.current_rssi = v;
    }
  }

  getExpectedRange(): cwn_antenna.Range {
    if (this.expected_range) {
      return this.expected_range;
    }
    return new cwn_antenna.Range();
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getExpectedMcsRange(): cwn_antenna.Range {
    if (this.expected_mcs_range) {
      return this.expected_mcs_range;
    }
    return new cwn_antenna.Range();
  }
}
export {PointTargetInfo};

class AddRadioInfo {
  srl_id: string;
  mac_address: string;
  photo: boolean;
  address: ?cwn_customers.Address;
  radio_model: cwn_asset.Model;
  photo_uploaded: boolean;
  radio_action: cwn_tech.RadioAction_Action;

  constructor(props: $Shape<AddRadioInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.photo = false;
    if (props.hasOwnProperty('photo')) {
      const v = props.photo;
      this.photo = !!v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.radio_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('radio_model')) {
      const v = props.radio_model;
      this.radio_model = v;
    }

    this.photo_uploaded = false;
    if (props.hasOwnProperty('photo_uploaded')) {
      const v = props.photo_uploaded;
      this.photo_uploaded = !!v;
    }

    this.radio_action = cwn_tech.RadioAction_ActionValue(0);
    if (props.hasOwnProperty('radio_action')) {
      const v = props.radio_action;
      this.radio_action = v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {AddRadioInfo};

class GetGrassRequest {
  area_id: string;

  constructor(props: $Shape<GetGrassRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }
  }
}
export {GetGrassRequest};

export const GetGrassReply_Node_NodeTypeValues = {
  LEAF: 0,
  RELAY: 1,
  SOURCE: 2,
  SRO: 3,
};
type GetGrassReply_Node_NodeType = $Keys<
  typeof GetGrassReply_Node_NodeTypeValues,
>;
export type {GetGrassReply_Node_NodeType};

export function GetGrassReply_Node_NodeTypeValue(
  n: number,
): GetGrassReply_Node_NodeType {
  switch (n) {
    case 0:
      return 'LEAF';

    case 1:
      return 'RELAY';

    case 2:
      return 'SOURCE';

    case 3:
      return 'SRO';

    default:
      return 'LEAF';
  }
}

class GetGrassReply_Node {
  device_id: string;
  node_type: GetGrassReply_Node_NodeType;
  online: boolean;
  latitude: number;
  longitude: number;
  dependent_nodes: string;
  dependent_subscribers: string;
  critically_dependent_nodes: string;
  critically_dependent_subscribers: string;

  constructor(props: $Shape<GetGrassReply_Node> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.node_type = GetGrassReply_Node_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.online = false;
    if (props.hasOwnProperty('online')) {
      const v = props.online;
      this.online = !!v;
    }

    this.latitude = 0;
    if (props.hasOwnProperty('latitude')) {
      const v = props.latitude;
      this.latitude = v;
    }

    this.longitude = 0;
    if (props.hasOwnProperty('longitude')) {
      const v = props.longitude;
      this.longitude = v;
    }

    this.dependent_nodes = '0';
    if (props.hasOwnProperty('dependent_nodes')) {
      const v = props.dependent_nodes;
      this.dependent_nodes = v;
    }

    this.dependent_subscribers = '0';
    if (props.hasOwnProperty('dependent_subscribers')) {
      const v = props.dependent_subscribers;
      this.dependent_subscribers = v;
    }

    this.critically_dependent_nodes = '0';
    if (props.hasOwnProperty('critically_dependent_nodes')) {
      const v = props.critically_dependent_nodes;
      this.critically_dependent_nodes = v;
    }

    this.critically_dependent_subscribers = '0';
    if (props.hasOwnProperty('critically_dependent_subscribers')) {
      const v = props.critically_dependent_subscribers;
      this.critically_dependent_subscribers = v;
    }
  }
}
export {GetGrassReply_Node};
export const GetGrassReply_Edge_EdgeTypeValues = {
  UNKNOWN_TYPE: 0,
  AIRMAX: 1,
  AIRFIBER: 2,
  METROLINQ: 3,
  SIKLU: 4,
};
type GetGrassReply_Edge_EdgeType = $Keys<
  typeof GetGrassReply_Edge_EdgeTypeValues,
>;
export type {GetGrassReply_Edge_EdgeType};

export function GetGrassReply_Edge_EdgeTypeValue(
  n: number,
): GetGrassReply_Edge_EdgeType {
  switch (n) {
    case 0:
      return 'UNKNOWN_TYPE';

    case 1:
      return 'AIRMAX';

    case 2:
      return 'AIRFIBER';

    case 3:
      return 'METROLINQ';

    case 4:
      return 'SIKLU';

    default:
      return 'UNKNOWN_TYPE';
  }
}

export const GetGrassReply_Edge_EdgeStatusValues = {
  UNKNOWN_STATUS: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  UNROUTABLE: 3,
};
type GetGrassReply_Edge_EdgeStatus = $Keys<
  typeof GetGrassReply_Edge_EdgeStatusValues,
>;
export type {GetGrassReply_Edge_EdgeStatus};

export function GetGrassReply_Edge_EdgeStatusValue(
  n: number,
): GetGrassReply_Edge_EdgeStatus {
  switch (n) {
    case 0:
      return 'UNKNOWN_STATUS';

    case 1:
      return 'ACTIVE';

    case 2:
      return 'INACTIVE';

    case 3:
      return 'UNROUTABLE';

    default:
      return 'UNKNOWN_STATUS';
  }
}

class GetGrassReply_Edge {
  upstream: string;
  downstream: string;
  edge_type: GetGrassReply_Edge_EdgeType;
  link_up: boolean;
  has_ewo: boolean;
  through_consumer_device: boolean;
  status: GetGrassReply_Edge_EdgeStatus;
  unexpected: boolean;

  constructor(props: $Shape<GetGrassReply_Edge> = {}): void {
    if (!props) {
      props = {};
    }

    this.upstream = '';
    if (props.hasOwnProperty('upstream')) {
      const v = props.upstream;
      this.upstream = v;
    }

    this.downstream = '';
    if (props.hasOwnProperty('downstream')) {
      const v = props.downstream;
      this.downstream = v;
    }

    this.edge_type = GetGrassReply_Edge_EdgeTypeValue(0);
    if (props.hasOwnProperty('edge_type')) {
      const v = props.edge_type;
      this.edge_type = v;
    }

    this.link_up = false;
    if (props.hasOwnProperty('link_up')) {
      const v = props.link_up;
      this.link_up = !!v;
    }

    this.has_ewo = false;
    if (props.hasOwnProperty('has_ewo')) {
      const v = props.has_ewo;
      this.has_ewo = !!v;
    }

    this.through_consumer_device = false;
    if (props.hasOwnProperty('through_consumer_device')) {
      const v = props.through_consumer_device;
      this.through_consumer_device = !!v;
    }

    this.status = GetGrassReply_Edge_EdgeStatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.unexpected = false;
    if (props.hasOwnProperty('unexpected')) {
      const v = props.unexpected;
      this.unexpected = !!v;
    }
  }
}
export {GetGrassReply_Edge};
class GetGrassReply {
  nodes: Array<GetGrassReply_Node>;
  edges: Array<GetGrassReply_Edge>;
  created: ?Date;

  constructor(props: $Shape<GetGrassReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.nodes = [];
    if (props.hasOwnProperty('nodes')) {
      const v = props.nodes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field nodes should be array');
      }
      this.nodes = v.map(function(v) {
        return new GetGrassReply_Node(v);
      });
    }

    this.edges = [];
    if (props.hasOwnProperty('edges')) {
      const v = props.edges;
      if (!Array.isArray(v)) {
        throw new Error('repeated field edges should be array');
      }
      this.edges = v.map(function(v) {
        return new GetGrassReply_Edge(v);
      });
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }
}
export {GetGrassReply};

class ListGeofencesRequest {
  cursor: string;

  constructor(props: $Shape<ListGeofencesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListGeofencesRequest};

class ListGeofencesReply {
  geofences: Array<cwn_geofence.Geofence>;
  cursor: string;

  constructor(props: $Shape<ListGeofencesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofences = [];
    if (props.hasOwnProperty('geofences')) {
      const v = props.geofences;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geofences should be array');
      }
      this.geofences = v.map(function(v) {
        return new cwn_geofence.Geofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListGeofencesReply};

class CreateGeofenceRequest {
  name: string;
  description: string;
  kml_file: string;

  constructor(props: $Shape<CreateGeofenceRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.kml_file = '';
    if (props.hasOwnProperty('kml_file')) {
      const v = props.kml_file;
      this.kml_file = v;
    }
  }
}
export {CreateGeofenceRequest};

class CreateGeofenceReply {
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<CreateGeofenceReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {CreateGeofenceReply};

class CreateProgramGeoRequest {
  geo: ?cwn_program.Geo;

  constructor(props: $Shape<CreateProgramGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_program.Geo(v);
    }
  }

  getGeo(): cwn_program.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_program.Geo();
  }
}
export {CreateProgramGeoRequest};

class PutProgramGeoRequest {
  geo: ?cwn_program.Geo;

  constructor(props: $Shape<PutProgramGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_program.Geo(v);
    }
  }

  getGeo(): cwn_program.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_program.Geo();
  }
}
export {PutProgramGeoRequest};

class ListProgramGeosRequest {
  cursor: string;

  constructor(props: $Shape<ListProgramGeosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListProgramGeosRequest};

class ListProgramGeosReply_GeoWithGeofence {
  geo: ?cwn_program.Geo;
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<ListProgramGeosReply_GeoWithGeofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_program.Geo(v);
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeo(): cwn_program.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_program.Geo();
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {ListProgramGeosReply_GeoWithGeofence};
class ListProgramGeosReply {
  geos: Array<ListProgramGeosReply_GeoWithGeofence>;
  cursor: string;

  constructor(props: $Shape<ListProgramGeosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geos = [];
    if (props.hasOwnProperty('geos')) {
      const v = props.geos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geos should be array');
      }
      this.geos = v.map(function(v) {
        return new ListProgramGeosReply_GeoWithGeofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListProgramGeosReply};

class CreateServGeoRequest {
  geo: ?cwn_serviceability.Geo;

  constructor(props: $Shape<CreateServGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_serviceability.Geo(v);
    }
  }

  getGeo(): cwn_serviceability.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_serviceability.Geo();
  }
}
export {CreateServGeoRequest};

class PutServGeoRequest {
  geo: ?cwn_serviceability.Geo;

  constructor(props: $Shape<PutServGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_serviceability.Geo(v);
    }
  }

  getGeo(): cwn_serviceability.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_serviceability.Geo();
  }
}
export {PutServGeoRequest};

class ListServGeosRequest {
  cursor: string;

  constructor(props: $Shape<ListServGeosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListServGeosRequest};

class ListServGeosReply_GeoWithGeofence {
  geo: ?cwn_serviceability.Geo;
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<ListServGeosReply_GeoWithGeofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_serviceability.Geo(v);
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeo(): cwn_serviceability.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_serviceability.Geo();
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {ListServGeosReply_GeoWithGeofence};
class ListServGeosReply {
  geos: Array<ListServGeosReply_GeoWithGeofence>;
  cursor: string;

  constructor(props: $Shape<ListServGeosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geos = [];
    if (props.hasOwnProperty('geos')) {
      const v = props.geos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geos should be array');
      }
      this.geos = v.map(function(v) {
        return new ListServGeosReply_GeoWithGeofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListServGeosReply};

class CreateReportsGeoRequest {
  geo: ?cwn_reports.Geo;

  constructor(props: $Shape<CreateReportsGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_reports.Geo(v);
    }
  }

  getGeo(): cwn_reports.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_reports.Geo();
  }
}
export {CreateReportsGeoRequest};

class PutReportsGeoRequest {
  geo: ?cwn_reports.Geo;

  constructor(props: $Shape<PutReportsGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_reports.Geo(v);
    }
  }

  getGeo(): cwn_reports.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_reports.Geo();
  }
}
export {PutReportsGeoRequest};

class ListReportsGeosRequest {
  cursor: string;

  constructor(props: $Shape<ListReportsGeosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListReportsGeosRequest};

class ListReportsGeosReply_GeoWithGeofence {
  geo: ?cwn_reports.Geo;
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<ListReportsGeosReply_GeoWithGeofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_reports.Geo(v);
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeo(): cwn_reports.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_reports.Geo();
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {ListReportsGeosReply_GeoWithGeofence};
class ListReportsGeosReply {
  geos: Array<ListReportsGeosReply_GeoWithGeofence>;
  cursor: string;

  constructor(props: $Shape<ListReportsGeosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geos = [];
    if (props.hasOwnProperty('geos')) {
      const v = props.geos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geos should be array');
      }
      this.geos = v.map(function(v) {
        return new ListReportsGeosReply_GeoWithGeofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListReportsGeosReply};

class CreateOpsGeoRequest {
  geo: ?cwn_ops.Geo;

  constructor(props: $Shape<CreateOpsGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_ops.Geo(v);
    }
  }

  getGeo(): cwn_ops.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_ops.Geo();
  }
}
export {CreateOpsGeoRequest};

class PutOpsGeoRequest {
  geo: ?cwn_ops.Geo;

  constructor(props: $Shape<PutOpsGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_ops.Geo(v);
    }
  }

  getGeo(): cwn_ops.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_ops.Geo();
  }
}
export {PutOpsGeoRequest};

class ListOpsGeosRequest {
  cursor: string;

  constructor(props: $Shape<ListOpsGeosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListOpsGeosRequest};

class ListOpsGeosReply_GeoWithGeofence {
  geo: ?cwn_ops.Geo;
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<ListOpsGeosReply_GeoWithGeofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_ops.Geo(v);
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeo(): cwn_ops.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_ops.Geo();
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {ListOpsGeosReply_GeoWithGeofence};
class ListOpsGeosReply {
  geos: Array<ListOpsGeosReply_GeoWithGeofence>;
  cursor: string;

  constructor(props: $Shape<ListOpsGeosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geos = [];
    if (props.hasOwnProperty('geos')) {
      const v = props.geos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geos should be array');
      }
      this.geos = v.map(function(v) {
        return new ListOpsGeosReply_GeoWithGeofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListOpsGeosReply};

class ListPlansForSiteRequest {
  site_id: string;

  constructor(props: $Shape<ListPlansForSiteRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {ListPlansForSiteRequest};

class CreateNetworkGeoRequest {
  geo: ?cwn_network.Geo;

  constructor(props: $Shape<CreateNetworkGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_network.Geo(v);
    }
  }

  getGeo(): cwn_network.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_network.Geo();
  }
}
export {CreateNetworkGeoRequest};

class PutNetworkGeoRequest {
  geo: ?cwn_network.Geo;

  constructor(props: $Shape<PutNetworkGeoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_network.Geo(v);
    }
  }

  getGeo(): cwn_network.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_network.Geo();
  }
}
export {PutNetworkGeoRequest};

class ListNetworkGeosRequest {
  cursor: string;

  constructor(props: $Shape<ListNetworkGeosRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListNetworkGeosRequest};

class ListNetworkGeosReply_GeoWithGeofence {
  geo: ?cwn_network.Geo;
  geofence: ?cwn_geofence.Geofence;

  constructor(props: $Shape<ListNetworkGeosReply_GeoWithGeofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.geo = null;
    if (props.hasOwnProperty('geo')) {
      const v = props.geo;
      this.geo = v && new cwn_network.Geo(v);
    }

    this.geofence = null;
    if (props.hasOwnProperty('geofence')) {
      const v = props.geofence;
      this.geofence = v && new cwn_geofence.Geofence(v);
    }
  }

  getGeo(): cwn_network.Geo {
    if (this.geo) {
      return this.geo;
    }
    return new cwn_network.Geo();
  }

  getGeofence(): cwn_geofence.Geofence {
    if (this.geofence) {
      return this.geofence;
    }
    return new cwn_geofence.Geofence();
  }
}
export {ListNetworkGeosReply_GeoWithGeofence};
class ListNetworkGeosReply {
  geos: Array<ListNetworkGeosReply_GeoWithGeofence>;
  cursor: string;

  constructor(props: $Shape<ListNetworkGeosReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.geos = [];
    if (props.hasOwnProperty('geos')) {
      const v = props.geos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field geos should be array');
      }
      this.geos = v.map(function(v) {
        return new ListNetworkGeosReply_GeoWithGeofence(v);
      });
    }

    this.cursor = '';
    if (props.hasOwnProperty('cursor')) {
      const v = props.cursor;
      this.cursor = v;
    }
  }
}
export {ListNetworkGeosReply};

class ListPlansForSiteReply {
  program_name: string;
  program: cwn_program.Program;
  plans: Array<Plan>;

  constructor(props: $Shape<ListPlansForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.program_name = '';
    if (props.hasOwnProperty('program_name')) {
      const v = props.program_name;
      this.program_name = v;
    }

    this.program = cwn_program.ProgramValue(0);
    if (props.hasOwnProperty('program')) {
      const v = props.program;
      this.program = v;
    }

    this.plans = [];
    if (props.hasOwnProperty('plans')) {
      const v = props.plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plans should be array');
      }
      this.plans = v.map(function(v) {
        return new Plan(v);
      });
    }
  }
}
export {ListPlansForSiteReply};

class GetProgramRequest {
  site_id: string;

  constructor(props: $Shape<GetProgramRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetProgramRequest};

class GetProgramReply {
  program: cwn_program.Program;

  constructor(props: $Shape<GetProgramReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.program = cwn_program.ProgramValue(0);
    if (props.hasOwnProperty('program')) {
      const v = props.program;
      this.program = v;
    }
  }
}
export {GetProgramReply};

class ListPlansRequest {
  program: cwn_program.Program;

  constructor(props: $Shape<ListPlansRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.program = cwn_program.ProgramValue(0);
    if (props.hasOwnProperty('program')) {
      const v = props.program;
      this.program = v;
    }
  }
}
export {ListPlansRequest};

class ListPlansReply {
  plans: Array<Plan>;

  constructor(props: $Shape<ListPlansReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.plans = [];
    if (props.hasOwnProperty('plans')) {
      const v = props.plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plans should be array');
      }
      this.plans = v.map(function(v) {
        return new Plan(v);
      });
    }
  }
}
export {ListPlansReply};

class GetPlanEligibilityOverrideRequest {
  site_id: string;

  constructor(props: $Shape<GetPlanEligibilityOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }
  }
}
export {GetPlanEligibilityOverrideRequest};

class GetPlanEligibilityOverrideReply {
  plan_eligibility_override: ?cwn_customers.PlanEligibilityOverride;

  constructor(props: $Shape<GetPlanEligibilityOverrideReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.plan_eligibility_override = null;
    if (props.hasOwnProperty('plan_eligibility_override')) {
      const v = props.plan_eligibility_override;
      this.plan_eligibility_override =
        v && new cwn_customers.PlanEligibilityOverride(v);
    }
  }

  getPlanEligibilityOverride(): cwn_customers.PlanEligibilityOverride {
    if (this.plan_eligibility_override) {
      return this.plan_eligibility_override;
    }
    return new cwn_customers.PlanEligibilityOverride();
  }
}
export {GetPlanEligibilityOverrideReply};

class SetPlanEligibilityOverrideRequest {
  plan_eligibility_override: ?cwn_customers.PlanEligibilityOverride;

  constructor(props: $Shape<SetPlanEligibilityOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.plan_eligibility_override = null;
    if (props.hasOwnProperty('plan_eligibility_override')) {
      const v = props.plan_eligibility_override;
      this.plan_eligibility_override =
        v && new cwn_customers.PlanEligibilityOverride(v);
    }
  }

  getPlanEligibilityOverride(): cwn_customers.PlanEligibilityOverride {
    if (this.plan_eligibility_override) {
      return this.plan_eligibility_override;
    }
    return new cwn_customers.PlanEligibilityOverride();
  }
}
export {SetPlanEligibilityOverrideRequest};

class SetPlanEligibilityOverrideReply {
  plan_eligibility_override: ?cwn_customers.PlanEligibilityOverride;

  constructor(props: $Shape<SetPlanEligibilityOverrideReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.plan_eligibility_override = null;
    if (props.hasOwnProperty('plan_eligibility_override')) {
      const v = props.plan_eligibility_override;
      this.plan_eligibility_override =
        v && new cwn_customers.PlanEligibilityOverride(v);
    }
  }

  getPlanEligibilityOverride(): cwn_customers.PlanEligibilityOverride {
    if (this.plan_eligibility_override) {
      return this.plan_eligibility_override;
    }
    return new cwn_customers.PlanEligibilityOverride();
  }
}
export {SetPlanEligibilityOverrideReply};

class ListEligiblePlansBeforeOverrideRequest {
  site_id: string;
  site_assessment_id: string;

  constructor(
    props: $Shape<ListEligiblePlansBeforeOverrideRequest> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.site_assessment_id = '0';
    if (props.hasOwnProperty('site_assessment_id')) {
      const v = props.site_assessment_id;
      this.site_assessment_id = v;
    }
  }
}
export {ListEligiblePlansBeforeOverrideRequest};

class ListEligiblePlansBeforeOverrideReply {
  plans: Array<cwn_customers.Subscription_Plan>;

  constructor(props: $Shape<ListEligiblePlansBeforeOverrideReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.plans = [];
    if (props.hasOwnProperty('plans')) {
      const v = props.plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field plans should be array');
      }
      this.plans = v.map(function(v) {
        return v;
      });
    }
  }
}
export {ListEligiblePlansBeforeOverrideReply};

class UpdatePlansForLeadRequest {
  lead_id: string;
  plan: cwn_customers.Subscription_Plan;

  constructor(props: $Shape<UpdatePlansForLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }
}
export {UpdatePlansForLeadRequest};

class NewSiteAssessmentRequest {
  site_id: string;
  mounts: Array<cwn_site_build.LocalMount>;
  from_site_assessment: ?cwn_site_assessment.SiteAssessment;

  constructor(props: $Shape<NewSiteAssessmentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.mounts = [];
    if (props.hasOwnProperty('mounts')) {
      const v = props.mounts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mounts should be array');
      }
      this.mounts = v.map(function(v) {
        return new cwn_site_build.LocalMount(v);
      });
    }

    this.from_site_assessment = null;
    if (props.hasOwnProperty('from_site_assessment')) {
      const v = props.from_site_assessment;
      this.from_site_assessment =
        v && new cwn_site_assessment.SiteAssessment(v);
    }
  }

  getFromSiteAssessment(): cwn_site_assessment.SiteAssessment {
    if (this.from_site_assessment) {
      return this.from_site_assessment;
    }
    return new cwn_site_assessment.SiteAssessment();
  }
}
export {NewSiteAssessmentRequest};

class GetSiteAssessmentReply {
  site_assessment: ?cwn_site_assessment.SiteAssessment;
  possible_builds: Array<cwn_site_assessment.PossibleBuild>;

  constructor(props: $Shape<GetSiteAssessmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_assessment = null;
    if (props.hasOwnProperty('site_assessment')) {
      const v = props.site_assessment;
      this.site_assessment = v && new cwn_site_assessment.SiteAssessment(v);
    }

    this.possible_builds = [];
    if (props.hasOwnProperty('possible_builds')) {
      const v = props.possible_builds;
      if (!Array.isArray(v)) {
        throw new Error('repeated field possible_builds should be array');
      }
      this.possible_builds = v.map(function(v) {
        return new cwn_site_assessment.PossibleBuild(v);
      });
    }
  }

  getSiteAssessment(): cwn_site_assessment.SiteAssessment {
    if (this.site_assessment) {
      return this.site_assessment;
    }
    return new cwn_site_assessment.SiteAssessment();
  }
}
export {GetSiteAssessmentReply};

class GetLOSCachesRequest {
  los_cache_ids: Array<string>;

  constructor(props: $Shape<GetLOSCachesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.los_cache_ids = [];
    if (props.hasOwnProperty('los_cache_ids')) {
      const v = props.los_cache_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field los_cache_ids should be array');
      }
      this.los_cache_ids = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetLOSCachesRequest};

class GetLOSCachesReply {
  los_caches: Array<cwn_lidar_los.Cache>;

  constructor(props: $Shape<GetLOSCachesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.los_caches = [];
    if (props.hasOwnProperty('los_caches')) {
      const v = props.los_caches;
      if (!Array.isArray(v)) {
        throw new Error('repeated field los_caches should be array');
      }
      this.los_caches = v.map(function(v) {
        return new cwn_lidar_los.Cache(v);
      });
    }
  }
}
export {GetLOSCachesReply};

class GetSiteServiceLevelRequest {
  site_id: string;
  site_assessment_id: string;

  constructor(props: $Shape<GetSiteServiceLevelRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.site_assessment_id = '0';
    if (props.hasOwnProperty('site_assessment_id')) {
      const v = props.site_assessment_id;
      this.site_assessment_id = v;
    }
  }
}
export {GetSiteServiceLevelRequest};

class GetBuildingPointsForSiteReply {
  building_points: Array<cwn_lidar.QsiBuildingPoint>;

  constructor(props: $Shape<GetBuildingPointsForSiteReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.building_points = [];
    if (props.hasOwnProperty('building_points')) {
      const v = props.building_points;
      if (!Array.isArray(v)) {
        throw new Error('repeated field building_points should be array');
      }
      this.building_points = v.map(function(v) {
        return new cwn_lidar.QsiBuildingPoint(v);
      });
    }
  }
}
export {GetBuildingPointsForSiteReply};

class NewShotOverrideRequest {
  mount_a: string;
  mount_b: string;
  has_line_of_sight: boolean;

  constructor(props: $Shape<NewShotOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_a = '0';
    if (props.hasOwnProperty('mount_a')) {
      const v = props.mount_a;
      this.mount_a = v;
    }

    this.mount_b = '0';
    if (props.hasOwnProperty('mount_b')) {
      const v = props.mount_b;
      this.mount_b = v;
    }

    this.has_line_of_sight = false;
    if (props.hasOwnProperty('has_line_of_sight')) {
      const v = props.has_line_of_sight;
      this.has_line_of_sight = !!v;
    }
  }
}
export {NewShotOverrideRequest};

class UpdateShotOverrideRequest {
  mount_a: string;
  mount_b: string;
  has_line_of_sight: boolean;

  constructor(props: $Shape<UpdateShotOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_a = '0';
    if (props.hasOwnProperty('mount_a')) {
      const v = props.mount_a;
      this.mount_a = v;
    }

    this.mount_b = '0';
    if (props.hasOwnProperty('mount_b')) {
      const v = props.mount_b;
      this.mount_b = v;
    }

    this.has_line_of_sight = false;
    if (props.hasOwnProperty('has_line_of_sight')) {
      const v = props.has_line_of_sight;
      this.has_line_of_sight = !!v;
    }
  }
}
export {UpdateShotOverrideRequest};

class DeleteShotOverrideRequest {
  mount_a: string;
  mount_b: string;

  constructor(props: $Shape<DeleteShotOverrideRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_a = '0';
    if (props.hasOwnProperty('mount_a')) {
      const v = props.mount_a;
      this.mount_a = v;
    }

    this.mount_b = '0';
    if (props.hasOwnProperty('mount_b')) {
      const v = props.mount_b;
      this.mount_b = v;
    }
  }
}
export {DeleteShotOverrideRequest};

class SiteBuildAndPlan {
  current: ?cwn_site_build.Build;
  plan: ?cwn_site_plan.Plan;
  plan_invalid: boolean;
  planned_work: Array<cwn_site_build.RequiredWork>;
  completed_work: Array<cwn_site_build_viewmodels.RequiredWorkViewModel>;
  remaining_work: Array<cwn_site_build_viewmodels.RequiredWorkViewModel>;
  unaccounted_work: Array<cwn_site_build.RequiredWork>;

  constructor(props: $Shape<SiteBuildAndPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.current = null;
    if (props.hasOwnProperty('current')) {
      const v = props.current;
      this.current = v && new cwn_site_build.Build(v);
    }

    this.plan = null;
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v && new cwn_site_plan.Plan(v);
    }

    this.plan_invalid = false;
    if (props.hasOwnProperty('plan_invalid')) {
      const v = props.plan_invalid;
      this.plan_invalid = !!v;
    }

    this.planned_work = [];
    if (props.hasOwnProperty('planned_work')) {
      const v = props.planned_work;
      if (!Array.isArray(v)) {
        throw new Error('repeated field planned_work should be array');
      }
      this.planned_work = v.map(function(v) {
        return new cwn_site_build.RequiredWork(v);
      });
    }

    this.completed_work = [];
    if (props.hasOwnProperty('completed_work')) {
      const v = props.completed_work;
      if (!Array.isArray(v)) {
        throw new Error('repeated field completed_work should be array');
      }
      this.completed_work = v.map(function(v) {
        return new cwn_site_build_viewmodels.RequiredWorkViewModel(v);
      });
    }

    this.remaining_work = [];
    if (props.hasOwnProperty('remaining_work')) {
      const v = props.remaining_work;
      if (!Array.isArray(v)) {
        throw new Error('repeated field remaining_work should be array');
      }
      this.remaining_work = v.map(function(v) {
        return new cwn_site_build_viewmodels.RequiredWorkViewModel(v);
      });
    }

    this.unaccounted_work = [];
    if (props.hasOwnProperty('unaccounted_work')) {
      const v = props.unaccounted_work;
      if (!Array.isArray(v)) {
        throw new Error('repeated field unaccounted_work should be array');
      }
      this.unaccounted_work = v.map(function(v) {
        return new cwn_site_build.RequiredWork(v);
      });
    }
  }

  getCurrent(): cwn_site_build.Build {
    if (this.current) {
      return this.current;
    }
    return new cwn_site_build.Build();
  }

  getPlan(): cwn_site_plan.Plan {
    if (this.plan) {
      return this.plan;
    }
    return new cwn_site_plan.Plan();
  }
}
export {SiteBuildAndPlan};

class CreateSitePlanRequest {
  current: ?cwn_site_build.Build;
  next: ?cwn_site_build.Build;

  constructor(props: $Shape<CreateSitePlanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.current = null;
    if (props.hasOwnProperty('current')) {
      const v = props.current;
      this.current = v && new cwn_site_build.Build(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new cwn_site_build.Build(v);
    }
  }

  getCurrent(): cwn_site_build.Build {
    if (this.current) {
      return this.current;
    }
    return new cwn_site_build.Build();
  }

  getNext(): cwn_site_build.Build {
    if (this.next) {
      return this.next;
    }
    return new cwn_site_build.Build();
  }
}
export {CreateSitePlanRequest};

class UpdateSitePlanRequest {
  current: ?cwn_site_build.Build;
  next: ?cwn_site_build.Build;

  constructor(props: $Shape<UpdateSitePlanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.current = null;
    if (props.hasOwnProperty('current')) {
      const v = props.current;
      this.current = v && new cwn_site_build.Build(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new cwn_site_build.Build(v);
    }
  }

  getCurrent(): cwn_site_build.Build {
    if (this.current) {
      return this.current;
    }
    return new cwn_site_build.Build();
  }

  getNext(): cwn_site_build.Build {
    if (this.next) {
      return this.next;
    }
    return new cwn_site_build.Build();
  }
}
export {UpdateSitePlanRequest};

class RebaseSitePlanRequest {
  current: ?cwn_site_build.Build;

  constructor(props: $Shape<RebaseSitePlanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.current = null;
    if (props.hasOwnProperty('current')) {
      const v = props.current;
      this.current = v && new cwn_site_build.Build(v);
    }
  }

  getCurrent(): cwn_site_build.Build {
    if (this.current) {
      return this.current;
    }
    return new cwn_site_build.Build();
  }
}
export {RebaseSitePlanRequest};

class ApplyRequiredWorkRequest {
  current: ?cwn_site_build.Build;
  required_work: ?cwn_site_build.RequiredWork;

  constructor(props: $Shape<ApplyRequiredWorkRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.current = null;
    if (props.hasOwnProperty('current')) {
      const v = props.current;
      this.current = v && new cwn_site_build.Build(v);
    }

    this.required_work = null;
    if (props.hasOwnProperty('required_work')) {
      const v = props.required_work;
      this.required_work = v && new cwn_site_build.RequiredWork(v);
    }
  }

  getCurrent(): cwn_site_build.Build {
    if (this.current) {
      return this.current;
    }
    return new cwn_site_build.Build();
  }

  getRequiredWork(): cwn_site_build.RequiredWork {
    if (this.required_work) {
      return this.required_work;
    }
    return new cwn_site_build.RequiredWork();
  }
}
export {ApplyRequiredWorkRequest};

class AdminService {
  baseURL: string;
  additionalHeaders: Array<Array<string>>;
  headersProvider: ?() => Array<Array<string>>;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.additionalHeaders = [];
  }

  async call<Req, Res>(
    method: string,
    req: Req,
    ResClass: Class<Res>,
  ): Promise<Res> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.additionalHeaders.forEach(function(h) {
      headers.append(h[0], h[1]);
    });

    if (this.headersProvider) {
      this.headersProvider().forEach(h => {
        headers.append(h[0], h[1]);
      });
    }

    const credentials =
      process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';

    let res: Response;
    let resText: string;
    try {
      res = await fetch(this.baseURL + '/cwn.admin.AdminService/' + method, {
        credentials,
        method: 'POST',
        headers: headers,
        body: JSON.stringify(req),
      });
      resText = await res.text();
    } catch (e) {
      if (e instanceof TypeError) {
        throw new Error(
          'Could not connect to server (reason: ' +
            e.message +
            ') Check your Internet connection and try again.',
        );
      } else {
        throw e;
      }
    }

    if (res.ok) {
      try {
        const json = JSON.parse(resText);
        // https://flow.org/en/docs/types/utilities/#toc-class
        // $FlowFixMe this will work in a future version of flow
        return new ResClass(json);
      } catch (e) {
        throw new Error(
          'Could not parse response for ' +
            method +
            ': ' +
            e.message +
            ' payload: ' +
            resText,
        );
      }
    }

    let errMessage: string;
    try {
      const errJSON = JSON.parse(resText);
      if (errJSON.code && errJSON.message) {
        // RPCError
        errMessage = errJSON.code + ': ' + errJSON.message;
      } else {
        errMessage = JSON.stringify(errJSON, null, 2);
      }
    } catch (e) {
      errMessage = resText;
    }
    throw new Error(
      'Request for ' +
        method +
        ' failed: ' +
        res.status +
        ' ' +
        res.statusText +
        ' ' +
        errMessage,
    );
  }

  addHeader(name: string, value: string) {
    this.additionalHeaders.push([name, value]);
  }

  async GetUser(req: GetUserRequest): Promise<GetUserReply> {
    return await this.call('GetUser', req, GetUserReply);
  }
  async Search(req: SearchRequest): Promise<SearchReply> {
    return await this.call('Search', req, SearchReply);
  }
  async GetInforadV2(req: GetInforadV2Request): Promise<GetInforadV2Reply> {
    return await this.call('GetInforadV2', req, GetInforadV2Reply);
  }
  async GetGrass(req: GetGrassRequest): Promise<GetGrassReply> {
    return await this.call('GetGrass', req, GetGrassReply);
  }
  async ListDevices(req: ListDevicesRequest): Promise<ListDevicesReply> {
    return await this.call('ListDevices', req, ListDevicesReply);
  }
  async ListDeviceSummaries(
    req: ListDeviceSummariesRequest,
  ): Promise<ListDeviceSummariesReply> {
    return await this.call(
      'ListDeviceSummaries',
      req,
      ListDeviceSummariesReply,
    );
  }
  async GetDevice(req: GetDeviceRequest): Promise<GetDeviceReply> {
    return await this.call('GetDevice', req, GetDeviceReply);
  }
  async GetSpeedTests(req: GetSpeedTestsRequest): Promise<GetSpeedTestsReply> {
    return await this.call('GetSpeedTests', req, GetSpeedTestsReply);
  }
  async GetTimelineSpeedTests(
    req: GetSpeedTestsRequest,
  ): Promise<GetSpeedTestsReply> {
    return await this.call('GetTimelineSpeedTests', req, GetSpeedTestsReply);
  }
  async PutDeviceLogLevel(
    req: PutDeviceLogLevelRequest,
  ): Promise<PutDeviceLogLevelReply> {
    return await this.call('PutDeviceLogLevel', req, PutDeviceLogLevelReply);
  }
  async ClearDeviceLogLevel(
    req: ClearDeviceLogLevelRequest,
  ): Promise<ClearDeviceLogLevelReply> {
    return await this.call(
      'ClearDeviceLogLevel',
      req,
      ClearDeviceLogLevelReply,
    );
  }
  async GetStatusContainer(
    req: GetStatusContainerRequest,
  ): Promise<GetStatusContainerReply> {
    return await this.call('GetStatusContainer', req, GetStatusContainerReply);
  }
  async GetDeviceNetworkStatus(
    req: GetDeviceNetworkStatusRequest,
  ): Promise<GetDeviceNetworkStatusReply> {
    return await this.call(
      'GetDeviceNetworkStatus',
      req,
      GetDeviceNetworkStatusReply,
    );
  }
  async RestartAvailabilityHistoryForDevice(
    req: cwn_rpc.StringId,
  ): Promise<EmptyMessage> {
    return await this.call(
      'RestartAvailabilityHistoryForDevice',
      req,
      EmptyMessage,
    );
  }
  async ListBoots(req: ListBootsRequest): Promise<ListBootsReply> {
    return await this.call('ListBoots', req, ListBootsReply);
  }
  async GetBoot(req: GetBootRequest): Promise<GetBootReply> {
    return await this.call('GetBoot', req, GetBootReply);
  }
  async ListRadioGroups(
    req: ListRadioGroupsRequest,
  ): Promise<ListRadioGroupsReply> {
    return await this.call('ListRadioGroups', req, ListRadioGroupsReply);
  }
  async ListJoinableRadioGroups(
    req: ListJoinableRadioGroupsRequest,
  ): Promise<ListJoinableRadioGroupsReply> {
    return await this.call(
      'ListJoinableRadioGroups',
      req,
      ListJoinableRadioGroupsReply,
    );
  }
  async GetRadioGroup(req: GetRadioGroupRequest): Promise<GetRadioGroupReply> {
    return await this.call('GetRadioGroup', req, GetRadioGroupReply);
  }
  async PutRadioGroup(req: PutRadioGroupRequest): Promise<PutRadioGroupReply> {
    return await this.call('PutRadioGroup', req, PutRadioGroupReply);
  }
  async DeleteEmptyRadioGroup(
    req: DeleteEmptyRadioGroupRequest,
  ): Promise<DeleteEmptyRadioGroupReply> {
    return await this.call(
      'DeleteEmptyRadioGroup',
      req,
      DeleteEmptyRadioGroupReply,
    );
  }
  async SearchRadioGroups(
    req: SearchRadioGroupsRequest,
  ): Promise<SearchRadioGroupsReply> {
    return await this.call('SearchRadioGroups', req, SearchRadioGroupsReply);
  }
  async IsRadioGroupChannelLocked(
    req: cwn_rpc.Id,
  ): Promise<IsRadioGroupChannelLockedReply> {
    return await this.call(
      'IsRadioGroupChannelLocked',
      req,
      IsRadioGroupChannelLockedReply,
    );
  }
  async LockRadioGroupChannel(
    req: LockRadioGroupChannelRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('LockRadioGroupChannel', req, cwn_rpc.Empty);
  }
  async UnlockRadioGroupChannel(
    req: LockRadioGroupChannelRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('UnlockRadioGroupChannel', req, cwn_rpc.Empty);
  }
  async GetCustomer(req: GetCustomerRequest): Promise<GetCustomerReply> {
    return await this.call('GetCustomer', req, GetCustomerReply);
  }
  async GetCustomerBatch(
    req: GetCustomerBatchRequest,
  ): Promise<GetCustomerBatchReply> {
    return await this.call('GetCustomerBatch', req, GetCustomerBatchReply);
  }
  async PutCustomer(req: PutCustomerRequest): Promise<PutCustomerReply> {
    return await this.call('PutCustomer', req, PutCustomerReply);
  }
  async ListCustomers(req: ListCustomersRequest): Promise<ListCustomersReply> {
    return await this.call('ListCustomers', req, ListCustomersReply);
  }
  async GetSubscription(
    req: GetSubscriptionRequest,
  ): Promise<GetSubscriptionReply> {
    return await this.call('GetSubscription', req, GetSubscriptionReply);
  }
  async GetSubscriptionBatch(
    req: GetSubscriptionBatchRequest,
  ): Promise<GetSubscriptionBatchReply> {
    return await this.call(
      'GetSubscriptionBatch',
      req,
      GetSubscriptionBatchReply,
    );
  }
  async PostSubscription(
    req: PostSubscriptionRequest,
  ): Promise<PostSubscriptionReply> {
    return await this.call('PostSubscription', req, PostSubscriptionReply);
  }
  async PutSubscription(
    req: PutSubscriptionRequest,
  ): Promise<PutSubscriptionReply> {
    return await this.call('PutSubscription', req, PutSubscriptionReply);
  }
  async SearchSubscriptions(
    req: SearchSubscriptionsRequest,
  ): Promise<SearchSubscriptionsReply> {
    return await this.call(
      'SearchSubscriptions',
      req,
      SearchSubscriptionsReply,
    );
  }
  async GetHansForSubscription(
    req: GetHansForSubscriptionRequest,
  ): Promise<GetHansForSubscriptionsReply> {
    return await this.call(
      'GetHansForSubscription',
      req,
      GetHansForSubscriptionsReply,
    );
  }
  async CreateHan(req: CreateHanRequest): Promise<CreateHanReply> {
    return await this.call('CreateHan', req, CreateHanReply);
  }
  async UpdateHan(req: UpdateHanRequest): Promise<UpdateHanReply> {
    return await this.call('UpdateHan', req, UpdateHanReply);
  }
  async UpdateDeclineAp(req: UpdateDeclineApRequest): Promise<EmptyMessage> {
    return await this.call('UpdateDeclineAp', req, EmptyMessage);
  }
  async GetLastUnifiStatuses(
    req: GetLastUnifiStatusesRequest,
  ): Promise<GetLastUnifiStatusesReply> {
    return await this.call(
      'GetLastUnifiStatuses',
      req,
      GetLastUnifiStatusesReply,
    );
  }
  async RebootUnifi(req: RebootUnifiRequest): Promise<RebootUnifiReply> {
    return await this.call('RebootUnifi', req, RebootUnifiReply);
  }
  async ListPackagesV2(
    req: ListPackagesV2Request,
  ): Promise<ListPackagesV2Reply> {
    return await this.call('ListPackagesV2', req, ListPackagesV2Reply);
  }
  async GetPackage(req: GetPackageRequest): Promise<GetPackageReply> {
    return await this.call('GetPackage', req, GetPackageReply);
  }
  async CreatePackage(req: CreatePackageRequest): Promise<CreatePackageReply> {
    return await this.call('CreatePackage', req, CreatePackageReply);
  }
  async CreateVersion(req: CreateVersionRequest): Promise<CreateVersionReply> {
    return await this.call('CreateVersion', req, CreateVersionReply);
  }
  async PutDefaultArchive(
    req: PutDefaultArchiveRequest,
  ): Promise<PutDefaultArchiveReply> {
    return await this.call('PutDefaultArchive', req, PutDefaultArchiveReply);
  }
  async GetRadio(req: GetRadioRequest): Promise<GetRadioReply> {
    return await this.call('GetRadio', req, GetRadioReply);
  }
  async GetAction(req: GetActionRequest): Promise<GetActionReply> {
    return await this.call('GetAction', req, GetActionReply);
  }
  async PutAction(req: PutActionRequest): Promise<PutActionReply> {
    return await this.call('PutAction', req, PutActionReply);
  }
  async ListRoutingTables(
    req: ListRoutingTablesRequest,
  ): Promise<ListRoutingTablesReply> {
    return await this.call('ListRoutingTables', req, ListRoutingTablesReply);
  }
  async GetRoutingTable(
    req: GetRoutingTableRequest,
  ): Promise<GetRoutingTableReply> {
    return await this.call('GetRoutingTable', req, GetRoutingTableReply);
  }
  async GetCurrentRoutingTable(
    req: GetCurrentRoutingTableRequest,
  ): Promise<GetCurrentRoutingTableReply> {
    return await this.call(
      'GetCurrentRoutingTable',
      req,
      GetCurrentRoutingTableReply,
    );
  }
  async ApplyRoutingTable(
    req: ApplyRoutingTableRequest,
  ): Promise<ApplyRoutingTableReply> {
    return await this.call('ApplyRoutingTable', req, ApplyRoutingTableReply);
  }
  async ListRouteHopsForDestinationDeviceID(
    req: ListRouteHopsForDestinationDeviceIDRequest,
  ): Promise<ListRouteHopsForDestinationDeviceIDReply> {
    return await this.call(
      'ListRouteHopsForDestinationDeviceID',
      req,
      ListRouteHopsForDestinationDeviceIDReply,
    );
  }
  async ListAppliedRoutesForDestinationDeviceIDBetween(
    req: ListAppliedRoutesForDestinationDeviceIDBetweenRequest,
  ): Promise<ListAppliedRoutesForDestinationDeviceIDBetweenReply> {
    return await this.call(
      'ListAppliedRoutesForDestinationDeviceIDBetween',
      req,
      ListAppliedRoutesForDestinationDeviceIDBetweenReply,
    );
  }
  async GetNetwork(req: GetNetworkRequest): Promise<GetNetworkReply> {
    return await this.call('GetNetwork', req, GetNetworkReply);
  }
  async PutEdgeWeightOverride(
    req: PutEdgeWeightOverrideRequest,
  ): Promise<PutEdgeWeightOverrideReply> {
    return await this.call(
      'PutEdgeWeightOverride',
      req,
      PutEdgeWeightOverrideReply,
    );
  }
  async DeleteEdgeWeightOverride(
    req: DeleteEdgeWeightOverrideRequest,
  ): Promise<DeleteEdgeWeightOverrideReply> {
    return await this.call(
      'DeleteEdgeWeightOverride',
      req,
      DeleteEdgeWeightOverrideReply,
    );
  }
  async ListEdgeWeightOverrides(
    req: ListEdgeWeightOverridesRequest,
  ): Promise<ListEdgeWeightOverridesReply> {
    return await this.call(
      'ListEdgeWeightOverrides',
      req,
      ListEdgeWeightOverridesReply,
    );
  }
  async GetSiteRadioLinkDowngraded(
    req: GetSiteRadioLinkDowngradedRequest,
  ): Promise<GetSiteRadioLinkDowngradedReply> {
    return await this.call(
      'GetSiteRadioLinkDowngraded',
      req,
      GetSiteRadioLinkDowngradedReply,
    );
  }
  async EnableMaintenanceMode(
    req: EnableMaintenanceModeRequest,
  ): Promise<EnableMaintenanceModeReply> {
    return await this.call(
      'EnableMaintenanceMode',
      req,
      EnableMaintenanceModeReply,
    );
  }
  async DisableMaintenanceMode(
    req: DisableMaintenanceModeRequest,
  ): Promise<DisableMaintenanceModeReply> {
    return await this.call(
      'DisableMaintenanceMode',
      req,
      DisableMaintenanceModeReply,
    );
  }
  async GetMaintenanceMode(
    req: GetMaintenanceModeRequest,
  ): Promise<GetMaintenanceModeReply> {
    return await this.call('GetMaintenanceMode', req, GetMaintenanceModeReply);
  }
  async ListProspectiveCustomers(
    req: ListProspectiveCustomersRequest,
  ): Promise<ListProspectiveCustomersReply> {
    return await this.call(
      'ListProspectiveCustomers',
      req,
      ListProspectiveCustomersReply,
    );
  }
  async PostComment(req: PostCommentRequest): Promise<PostCommentReply> {
    return await this.call('PostComment', req, PostCommentReply);
  }
  async ListPossibleChannels(
    req: ListPossibleChannelsRequest,
  ): Promise<ListPossibleChannelsReply> {
    return await this.call(
      'ListPossibleChannels',
      req,
      ListPossibleChannelsReply,
    );
  }
  async ListPossibleRadioConfigurations(
    req: ListPossibleRadioConfigurationsRequest,
  ): Promise<ListPossibleRadioConfigurationsReply> {
    return await this.call(
      'ListPossibleRadioConfigurations',
      req,
      ListPossibleRadioConfigurationsReply,
    );
  }
  async ListAvailableChannelsForSites(
    req: ListAvailableChannelsForSitesRequest,
  ): Promise<ListAvailableChannelsForSitesReply> {
    return await this.call(
      'ListAvailableChannelsForSites',
      req,
      ListAvailableChannelsForSitesReply,
    );
  }
  async GenerateSignupLinkV2(
    req: GenerateSignupLinkV2Request,
  ): Promise<GenerateSignupLinkV2Reply> {
    return await this.call(
      'GenerateSignupLinkV2',
      req,
      GenerateSignupLinkV2Reply,
    );
  }
  async ListRadioStatuses(
    req: ListRadioStatusesRequest,
  ): Promise<ListRadioStatusesReply> {
    return await this.call('ListRadioStatuses', req, ListRadioStatusesReply);
  }
  async ListRadioStatusesSince(
    req: ListRadioStatusesSinceRequest,
  ): Promise<ListRadioStatusesSinceReply> {
    return await this.call(
      'ListRadioStatusesSince',
      req,
      ListRadioStatusesSinceReply,
    );
  }
  async ListInvoices(req: ListInvoicesRequest): Promise<ListInvoicesReply> {
    return await this.call('ListInvoices', req, ListInvoicesReply);
  }
  async SearchInvoices(
    req: SearchInvoicesRequest,
  ): Promise<SearchInvoicesReply> {
    return await this.call('SearchInvoices', req, SearchInvoicesReply);
  }
  async GetInvoice(req: GetInvoiceRequest): Promise<GetInvoiceReply> {
    return await this.call('GetInvoice', req, GetInvoiceReply);
  }
  async GetInvoicesForSubscription(
    req: GetInvoicesForSubscriptionRequest,
  ): Promise<GetInvoicesForSubscriptionReply> {
    return await this.call(
      'GetInvoicesForSubscription',
      req,
      GetInvoicesForSubscriptionReply,
    );
  }
  async GetActiveLedger(
    req: GetActiveLedgerRequest,
  ): Promise<GetActiveLedgerResponse> {
    return await this.call('GetActiveLedger', req, GetActiveLedgerResponse);
  }
  async ResendReceiptEmail(
    req: ResendReceiptEmailRequest,
  ): Promise<ResendReceiptEmailReply> {
    return await this.call('ResendReceiptEmail', req, ResendReceiptEmailReply);
  }
  async AddCoupon(req: AddCouponRequest): Promise<AddCouponReply> {
    return await this.call('AddCoupon', req, AddCouponReply);
  }
  async DeleteCoupon(req: DeleteCouponRequest): Promise<DeleteCouponReply> {
    return await this.call('DeleteCoupon', req, DeleteCouponReply);
  }
  async AddLedgerEntry(req: AddLedgerEntryRequest): Promise<EmptyMessage> {
    return await this.call('AddLedgerEntry', req, EmptyMessage);
  }
  async PostArea(req: PostAreaRequest): Promise<PostAreaReply> {
    return await this.call('PostArea', req, PostAreaReply);
  }
  async PutArea(req: PutAreaRequest): Promise<PutAreaReply> {
    return await this.call('PutArea', req, PutAreaReply);
  }
  async ListAreas(req: ListAreasRequest): Promise<ListAreasReply> {
    return await this.call('ListAreas', req, ListAreasReply);
  }
  async GetArea(req: GetAreaRequest): Promise<GetAreaReply> {
    return await this.call('GetArea', req, GetAreaReply);
  }
  async ScheduleSpeedTest(
    req: ScheduleSpeedTestRequest,
  ): Promise<ScheduleSpeedTestReply> {
    return await this.call('ScheduleSpeedTest', req, ScheduleSpeedTestReply);
  }
  async GetDependentDownstreamDevices(
    req: GetDependentDownstreamDevicesRequest,
  ): Promise<GetDependentDownstreamDevicesReply> {
    return await this.call(
      'GetDependentDownstreamDevices',
      req,
      GetDependentDownstreamDevicesReply,
    );
  }
  async PutOffer(req: PutOfferRequest): Promise<PutOfferReply> {
    return await this.call('PutOffer', req, PutOfferReply);
  }
  async ListOffers(req: ListOffersRequest): Promise<ListOffersReply> {
    return await this.call('ListOffers', req, ListOffersReply);
  }
  async DeleteOffer(req: DeleteOfferRequest): Promise<DeleteOfferReply> {
    return await this.call('DeleteOffer', req, DeleteOfferReply);
  }
  async OverrideOffer(req: OverrideOfferRequest): Promise<OverrideOfferReply> {
    return await this.call('OverrideOffer', req, OverrideOfferReply);
  }
  async PostInstrument(req: PostInstrumentRequest): Promise<EmptyMessage> {
    return await this.call('PostInstrument', req, EmptyMessage);
  }
  async ChargeLedger(req: ChargeLedgerRequest): Promise<EmptyMessage> {
    return await this.call('ChargeLedger', req, EmptyMessage);
  }
  async GetInstrument(req: GetInstrumentRequest): Promise<GetInstrumentReply> {
    return await this.call('GetInstrument', req, GetInstrumentReply);
  }
  async CreateSite(req: CreateSiteRequest): Promise<CreateSiteReply> {
    return await this.call('CreateSite', req, CreateSiteReply);
  }
  async GetSite(req: GetSiteRequest): Promise<GetSiteReply> {
    return await this.call('GetSite', req, GetSiteReply);
  }
  async GetSites(req: GetSitesRequest): Promise<GetSitesReply> {
    return await this.call('GetSites', req, GetSitesReply);
  }
  async GetSiteBatch(req: GetSiteBatchRequest): Promise<GetSiteBatchReply> {
    return await this.call('GetSiteBatch', req, GetSiteBatchReply);
  }
  async GetSiteFromDeviceIDBatch(
    req: GetSiteFromDeviceIDBatchRequest,
  ): Promise<GetSiteFromDeviceIDBatchReply> {
    return await this.call(
      'GetSiteFromDeviceIDBatch',
      req,
      GetSiteFromDeviceIDBatchReply,
    );
  }
  async GetSiteTargetSummary(
    req: GetSiteTargetSummaryRequest,
  ): Promise<GetSiteTargetSummaryReply> {
    return await this.call(
      'GetSiteTargetSummary',
      req,
      GetSiteTargetSummaryReply,
    );
  }
  async GetSiteRadioLinkBatch(
    req: GetSiteRadioLinkBatchRequest,
  ): Promise<GetSiteRadioLinkBatchReply> {
    return await this.call(
      'GetSiteRadioLinkBatch',
      req,
      GetSiteRadioLinkBatchReply,
    );
  }
  async ListStaMacAddressesForArea(
    req: ListStaMacAddressesForAreaRequest,
  ): Promise<ListStaMacAddressesForAreaReply> {
    return await this.call(
      'ListStaMacAddressesForArea',
      req,
      ListStaMacAddressesForAreaReply,
    );
  }
  async GetSiteEdgeWeightOverrides(
    req: GetSiteEdgeWeightOverridesRequest,
  ): Promise<GetSiteEdgeWeightOverridesResponse> {
    return await this.call(
      'GetSiteEdgeWeightOverrides',
      req,
      GetSiteEdgeWeightOverridesResponse,
    );
  }
  async GetLatestSiteRadioStatus(
    req: GetLatestSiteRadioStatusRequest,
  ): Promise<GetLatestSiteRadioStatusResponse> {
    return await this.call(
      'GetLatestSiteRadioStatus',
      req,
      GetLatestSiteRadioStatusResponse,
    );
  }
  async AssignSiteDevice(
    req: AssignSiteDeviceRequest,
  ): Promise<AssignSiteDeviceReply> {
    return await this.call('AssignSiteDevice', req, AssignSiteDeviceReply);
  }
  async AddSiteSubscription(
    req: AddSiteSubscriptionRequest,
  ): Promise<AddSiteSubscriptionReply> {
    return await this.call(
      'AddSiteSubscription',
      req,
      AddSiteSubscriptionReply,
    );
  }
  async RemoveSiteSubscription(
    req: RemoveSiteSubscriptionRequest,
  ): Promise<RemoveSiteSubscriptionReply> {
    return await this.call(
      'RemoveSiteSubscription',
      req,
      RemoveSiteSubscriptionReply,
    );
  }
  async ListSites(req: ListSitesRequest): Promise<ListSitesReply> {
    return await this.call('ListSites', req, ListSitesReply);
  }
  async ListSitesWithCustomers(
    req: ListSitesWithCustomersRequest,
  ): Promise<ListSitesWithCustomersReply> {
    return await this.call(
      'ListSitesWithCustomers',
      req,
      ListSitesWithCustomersReply,
    );
  }
  async ListLeadCustomerSubscriptionsForSite(
    req: ListLeadCustomerSubscriptionsForSiteRequest,
  ): Promise<ListLeadCustomerSubscriptionsForSiteReply> {
    return await this.call(
      'ListLeadCustomerSubscriptionsForSite',
      req,
      ListLeadCustomerSubscriptionsForSiteReply,
    );
  }
  async ListSiteRadios(
    req: ListSiteRadiosRequest,
  ): Promise<ListSiteRadiosReply> {
    return await this.call('ListSiteRadios', req, ListSiteRadiosReply);
  }
  async ListSiteBridges(
    req: ListSiteBridgesRequest,
  ): Promise<ListSiteBridgesReply> {
    return await this.call('ListSiteBridges', req, ListSiteBridgesReply);
  }
  async ListSiteMocas(req: ListSiteMocasRequest): Promise<ListSiteMocasReply> {
    return await this.call('ListSiteMocas', req, ListSiteMocasReply);
  }
  async ListSiteRadioLinksForRadioGroupBatch(
    req: ListSiteRadioLinksForRadioGroupBatchRequest,
  ): Promise<ListSiteRadioLinksForRadioGroupBatchReply> {
    return await this.call(
      'ListSiteRadioLinksForRadioGroupBatch',
      req,
      ListSiteRadioLinksForRadioGroupBatchReply,
    );
  }
  async ListSiteRadioLinksForSite(
    req: ListSiteRadioLinksForSiteRequest,
  ): Promise<ListSiteRadioLinksReply> {
    return await this.call(
      'ListSiteRadioLinksForSite',
      req,
      ListSiteRadioLinksReply,
    );
  }
  async ListSiteRadioLinksForMount(
    req: cwn_rpc.Id,
  ): Promise<ListSiteRadioLinksReply> {
    return await this.call(
      'ListSiteRadioLinksForMount',
      req,
      ListSiteRadioLinksReply,
    );
  }
  async ListLiveSiteRadioLinksForSite(
    req: ListLiveSiteRadioLinksForSiteRequest,
  ): Promise<ListLiveSiteRadioLinksForSiteReply> {
    return await this.call(
      'ListLiveSiteRadioLinksForSite',
      req,
      ListLiveSiteRadioLinksForSiteReply,
    );
  }
  async ListActiveShotsForSite(
    req: ListActiveShotsForSiteRequest,
  ): Promise<ListActiveShotsForSiteReply> {
    return await this.call(
      'ListActiveShotsForSite',
      req,
      ListActiveShotsForSiteReply,
    );
  }
  async PutSiteRadioLink(
    req: PutSiteRadioLinkRequest,
  ): Promise<PutSiteRadioLinkReply> {
    return await this.call('PutSiteRadioLink', req, PutSiteRadioLinkReply);
  }
  async DeleteSiteRadioLink(
    req: DeleteSiteRadioLinkRequest,
  ): Promise<DeleteSiteRadioLinkReply> {
    return await this.call(
      'DeleteSiteRadioLink',
      req,
      DeleteSiteRadioLinkReply,
    );
  }
  async GetSiteBridgeConfigs(
    req: GetSiteBridgeConfigsRequest,
  ): Promise<GetSiteBridgeConfigsReply> {
    return await this.call(
      'GetSiteBridgeConfigs',
      req,
      GetSiteBridgeConfigsReply,
    );
  }
  async PutSiteBridgeConfig(
    req: PutSiteBridgeConfigRequest,
  ): Promise<PutSiteBridgeConfigReply> {
    return await this.call(
      'PutSiteBridgeConfig',
      req,
      PutSiteBridgeConfigReply,
    );
  }
  async DeleteSiteBridgeConfig(
    req: DeleteSiteBridgeConfigRequest,
  ): Promise<DeleteSiteBridgeConfigReply> {
    return await this.call(
      'DeleteSiteBridgeConfig',
      req,
      DeleteSiteBridgeConfigReply,
    );
  }
  async GetSiteRadioLinkStatusForMac(
    req: GetSiteRadioLinkStatusForMacRequest,
  ): Promise<GetSiteRadioLinkStatusForMacReply> {
    return await this.call(
      'GetSiteRadioLinkStatusForMac',
      req,
      GetSiteRadioLinkStatusForMacReply,
    );
  }
  async SetSiteDetails(
    req: SetSiteDetailsRequest,
  ): Promise<SetSiteDetailsReply> {
    return await this.call('SetSiteDetails', req, SetSiteDetailsReply);
  }
  async GetSRLForSitePair(
    req: GetSRLForSitePairRequest,
  ): Promise<GetSRLForSitePairReply> {
    return await this.call('GetSRLForSitePair', req, GetSRLForSitePairReply);
  }
  async ListNextAuditRecordBatch(
    req: ListNextAuditRecordBatchRequest,
  ): Promise<ListNextAuditRecordBatchReply> {
    return await this.call(
      'ListNextAuditRecordBatch',
      req,
      ListNextAuditRecordBatchReply,
    );
  }
  async AddAuditNote(req: AddAuditNoteRequest): Promise<AddAuditNoteReply> {
    return await this.call('AddAuditNote', req, AddAuditNoteReply);
  }
  async GetSiteEquipment(
    req: GetSiteEquipmentRequest,
  ): Promise<GetSiteEquipmentReply> {
    return await this.call('GetSiteEquipment', req, GetSiteEquipmentReply);
  }
  async PutSiteEquipment(
    req: PutSiteEquipmentRequest,
  ): Promise<PutSiteEquipmentReply> {
    return await this.call('PutSiteEquipment', req, PutSiteEquipmentReply);
  }
  async GetSiteCustomerName(
    req: GetSiteCustomerNameRequest,
  ): Promise<GetSiteCustomerNameReply> {
    return await this.call(
      'GetSiteCustomerName',
      req,
      GetSiteCustomerNameReply,
    );
  }
  async SetSiteStatus(req: SetSiteStatusRequest): Promise<SetSiteStatusReply> {
    return await this.call('SetSiteStatus', req, SetSiteStatusReply);
  }
  async GetSiteBuildingDescription(
    req: GetSiteBuildingDescriptionRequest,
  ): Promise<GetSiteBuildingDescriptionReply> {
    return await this.call(
      'GetSiteBuildingDescription',
      req,
      GetSiteBuildingDescriptionReply,
    );
  }
  async SetSiteBuildingDescription(
    req: SetSiteBuildingDescriptionRequest,
  ): Promise<SetSiteBuildingDescriptionReply> {
    return await this.call(
      'SetSiteBuildingDescription',
      req,
      SetSiteBuildingDescriptionReply,
    );
  }
  async SetSitePartnerInfo(
    req: SetSitePartnerInfoRequest,
  ): Promise<SetSitePartnerInfoReply> {
    return await this.call('SetSitePartnerInfo', req, SetSitePartnerInfoReply);
  }
  async UninstallSite(req: cwn_rpc.StringId): Promise<UninstallSiteReply> {
    return await this.call('UninstallSite', req, UninstallSiteReply);
  }
  async GetNetworkPlan(
    req: GetNetworkPlanRequest,
  ): Promise<GetNetworkPlanReply> {
    return await this.call('GetNetworkPlan', req, GetNetworkPlanReply);
  }
  async GenNetworkPlanTicket(
    req: GenNetworkPlanTicketRequest,
  ): Promise<GenNetworkPlanTicketReply> {
    return await this.call(
      'GenNetworkPlanTicket',
      req,
      GenNetworkPlanTicketReply,
    );
  }
  async DispatchRadioAdd(
    req: DispatchRadioAddRequest,
  ): Promise<DispatchRadioAddReply> {
    return await this.call('DispatchRadioAdd', req, DispatchRadioAddReply);
  }
  async GetPlanEligibilityOverride(
    req: GetPlanEligibilityOverrideRequest,
  ): Promise<GetPlanEligibilityOverrideReply> {
    return await this.call(
      'GetPlanEligibilityOverride',
      req,
      GetPlanEligibilityOverrideReply,
    );
  }
  async SetPlanEligibilityOverride(
    req: SetPlanEligibilityOverrideRequest,
  ): Promise<SetPlanEligibilityOverrideReply> {
    return await this.call(
      'SetPlanEligibilityOverride',
      req,
      SetPlanEligibilityOverrideReply,
    );
  }
  async ListEligiblePlansBeforeOverride(
    req: ListEligiblePlansBeforeOverrideRequest,
  ): Promise<ListEligiblePlansBeforeOverrideReply> {
    return await this.call(
      'ListEligiblePlansBeforeOverride',
      req,
      ListEligiblePlansBeforeOverrideReply,
    );
  }
  async SearchSites(req: SearchSitesRequest): Promise<SearchSitesReply> {
    return await this.call('SearchSites', req, SearchSitesReply);
  }
  async SearchDevices(req: SearchDevicesRequest): Promise<SearchDevicesReply> {
    return await this.call('SearchDevices', req, SearchDevicesReply);
  }
  async CreateMount(req: CreateMountRequest): Promise<CreateMountReply> {
    return await this.call('CreateMount', req, CreateMountReply);
  }
  async ListMountsForSite(
    req: ListMountsForSiteRequest,
  ): Promise<ListMountsForSiteReply> {
    return await this.call('ListMountsForSite', req, ListMountsForSiteReply);
  }
  async UpdateMount(req: UpdateMountRequest): Promise<UpdateMountReply> {
    return await this.call('UpdateMount', req, UpdateMountReply);
  }
  async UpdateMountLocation(
    req: UpdateMountLocationRequest,
  ): Promise<cwn_geo.Mount> {
    return await this.call('UpdateMountLocation', req, cwn_geo.Mount);
  }
  async DeleteMount(req: DeleteMountRequest): Promise<DeleteMountReply> {
    return await this.call('DeleteMount', req, DeleteMountReply);
  }
  async GetMounts(req: GetMountsRequest): Promise<GetMountsReply> {
    return await this.call('GetMounts', req, GetMountsReply);
  }
  async ListMapLinks(req: ListMapLinksRequest): Promise<ListMapLinksReply> {
    return await this.call('ListMapLinks', req, ListMapLinksReply);
  }
  async ListDelinquentAccounts(
    req: ListDelinquentAccountsRequest,
  ): Promise<ListDelinquentAccountsReply> {
    return await this.call(
      'ListDelinquentAccounts',
      req,
      ListDelinquentAccountsReply,
    );
  }
  async GetSignedURL(req: GetSignedURLRequest): Promise<GetSignedURLReply> {
    return await this.call('GetSignedURL', req, GetSignedURLReply);
  }
  async UpdatePhotos(req: UpdatePhotosRequest): Promise<UpdatePhotosReply> {
    return await this.call('UpdatePhotos', req, UpdatePhotosReply);
  }
  async GetSitePhotos(req: GetSitePhotosRequest): Promise<GetSitePhotosReply> {
    return await this.call('GetSitePhotos', req, GetSitePhotosReply);
  }
  async DeletePhoto(req: DeletePhotoRequest): Promise<EmptyMessage> {
    return await this.call('DeletePhoto', req, EmptyMessage);
  }
  async GetSiteHealth(req: GetSiteHealthRequest): Promise<GetSiteHealthReply> {
    return await this.call('GetSiteHealth', req, GetSiteHealthReply);
  }
  async GetPrescriptions(
    req: GetPrescriptionsRequest,
  ): Promise<GetPrescriptionsReply> {
    return await this.call('GetPrescriptions', req, GetPrescriptionsReply);
  }
  async GetCurrentSiteHealth(
    req: GetCurrentSiteHealthRequest,
  ): Promise<GetCurrentSiteHealthReply> {
    return await this.call(
      'GetCurrentSiteHealth',
      req,
      GetCurrentSiteHealthReply,
    );
  }
  async ListResolvedDiagnoses(
    req: ListResolvedDiagnosesRequest,
  ): Promise<ListResolvedDiagnosesReply> {
    return await this.call(
      'ListResolvedDiagnoses',
      req,
      ListResolvedDiagnosesReply,
    );
  }
  async ListOngoingDiagnoses(
    req: ListOngoingDiagnosesRequest,
  ): Promise<ListOngoingDiagnosesReply> {
    return await this.call(
      'ListOngoingDiagnoses',
      req,
      ListOngoingDiagnosesReply,
    );
  }
  async ListLeads(req: ListLeadsRequest): Promise<ListLeadsReply> {
    return await this.call('ListLeads', req, ListLeadsReply);
  }
  async ListLeadsForCustomer(
    req: ListLeadsForCustomerRequest,
  ): Promise<ListLeadsForCustomerReply> {
    return await this.call(
      'ListLeadsForCustomer',
      req,
      ListLeadsForCustomerReply,
    );
  }
  async ListLeadsForSite(
    req: ListLeadsForSiteRequest,
  ): Promise<ListLeadsForSiteReply> {
    return await this.call('ListLeadsForSite', req, ListLeadsForSiteReply);
  }
  async SearchLeads(req: SearchLeadsRequest): Promise<SearchLeadsReply> {
    return await this.call('SearchLeads', req, SearchLeadsReply);
  }
  async UpdateLeadState(
    req: UpdateLeadStateRequest,
  ): Promise<UpdateLeadStateReply> {
    return await this.call('UpdateLeadState', req, UpdateLeadStateReply);
  }
  async UpdateLeadAddress(
    req: UpdateLeadAddressRequest,
  ): Promise<UpdateLeadAddressReply> {
    return await this.call('UpdateLeadAddress', req, UpdateLeadAddressReply);
  }
  async UpdateLeadPromoCode(
    req: UpdateLeadPromoCodeRequest,
  ): Promise<EmptyMessage> {
    return await this.call('UpdateLeadPromoCode', req, EmptyMessage);
  }
  async GetLead(req: GetLeadRequest): Promise<GetLeadReply> {
    return await this.call('GetLead', req, GetLeadReply);
  }
  async ActivateLead(req: ActivateLeadRequest): Promise<EmptyMessage> {
    return await this.call('ActivateLead', req, EmptyMessage);
  }
  async SetLeadSite(req: SetLeadSiteRequest): Promise<SetLeadSiteReply> {
    return await this.call('SetLeadSite', req, SetLeadSiteReply);
  }
  async RemoveLeadSite(
    req: RemoveLeadSiteRequest,
  ): Promise<RemoveLeadSiteReply> {
    return await this.call('RemoveLeadSite', req, RemoveLeadSiteReply);
  }
  async GetSMS(req: GetSMSRequest): Promise<GetSMSReply> {
    return await this.call('GetSMS', req, GetSMSReply);
  }
  async SendReferralEmail(
    req: SendReferralEmailRequest,
  ): Promise<SendReferralEmailReply> {
    return await this.call('SendReferralEmail', req, SendReferralEmailReply);
  }
  async SendReferralEmailForLead(
    req: SendReferralEmailForLeadRequest,
  ): Promise<EmptyMessage> {
    return await this.call('SendReferralEmailForLead', req, EmptyMessage);
  }
  async SearchPartnerLeads(
    req: SearchPartnerLeadsRequest,
  ): Promise<SearchPartnerLeadsReply> {
    return await this.call('SearchPartnerLeads', req, SearchPartnerLeadsReply);
  }
  async ListPartnerLeads(
    req: ListPartnerLeadsRequest,
  ): Promise<ListPartnerLeadsReply> {
    return await this.call('ListPartnerLeads', req, ListPartnerLeadsReply);
  }
  async UpdatePartnerLeads(
    req: UpdatePartnerLeadsRequest,
  ): Promise<UpdatePartnerLeadsReply> {
    return await this.call('UpdatePartnerLeads', req, UpdatePartnerLeadsReply);
  }
  async GetAppointment(
    req: GetAppointmentRequest,
  ): Promise<GetAppointmentReply> {
    return await this.call('GetAppointment', req, GetAppointmentReply);
  }
  async BookAppointment(req: BookAppointmentRequest): Promise<EmptyMessage> {
    return await this.call('BookAppointment', req, EmptyMessage);
  }
  async CancelAppointment(
    req: CancelAppointmentRequest,
  ): Promise<EmptyMessage> {
    return await this.call('CancelAppointment', req, EmptyMessage);
  }
  async ListAppointments(
    req: ListAppointmentsRequest,
  ): Promise<ListAppointmentsReply> {
    return await this.call('ListAppointments', req, ListAppointmentsReply);
  }
  async ListAppointmentsForDay(
    req: ListAppointmentsForDayRequest,
  ): Promise<ListAppointmentsForDayReply> {
    return await this.call(
      'ListAppointmentsForDay',
      req,
      ListAppointmentsForDayReply,
    );
  }
  async ListWorkOrderApptsForDay(
    req: ListWorkOrderApptsForDayRequest,
  ): Promise<ListWorkOrderApptsForDayReply> {
    return await this.call(
      'ListWorkOrderApptsForDay',
      req,
      ListWorkOrderApptsForDayReply,
    );
  }
  async SetWorkOrderAppointment(
    req: SetWorkOrderAppointmentRequest,
  ): Promise<SetWorkOrderAppointmentReply> {
    return await this.call(
      'SetWorkOrderAppointment',
      req,
      SetWorkOrderAppointmentReply,
    );
  }
  async ListWorkOrderAppointmentTypes(
    req: cwn_rpc.Id,
  ): Promise<ListWorkOrderAppointmentTypesReply> {
    return await this.call(
      'ListWorkOrderAppointmentTypes',
      req,
      ListWorkOrderAppointmentTypesReply,
    );
  }
  async ListPromoCodes(req: EmptyMessage): Promise<ListPromoCodesReply> {
    return await this.call('ListPromoCodes', req, ListPromoCodesReply);
  }
  async UpdatePromoCode(req: UpdatePromoCodeRequest): Promise<EmptyMessage> {
    return await this.call('UpdatePromoCode', req, EmptyMessage);
  }
  async QualifyOpenAddress(
    req: QualifyOpenAddressRequest,
  ): Promise<EmptyMessage> {
    return await this.call('QualifyOpenAddress', req, EmptyMessage);
  }
  async ListFlexibleWorkOrders(
    req: ListFlexibleWorkOrdersRequest,
  ): Promise<ListFlexibleWorkOrdersReply> {
    return await this.call(
      'ListFlexibleWorkOrders',
      req,
      ListFlexibleWorkOrdersReply,
    );
  }
  async GetWorkOrder(req: GetWorkOrdersRequest): Promise<GetWorkOrdersReply> {
    return await this.call('GetWorkOrder', req, GetWorkOrdersReply);
  }
  async GetWorkOrderForAppointment(
    req: cwn_rpc.Id,
  ): Promise<GetWorkOrderReply> {
    return await this.call(
      'GetWorkOrderForAppointment',
      req,
      GetWorkOrderReply,
    );
  }
  async NewWorkOrder(req: NewWorkOrderRequest): Promise<NewWorkOrderReply> {
    return await this.call('NewWorkOrder', req, NewWorkOrderReply);
  }
  async SearchWorkOrders(
    req: SearchWorkOrdersRequest,
  ): Promise<SearchWorkOrdersReply> {
    return await this.call('SearchWorkOrders', req, SearchWorkOrdersReply);
  }
  async CreateTag(req: cwn_tech.Tag): Promise<cwn_tech.Tag> {
    return await this.call('CreateTag', req, cwn_tech.Tag);
  }
  async EditTagContents(req: EditTagContentsRequest): Promise<cwn_tech.Tag> {
    return await this.call('EditTagContents', req, cwn_tech.Tag);
  }
  async EnableTag(req: cwn_rpc.StringId): Promise<cwn_tech.Tag> {
    return await this.call('EnableTag', req, cwn_tech.Tag);
  }
  async DisableTag(req: cwn_rpc.StringId): Promise<cwn_tech.Tag> {
    return await this.call('DisableTag', req, cwn_tech.Tag);
  }
  async ListTags(req: ListTagsRequest): Promise<ListTagsResponse> {
    return await this.call('ListTags', req, ListTagsResponse);
  }
  async GetTagConfig(req: cwn_rpc.Empty): Promise<GetTagConfigResponse> {
    return await this.call('GetTagConfig', req, GetTagConfigResponse);
  }
  async CancelWorkOrder(req: CloseWorkOrderRequest): Promise<cwn_rpc.Empty> {
    return await this.call('CancelWorkOrder', req, cwn_rpc.Empty);
  }
  async FailWorkOrder(req: CloseWorkOrderRequest): Promise<cwn_rpc.Empty> {
    return await this.call('FailWorkOrder', req, cwn_rpc.Empty);
  }
  async CompleteWorkOrder(req: CloseWorkOrderRequest): Promise<cwn_rpc.Empty> {
    return await this.call('CompleteWorkOrder', req, cwn_rpc.Empty);
  }
  async SetWorkOrderDescription(
    req: SetWorkOrderDescriptionRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('SetWorkOrderDescription', req, cwn_rpc.Empty);
  }
  async SetWorkOrderTags(
    req: SetWorkOrderTagsRequest,
  ): Promise<cwn_tech.FlexibleWorkOrder> {
    return await this.call('SetWorkOrderTags', req, cwn_tech.FlexibleWorkOrder);
  }
  async SetWorkOrderAssignedTo(
    req: SetWorkOrderAssignedToRequest,
  ): Promise<cwn_tech.FlexibleWorkOrder> {
    return await this.call(
      'SetWorkOrderAssignedTo',
      req,
      cwn_tech.FlexibleWorkOrder,
    );
  }
  async EditWorkOrder(
    req: EditWorkOrderRequest,
  ): Promise<cwn_tech.FlexibleWorkOrder> {
    return await this.call('EditWorkOrder', req, cwn_tech.FlexibleWorkOrder);
  }
  async GenerateWorkOrderApptToken(
    req: GenerateWorkOrderApptTokenRequest,
  ): Promise<GenerateWorkOrderApptTokenReply> {
    return await this.call(
      'GenerateWorkOrderApptToken',
      req,
      GenerateWorkOrderApptTokenReply,
    );
  }
  async ScheduleWork(req: ScheduleWorkRequest): Promise<ScheduleWorkReply> {
    return await this.call('ScheduleWork', req, ScheduleWorkReply);
  }
  async GetSiteVisitCounts(
    req: GetSiteVisitCountsRequest,
  ): Promise<GetSiteVisitCountsReply> {
    return await this.call('GetSiteVisitCounts', req, GetSiteVisitCountsReply);
  }
  async ListBuildPlans(
    req: ListBuildPlansRequest,
  ): Promise<ListBuildPlansReply> {
    return await this.call('ListBuildPlans', req, ListBuildPlansReply);
  }
  async GetBuildPlanPageData(
    req: cwn_rpc.Id,
  ): Promise<GetBuildPlanPageDataReply> {
    return await this.call(
      'GetBuildPlanPageData',
      req,
      GetBuildPlanPageDataReply,
    );
  }
  async UpgradeBuildPlan(req: cwn_rpc.Id): Promise<EmptyMessage> {
    return await this.call('UpgradeBuildPlan', req, EmptyMessage);
  }
  async GetAvailableShotsForInstallBuildPlan(
    req: cwn_rpc.Id,
  ): Promise<GetAvailableShotsForInstallBuildPlanReply> {
    return await this.call(
      'GetAvailableShotsForInstallBuildPlan',
      req,
      GetAvailableShotsForInstallBuildPlanReply,
    );
  }
  async AddShotSelection(
    req: AddShotSelectionRequest,
  ): Promise<cwn_tech.InstallBuildPlan> {
    return await this.call('AddShotSelection', req, cwn_tech.InstallBuildPlan);
  }
  async RemoveShotSelection(
    req: RemoveShotSelectionRequest,
  ): Promise<cwn_tech.InstallBuildPlan> {
    return await this.call(
      'RemoveShotSelection',
      req,
      cwn_tech.InstallBuildPlan,
    );
  }
  async ListWorkOrdersForSite(
    req: ListWorkOrdersForSiteRequest,
  ): Promise<ListWorkOrdersForSiteReply> {
    return await this.call(
      'ListWorkOrdersForSite',
      req,
      ListWorkOrdersForSiteReply,
    );
  }
  async GetWorkOrderInfo(
    req: GetWorkOrderInfoRequest,
  ): Promise<GetWorkOrderInfoReply> {
    return await this.call('GetWorkOrderInfo', req, GetWorkOrderInfoReply);
  }
  async StartWorkOrder(req: StartWorkOrderRequest): Promise<EmptyMessage> {
    return await this.call('StartWorkOrder', req, EmptyMessage);
  }
  async DeleteWorkOrder(req: DeleteWorkOrderRequest): Promise<EmptyMessage> {
    return await this.call('DeleteWorkOrder', req, EmptyMessage);
  }
  async CreateWorkOrder(
    req: CreateWorkOrderRequest,
  ): Promise<CreateWorkOrderReply> {
    return await this.call('CreateWorkOrder', req, CreateWorkOrderReply);
  }
  async FindAllQBPsWithinBounds(
    req: FindAllQBPsWithinBoundsRequest,
  ): Promise<FindAllQBPsWithinBoundsReply> {
    return await this.call(
      'FindAllQBPsWithinBounds',
      req,
      FindAllQBPsWithinBoundsReply,
    );
  }
  async ListGeofences(req: ListGeofencesRequest): Promise<ListGeofencesReply> {
    return await this.call('ListGeofences', req, ListGeofencesReply);
  }
  async CreateGeofence(
    req: CreateGeofenceRequest,
  ): Promise<CreateGeofenceReply> {
    return await this.call('CreateGeofence', req, CreateGeofenceReply);
  }
  async CreateProgramGeo(req: CreateProgramGeoRequest): Promise<EmptyMessage> {
    return await this.call('CreateProgramGeo', req, EmptyMessage);
  }
  async PutProgramGeo(req: PutProgramGeoRequest): Promise<EmptyMessage> {
    return await this.call('PutProgramGeo', req, EmptyMessage);
  }
  async ListProgramGeos(
    req: ListProgramGeosRequest,
  ): Promise<ListProgramGeosReply> {
    return await this.call('ListProgramGeos', req, ListProgramGeosReply);
  }
  async GetProgram(req: GetProgramRequest): Promise<GetProgramReply> {
    return await this.call('GetProgram', req, GetProgramReply);
  }
  async ListPlans(req: ListPlansRequest): Promise<ListPlansReply> {
    return await this.call('ListPlans', req, ListPlansReply);
  }
  async CreateServGeo(req: CreateServGeoRequest): Promise<EmptyMessage> {
    return await this.call('CreateServGeo', req, EmptyMessage);
  }
  async PutServGeo(req: PutServGeoRequest): Promise<EmptyMessage> {
    return await this.call('PutServGeo', req, EmptyMessage);
  }
  async ListServGeos(req: ListServGeosRequest): Promise<ListServGeosReply> {
    return await this.call('ListServGeos', req, ListServGeosReply);
  }
  async CreateReportsGeo(req: CreateReportsGeoRequest): Promise<EmptyMessage> {
    return await this.call('CreateReportsGeo', req, EmptyMessage);
  }
  async PutReportsGeo(req: PutReportsGeoRequest): Promise<EmptyMessage> {
    return await this.call('PutReportsGeo', req, EmptyMessage);
  }
  async ListReportsGeos(
    req: ListReportsGeosRequest,
  ): Promise<ListReportsGeosReply> {
    return await this.call('ListReportsGeos', req, ListReportsGeosReply);
  }
  async CreateOpsGeo(req: CreateOpsGeoRequest): Promise<EmptyMessage> {
    return await this.call('CreateOpsGeo', req, EmptyMessage);
  }
  async PutOpsGeo(req: PutOpsGeoRequest): Promise<EmptyMessage> {
    return await this.call('PutOpsGeo', req, EmptyMessage);
  }
  async ListOpsGeos(req: ListOpsGeosRequest): Promise<ListOpsGeosReply> {
    return await this.call('ListOpsGeos', req, ListOpsGeosReply);
  }
  async CreateNetworkGeo(req: CreateNetworkGeoRequest): Promise<EmptyMessage> {
    return await this.call('CreateNetworkGeo', req, EmptyMessage);
  }
  async PutNetworkGeo(req: PutNetworkGeoRequest): Promise<EmptyMessage> {
    return await this.call('PutNetworkGeo', req, EmptyMessage);
  }
  async ListNetworkGeos(
    req: ListNetworkGeosRequest,
  ): Promise<ListNetworkGeosReply> {
    return await this.call('ListNetworkGeos', req, ListNetworkGeosReply);
  }
  async ListPlansForSite(
    req: ListPlansForSiteRequest,
  ): Promise<ListPlansForSiteReply> {
    return await this.call('ListPlansForSite', req, ListPlansForSiteReply);
  }
  async UpdatePlansForLead(
    req: UpdatePlansForLeadRequest,
  ): Promise<EmptyMessage> {
    return await this.call('UpdatePlansForLead', req, EmptyMessage);
  }
  async GetCandidateMountForSite(
    req: cwn_rpc.StringId,
  ): Promise<cwn_site_build.LocalMount> {
    return await this.call(
      'GetCandidateMountForSite',
      req,
      cwn_site_build.LocalMount,
    );
  }
  async NewSiteAssessment(
    req: NewSiteAssessmentRequest,
  ): Promise<cwn_site_assessment.SiteAssessment> {
    return await this.call(
      'NewSiteAssessment',
      req,
      cwn_site_assessment.SiteAssessment,
    );
  }
  async PushSiteAssessment(req: cwn_rpc.Id): Promise<cwn_rpc.Empty> {
    return await this.call('PushSiteAssessment', req, cwn_rpc.Empty);
  }
  async GetSiteAssessment(req: cwn_rpc.Id): Promise<GetSiteAssessmentReply> {
    return await this.call('GetSiteAssessment', req, GetSiteAssessmentReply);
  }
  async GetLOSCaches(req: GetLOSCachesRequest): Promise<GetLOSCachesReply> {
    return await this.call('GetLOSCaches', req, GetLOSCachesReply);
  }
  async GetSiteServiceLevel(
    req: GetSiteServiceLevelRequest,
  ): Promise<cwn_customers.ServiceLevel> {
    return await this.call(
      'GetSiteServiceLevel',
      req,
      cwn_customers.ServiceLevel,
    );
  }
  async GetBuildingPointsForSite(
    req: cwn_rpc.StringId,
  ): Promise<GetBuildingPointsForSiteReply> {
    return await this.call(
      'GetBuildingPointsForSite',
      req,
      GetBuildingPointsForSiteReply,
    );
  }
  async NewShotOverride(
    req: NewShotOverrideRequest,
  ): Promise<cwn_geo.ShotOverride> {
    return await this.call('NewShotOverride', req, cwn_geo.ShotOverride);
  }
  async UpdateShotOverride(
    req: UpdateShotOverrideRequest,
  ): Promise<cwn_geo.ShotOverride> {
    return await this.call('UpdateShotOverride', req, cwn_geo.ShotOverride);
  }
  async DeleteShotOverride(
    req: DeleteShotOverrideRequest,
  ): Promise<cwn_rpc.Empty> {
    return await this.call('DeleteShotOverride', req, cwn_rpc.Empty);
  }
  async GetSiteBuildAndPlan(req: cwn_rpc.StringId): Promise<SiteBuildAndPlan> {
    return await this.call('GetSiteBuildAndPlan', req, SiteBuildAndPlan);
  }
  async CreateSitePlan(req: CreateSitePlanRequest): Promise<SiteBuildAndPlan> {
    return await this.call('CreateSitePlan', req, SiteBuildAndPlan);
  }
  async UpdateSitePlan(req: UpdateSitePlanRequest): Promise<SiteBuildAndPlan> {
    return await this.call('UpdateSitePlan', req, SiteBuildAndPlan);
  }
  async RebaseSitePlan(req: RebaseSitePlanRequest): Promise<SiteBuildAndPlan> {
    return await this.call('RebaseSitePlan', req, SiteBuildAndPlan);
  }
  async ApplyRequiredWork(
    req: ApplyRequiredWorkRequest,
  ): Promise<SiteBuildAndPlan> {
    return await this.call('ApplyRequiredWork', req, SiteBuildAndPlan);
  }
}
export {AdminService};

// END cwn/admin/service.proto
